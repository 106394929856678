import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import API from '../../services/api.service';
import { API_ENDPOINTS } from 'constants/db.constants';
import { IAudit } from 'constants/audit.constants';
import { convertKeysToCamelCaseArray, getFormattedDate } from '../../utils/global.util';
import { timing } from 'constants/ui.constants';

enum AUDIT_RESULT {
  SUCCESS = 'Success',
  FAILED = 'Failed'
}

export interface IStateError {
  errorCode: number | null;
  errorMessage: string;
  appCode?: number | null;
}

interface IAuditState {
  loading: boolean;
  error?: IStateError;
  audits: IAudit[];
  page: number;
  totalNumber: number;
}

const pageSize = 100;

export const getAudits = createAsyncThunk('audits/getAudits', (data: any) => {
  return API.getInstance().get(API_ENDPOINTS.AUDIT.GET, {
    pageNumber: data.pageNumber,
    pageSize,
    order: data.orderColumn,
    orderDirection: data.orderDirection
  });
});

const initialState: IAuditState = {
  totalNumber: 0,
  error: undefined,
  loading: false,
  audits: [],
  page: 1
};

export const auditSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    setError: (state, { payload }) => {
      state.error = {
        errorCode: payload.errorCode,
        errorMessage: payload.data?.message
      };
    }
  },
  extraReducers: {
    [getAudits.pending.type]: (state) => {
      state.loading = true;
      state.error = undefined;
    },
    [getAudits.fulfilled.type]: (state, { payload }) => {
      state.audits = convertKeysToCamelCaseArray(payload.data.data.rows)
        .filter((entry) => entry.api != 'sync/push')
        .map((entry) => {
          entry.createdAt = getFormattedDate(new Date(entry?.createdAt || ''), timing.FULLDATE);
          entry.httpStatus =
            entry.httpStatus >= 200 && entry.httpStatus < 400 ? AUDIT_RESULT.SUCCESS : AUDIT_RESULT.FAILED;
          return entry;
        });
      state.totalNumber = Math.ceil(payload.data.data.totalEntries / pageSize);
      state.loading = false;
    },
    [getAudits.rejected.type]: (state, { payload }) => {
      state.error = {
        errorCode: payload?.statusCode,
        errorMessage: payload?.message
      };
      state.loading = false;
    }
  }
});

export default auditSlice.reducer;
