import React, { useDeferredValue, useEffect, useMemo, useState } from 'react';
import { useAppSelector, useAppDispatch } from 'hooks/redux.hooks';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Button, Stack } from '@mui/material';
import { Add } from '@mui/icons-material';
import _ from 'lodash';

import { CpDefaultSort, CpId, CpTableColumn, CpTableColumnTitles, ICpActive } from 'constants/cp.constants';
import { userStatuses } from 'constants/user.constants';
import { IColumnType } from 'components/Table/types';

import SelectBox from 'components/SelectBox';
import BasicTable from 'components/Table';

import { StyledHeaderBox, StyledTypography, StyledTypographySpan } from '../styled-components';
import { pagesEnum } from 'constants/ui.constants';

import { getAllCps } from 'state/reducers/cp.reducers';
import CircularIndeterminate from 'components/Loading';
import NoInfo from 'components/NoInfo';
import { getAllSites } from 'state/reducers/user.reducers';
import { filterByFields } from '../../utils/global.util';
import SearchComponent from 'components/Search/searchComponent';

const CpPage: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { siteId } = useParams();
  const { cps } = useAppSelector((state) => state.cp);
  const { sites, loading, error } = useAppSelector((state) => state.user);
  const [searchValue, setSearchValue] = useState<string>('');
  const debouncedValue = useDeferredValue(searchValue);

  useEffect(() => {
    dispatch(getAllSites());
    dispatch(getAllCps({}));
  }, []);

  const cpColumns = () => {
    const columns: IColumnType[] = [];
    _.keys(CpTableColumn).forEach((element) => {
      const props: IColumnType = {
        fieldId: CpId,
        name: element,
        title: CpTableColumnTitles[element as keyof typeof CpTableColumnTitles] || element,
        type: CpTableColumn[element],
        customValueHandle: (val, rec) => {
          return rec.active ? ICpActive.Active : ICpActive.Inactive;
        }
      };
      columns.push(props);
    });
    return columns;
  };

  const onResetSelections = () => {
    const resetedFilterValues = { ...filterValues };
    _.chain(filterValues)
      .keys()
      .forEach((filterValue) => {
        resetedFilterValues[filterValue as keyof typeof filterValues].value = 'All';
      })
      .value();
    setFilterValues(resetedFilterValues);
    setSearchValue('');
  };

  const parsedSitesOptions = useMemo(() => {
    const cpSites = _.map(cps, (cp) => cp.assignedSite ?? 'No site') || [];
    return [...new Set(cpSites)];
  }, [cps]);

  const parsedCountriesOptions = useMemo(() => _.map(sites, (site) => site.country), [sites]);

  const [filterValues, setFilterValues] = useState<{ [key: string]: any }>();

  const onFilterChange = (filterType: string, filterValue: string) => {
    const updatedFilterValues = {
      ...filterValues,
      [filterType]: {
        ...filterValues?.[filterType as keyof typeof filterValues],
        value: filterValue
      }
    };
    setFilterValues(updatedFilterValues);
  };

  const onFilterChangeParam = (filterType: string, filterValue: string) => {
    if (siteId) navigate(`/${pagesEnum.CP}`, { replace: true });
    onFilterChange(filterType, filterValue);
  };

  const updatedTableData = () => {
    const items: { [key: string]: any } = cps || [];
    return items.filter((cp: any) => {
      return isCountryMatches(cp.country) && isUserSitesMatches(cp.assignedSite) && isUserStatusMatches(cp.active);
    });
  };
  const isUserStatusMatches = (isActive: string | boolean) => {
    const statusValue = filterValues?.status?.value;

    // Conditionally checks if statusValue is undefined, empty, 'All', or matches isActive
    return !statusValue || statusValue === 'All' || statusValue === '' || statusValue === isActive;
  };

  const isCountryMatches = (cpCountry: string) => {
    return _.includes([cpCountry, 'All', ''], filterValues?.country.value);
  };

  const isUserSitesMatches = (site: string) => {
    return filterValues?.sites.value === 'No site' ? !site : _.includes([site, 'All'], filterValues?.sites.value);
  };

  useEffect(() => {
    if (siteId && filterValues) {
      if (filterValues.sites) {
        const site = sites?.find((site) => site!.id === +siteId);
        if (site) {
          if (filterValues.sites.value !== site.name) onFilterChange('sites', site.name!);
        } else navigate(`/${pagesEnum.CP}`, { replace: true });
      }
    }
  }, [siteId, filterValues, sites]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const filteredCpData = filterByFields(updatedTableData(), debouncedValue, ['idNumber', 'country', 'assignedSite']);

  useEffect(() => {
    if (parsedSitesOptions && parsedCountriesOptions) {
      setFilterValues({
        sites: {
          displayName: 'Sites',
          options: parsedSitesOptions,
          value: 'All'
        },
        country: {
          displayName: 'Country',
          options: parsedCountriesOptions,
          value: 'All'
        },
        status: {
          displayName: 'Status',
          options: userStatuses,
          value: 'All'
        }
      });
    }
  }, [parsedSitesOptions, parsedCountriesOptions, setFilterValues]);

  if (error) return <NoInfo />;
  if (loading) return <CircularIndeterminate />;

  return (
    <Box sx={{ width: '100%' }}>
      {cps && (
        <Stack spacing={2}>
          <StyledHeaderBox>
            <StyledTypography>CP</StyledTypography>
            <Button
              color='secondary'
              variant='contained'
              startIcon={<Add />}
              onClick={() => {
                navigate(`/${pagesEnum.CP}/new`);
              }}
            >
              Add New
            </Button>
          </StyledHeaderBox>
          <Stack direction='row' justifyContent='start' alignItems='center' spacing={2} flexWrap='nowrap'>
            <SearchComponent handleInputChange={handleInputChange} searchValue={searchValue} />
            {_.map(_.keys(filterValues), (filterValuesKey) => {
              return (
                <SelectBox
                  key={filterValuesKey}
                  items={filterValues![filterValuesKey as keyof typeof filterValues].options}
                  values={filterValues![filterValuesKey as keyof typeof filterValues].value}
                  label={filterValues![filterValuesKey as keyof typeof filterValues].displayName}
                  inputLabel={filterValues![filterValuesKey as keyof typeof filterValues].displayName}
                  type={filterValuesKey}
                  onFilterChange={onFilterChangeParam}
                  withAllOption
                />
              );
            })}
            <Button variant='text' size='small' onClick={onResetSelections} sx={{ marginBottom: '8px' }}>
              Reset filter
            </Button>
          </Stack>
          <StyledTypographySpan>Showing {`${updatedTableData()?.length}`} results</StyledTypographySpan>
          <BasicTable
            fieldId='id'
            data={filteredCpData || []}
            columns={cpColumns()}
            defaultSortBy={CpDefaultSort}
            actionsCell={[
              {
                element: (
                  <Button
                    color='secondary'
                    sx={{ marginRight: '46px', textTransform: 'none', fontWeight: 'bold' }}
                    variant='text'
                  >
                    Info
                  </Button>
                ),
                onClick: (cp) => {
                  navigate(`/${pagesEnum.CP}/${cp.id}`);
                }
              }
            ]}
          />
        </Stack>
      )}
    </Box>
  );
};

export default CpPage;
