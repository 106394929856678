import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IUiState {
  page: string;
}

const initialState: IUiState = {
  page: 'dashboard'
};

export const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    togglePage: (state, action: PayloadAction<string>) => {
      state.page = action.payload;
    }
  }
});

export const { togglePage } = uiSlice.actions;

export default uiSlice.reducer;
