import React from 'react';
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import _ from 'lodash';

// eslint-disable-next-line functional/no-mixed-type
interface SelectBoxProps {
  items: string[];
  values: string;
  label?: string;
  inputLabel?: string;
  onFilterChange: (filterType: string, filterValue: string) => void;
  type: string;
  disabled?: boolean;
  withAllOption?: boolean;
  allOptionLabel?: string;
  inputId?: string;
}

const SelectBox: React.FC<SelectBoxProps> = ({
  items,
  values,
  label,
  inputLabel,
  onFilterChange,
  type,
  disabled,
  withAllOption,
  allOptionLabel,
  inputId
}) => {
  const handleChange = (event: SelectChangeEvent) => {
    const { value } = event.target;
    onFilterChange(type, value);
  };

  const sortedUniqueItems = _.uniq(_.pull(items, '')).sort(function (a, b) {
    if (a?.toLowerCase() < b?.toLowerCase()) return -1;
    if (a?.toLowerCase() > b?.toLowerCase()) return 1;
    return 0;
  });

  return (
    <FormControl sx={{ minWidth: 160, maxWidth: 400 }}>
      <InputLabel id='select-label'>{inputLabel}</InputLabel>
      <Select
        labelId='select-label'
        label={label}
        value={values}
        onChange={handleChange}
        displayEmpty
        disabled={disabled}
        sx={{ backgroundColor: disabled ? '#f5f5f5' : '#ffff' }}
        inputProps={{ 'data-testid': inputId }}
      >
        {withAllOption && <MenuItem value={'All'}>{allOptionLabel || 'All'}</MenuItem>}
        {sortedUniqueItems.map((item) => (
          <MenuItem key={item} value={item}>
            {item}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectBox;
