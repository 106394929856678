import { styled } from '@mui/material/styles';
import { TableContainer, TableCell, TableSortLabel } from '@mui/material';

const CustomTableContainer = styled(TableContainer)(({ theme }) => ({
  backgroundColor: theme.palette.root.basicWhite,
  border: `solid 0.5px ${theme.palette.root.t_border}`,
  borderRadius: theme.shape.borderRadius,
  boxShadow: `0 8px 12px -2px ${theme.palette.root.boxShadowDark}, 0 2px 4px -1px ${theme.palette.root.boxShadowLight}`
}));

const CustomTableCell = styled(TableCell)(({ theme }) => ({
  color: theme.palette.root.sub,
  fontSize: '16px',
  fontWeight: 'normal',
  '& .onHover': {
    position: 'absolute',
    height: 'auto',
    minHeight: 400,
    background: '#FFF',
    textAlign: 'center',
    top: '25%',
    right: 0,
    left: 0,
    zIndex: '99',
    display: 'none',
    justifyContent: 'center',
    alignItems: 'center',
    margin: 'auto',
    boxShadow: '1px 2px 1px 1px rgba(0,0,0, 0.2)',
    overflow: 'scroll',
    borderRadius: 8
  },
  '&.hoverBox:hover': {
    '& .onHover': {
      display: 'flex'
    }
  }
}));

const CustomTableInfoCell = styled(TableCell)(() => ({
  display: 'flex',
  justifyContent: 'flex-end'
}));

const CustomTableSortLabel = styled(TableSortLabel)(({ theme }) => ({
  color: theme.palette.root.header,
  fontSize: '16px',
  fontWeight: 'normal',
  '& .MuiTableSortLabel-icon': {
    fontSize: '20px',
    color: theme.palette.root.header
  }
}));

export { CustomTableContainer, CustomTableCell, CustomTableInfoCell, CustomTableSortLabel };
