import React from 'react';

interface IconProps {
  width?: string;
  height?: string;
}

export const SiteIcon: React.FC<IconProps> = ({ width = '24px', height = '24px' }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_6953_4532)'>
        <path d='M3 21H21' stroke='#4E9FB2' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
        <path
          d='M5 21V5C5 4.46957 5.21071 3.96086 5.58579 3.58579C5.96086 3.21071 6.46957 3 7 3H17C17.5304 3 18.0391 3.21071 18.4142 3.58579C18.7893 3.96086 19 4.46957 19 5V21'
          stroke='#4E9FB2'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M9 21V17C9 16.4696 9.21071 15.9609 9.58579 15.5858C9.96086 15.2107 10.4696 15 11 15H13C13.5304 15 14.0391 15.2107 14.4142 15.5858C14.7893 15.9609 15 16.4696 15 17V21'
          stroke='#4E9FB2'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path d='M10 9H14' stroke='#4E9FB2' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M12 7V11' stroke='#4E9FB2' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
      </g>
      <defs>
        <clipPath id='clip0_6953_4532'>
          <rect width='24' height='24' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};
