import React, { FormEvent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from 'hooks/redux.hooks';
import { authorize, login } from 'state/reducers/auth.reducers';
import { Box, Button, Stack } from '@mui/material';
import { Info } from '@mui/icons-material';

import AuthenticateCode from 'components/AuthenticateCode';
import { StyledSpan, StyledTypography, StyledTypographySubTitle, StyledTypographyValidation } from './styled';

export const AccountAuthentication: React.FC = () => {
  const navigate = useNavigate();
  const [authCode, setAuthCode] = useState('');
  const dispatch = useAppDispatch();
  const { userDetails, error, loading } = useAppSelector((state) => state.auth);

  const formSubmitHandler = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const twoFactorAuthData = {
      userName: userDetails?.userName,
      password: userDetails?.password,
      OTP: authCode
    };
    dispatch(authorize(twoFactorAuthData));
  };

  useEffect(() => {
    if (!loading && !error && userDetails?.periodicBlock) {
      navigate('#change-password-period');
    } else {
      if (!loading && !error && userDetails?.forcePasswordChange) {
        navigate('#reset-password');
      } else {
        if (!loading && !error && userDetails?.otp) {
          navigate('/sites');
        } else {
          if (error?.errorCode === 403) {
            return;
          }
        }
      }
    }
  }, [userDetails, error, loading]);

  return (
    <form noValidate onSubmit={formSubmitHandler}>
      <Stack spacing={4}>
        <Box>
          <StyledTypography variant='h4' gutterBottom>
            Authenticate Your Account
          </StyledTypography>
          <StyledTypographySubTitle variant='body1'>A email with a 6-digit verification code</StyledTypographySubTitle>
          <StyledTypographySubTitle variant='body1'>was just sent to your email address.</StyledTypographySubTitle>
        </Box>
        <Stack>
          <AuthenticateCode authCode={authCode} setAuthCode={setAuthCode} />
          {authCode.length !== 6 ||
            (error && (
              <StyledTypographyValidation variant='subtitle1' gutterBottom>
                <Info sx={{ color: '#333333', paddingRight: '8px' }} />
                Incorrect code. Try again or resend a new code.
              </StyledTypographyValidation>
            ))}
        </Stack>
        <StyledSpan>It may take a few seconds to receive the message</StyledSpan>
        <Button
          color='secondary'
          data-testid='validate-button'
          type='submit'
          fullWidth
          disabled={authCode.length !== 6}
          variant='contained'
          sx={{ height: '60px' }}
        >
          Validate
        </Button>
        <Button
          onClick={() => {
            dispatch(login({ userName: userDetails?.userName, password: userDetails?.password }));
          }}
          sx={{
            color: '#04a0c5',
            textTransform: 'none',
            textAlign: 'center',
            fontSize: 17
          }}
        >
          Didn`t receive a code? Resend
        </Button>
      </Stack>
    </form>
  );
};
