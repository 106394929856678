import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';

import { Box, Button, Card, Stack } from '@mui/material';

//style
import {
  StyledCard,
  StyledCardContent,
  StyledStatusBox,
  StyledTypographySpan,
  StyledTypographySubTitle
} from '../../styled-components';
//constants
import { Site, SiteInputsForm } from 'constants/sites.constants';
import { pagesEnum } from 'constants/ui.constants';
//component
import CustomInputForm from '../../../components/InputsForm/CustomInput';
import ConfirmDialog from 'components/ConfirmDialog';
import NoInfo from 'components/NoInfo';
import CircularIndeterminate from 'components/Loading';
import { convertKeysToSnakeCase, getFormattedDate } from '../../../utils/global.util';
//icons
import { CityIcon } from 'assets/icons/City.icon';
import { CpIcon } from 'assets/icons/Cp.icon';
import { SwiftIcon } from 'assets/icons/Swift.icon';
import { LocationIcon } from 'assets/icons/Location.icon';
import { PermissionIcon } from 'assets/icons/Permission.icon';
import { CalenderIcon } from 'assets/icons/Calender.icon';
import { useAppDispatch, useAppSelector } from 'hooks/redux.hooks';
import { SiteMember } from '../SiteMember';
import { EngineerIcon } from 'assets/icons/Engineer.icon';
import { DoctorIcon } from 'assets/icons/Doctor.icon';
import { PatientIcon } from 'assets/icons/Patient.icon';
import { EditIcon } from 'assets/icons/Edit.icon';
import { getSites, inactiveSite, updateSite } from 'state/reducers/site.reducers';
import { UserRole } from 'constants/user.constants';
import _ from 'lodash';
import { TabsSwitcher } from 'components/TabsSwitcher';
import ArrowBackBtn from 'components/ArrowBackBtn/arrowBackBtn';

interface IFormInputs {
  dataForm: SiteInputsForm;
  openProfileModal: boolean;
  setOpenProfileModal: Dispatch<SetStateAction<boolean>>;
}

const SiteInformation: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { id } = useParams();

  const [site, setSite] = useState<Site | undefined>(undefined);
  const [formError, setFormError] = useState<{
    [key: string]: boolean;
  }>({});
  const [onEdit, setOnEdit] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  const methods = useForm<IFormInputs>({
    defaultValues: {
      dataForm: {}
    }
  });
  const { sites, loading, error } = useAppSelector((state) => state.sites);

  useEffect(() => {
    if (!sites) {
      dispatch(getSites());
    }
  }, []);

  const getFullName = (firstName = '', lastName = '') => {
    if (!firstName && !lastName) return 'N/A';
    else return `${firstName || ''} ${lastName || ''}`;
  };

  useEffect(() => {
    if (sites) {
      const findSite = sites.find((site) => site.id == id);
      setSite(findSite);
      methods.reset({
        dataForm: {
          name: findSite?.name || '',
          country: findSite?.country || '',
          siteId: findSite?.siteId || '',
          city: findSite?.city || '',
          createdAt: `${getFullName(findSite?.createdByFirstName, findSite?.createdByLastName)} ${
            getFormattedDate(new Date(findSite?.createdAt || '')) as string
          }`,
          lastActivity: findSite?.lastActivity || '',
          updatedAt: `${getFullName(findSite?.updatedByFirstName, findSite?.updatedByLastName)} ${
            getFormattedDate(new Date(findSite?.updatedAt || '')) as string
          }`,
          activeCpCount: findSite?.activeCpCount || 0
        }
      });
    }
  }, [sites, id]);

  const [statusInactive, setStatusInactive] = useState<boolean>();

  const doesSiteHasPatients = useMemo(() => !!site?.patientCount, [site]);

  const parsedSite = {
    ...site,
    createdAt: getFormattedDate(new Date(site?.createdAt || '')),
    updatedAt: getFormattedDate(new Date(site?.updatedAt || ''))
  };

  const formSubmitHandler: SubmitHandler<IFormInputs> = (data: IFormInputs) => {
    const siteData = convertKeysToSnakeCase({
      name: data.dataForm.name,
      country: data.dataForm.country,
      siteId: data.dataForm.siteId,
      city: data.dataForm.city
    });
    const updatedSiteData = {
      id,
      siteData
    };
    dispatch(updateSite(updatedSiteData));
    setOnEdit(false);
  };

  const hasErrorCode = useMemo(() => {
    return _.isEqual(_.get(error, 'appCode'), 104);
  }, [error]);

  const onConfirmHandler = () => {
    if (doesSiteHasPatients) {
      setOpenDialog(false);
      setOnEdit(false);
      return;
    }
    if (!statusInactive) {
      dispatch(inactiveSite({ siteId: id }));
    } else {
      dispatch(updateSite({ id, siteData: { active: !site?.active } }));
    }
    setOpenDialog(false);
    setOnEdit(false);
    //in case inactive was return from the server with approval
    if (hasErrorCode) {
      dispatch(inactiveSite({ siteId: id, ignoreAttached: true }));
    }
  };

  const validateField = (event: any) => {
    const {
      id,
      value
    }: {
      id: keyof Site;
      value: string;
    } = event.target;

    if (value?.length) {
      if (['name', 'siteId'].includes(id) && value) {
        const exist = !!sites?.find((_site: Site) => {
          return _site[id] === value && !_.isEqual(_site.id, site?.id);
        });
        if (exist) {
          setFormError({
            ...formError,
            [id]: true
          });
          return;
        }
        setFormError({
          ...formError,
          [id]: false
        });
      }
    }
  };

  useEffect(() => {
    if (hasErrorCode) {
      setOpenDialog(true);
    }
  }, [hasErrorCode]);

  useEffect(() => {
    setStatusInactive(!site?.active);
  }, [site, setStatusInactive]);

  if (!site) return <NoInfo />;
  if (loading) return <CircularIndeterminate />;
  return (
    <Box sx={{ width: '100%', height: '100%' }}>
      <FormProvider {...methods}>
        <form noValidate onChange={validateField} onSubmit={methods.handleSubmit(formSubmitHandler)}>
          <StyledCard sx={{ background: 'transparent' }}>
            <StyledCardContent>
              <Stack spacing={3}>
                <ArrowBackBtn title={'Site Info'} route={`/${pagesEnum.SITES}`} />
                <Stack spacing={1} direction='row' alignItems='center' justifyContent='space-between'>
                  <Stack spacing={2} direction='row' alignItems='center'>
                    <StyledTypographySubTitle>{onEdit ? 'Edit Site' : site?.name}</StyledTypographySubTitle>
                    {onEdit ? (
                      <TabsSwitcher
                        onClick={() => {
                          onEdit ? setOpenDialog(true) : {};
                        }}
                        isInactive={statusInactive}
                        disabled={!onEdit}
                      />
                    ) : (
                      <StyledStatusBox isInactive={statusInactive}>
                        {statusInactive ? 'Inactive' : 'Active'}
                      </StyledStatusBox>
                    )}
                  </Stack>
                  {!onEdit && (
                    <Button
                      color='secondary'
                      data-testid='edit-button'
                      startIcon={<EditIcon />}
                      variant='outlined'
                      onClick={() => {
                        setOnEdit(true);
                      }}
                    >
                      Edit Site
                    </Button>
                  )}
                  <ConfirmDialog
                    openDialog={openDialog}
                    setOpenDialog={setOpenDialog}
                    onConfirm={onConfirmHandler}
                    errorDialog={doesSiteHasPatients}
                    dialogTitle={
                      hasErrorCode
                        ? _.get(error, 'errorMessage') || ''
                        : doesSiteHasPatients && site.active
                        ? `You Cannot Convert ${site?.name} Branch to inactive`
                        : statusInactive
                        ? 'Convert site to active'
                        : 'Convert site to inactive'
                    }
                    showCancel={hasErrorCode}
                    dialogContentText={
                      hasErrorCode
                        ? ''
                        : doesSiteHasPatients
                        ? `There are ${site.patientCount} patients assigned to this site.`
                        : 'Press OK to continue'
                    }
                  />
                </Stack>
                <Card variant='outlined' sx={{ p: 3 }}>
                  <Stack spacing={2}>
                    {onEdit && (
                      <Stack direction='row' spacing={2} flexWrap='wrap' useFlexGap>
                        <CustomInputForm
                          disabled={!onEdit}
                          defaultValue={site?.name}
                          controllerName={'dataForm.name'}
                          inputRegister={'dataForm.name'}
                          inputId={'name'}
                          label={'Name'}
                          type={'text'}
                          icon={<LocationIcon />}
                          errorMessage={_.get(formError, 'name') ? 'Same site name, Try other name.' : ''}
                        />
                        <CustomInputForm
                          disabled={!onEdit}
                          defaultValue={site?.country}
                          controllerName={'dataForm.country'}
                          inputRegister={'dataForm.country'}
                          inputId={'country'}
                          label={'Country'}
                          type={'text'}
                          icon={<SwiftIcon />}
                        />
                      </Stack>
                    )}
                    <Stack direction='row' spacing={2} flexWrap='wrap' useFlexGap>
                      <CustomInputForm
                        disabled={!onEdit}
                        defaultValue={site?.country}
                        controllerName={'dataForm.country'}
                        inputRegister={'dataForm.country'}
                        inputId={'country'}
                        label={'Country'}
                        type={'text'}
                        icon={<SwiftIcon />}
                      />
                      <CustomInputForm
                        disabled={!onEdit}
                        defaultValue={site?.siteId}
                        controllerName={'dataForm.siteId'}
                        inputRegister={'dataForm.siteId'}
                        inputId={'siteId'}
                        label={'Site ID'}
                        type={'text'}
                        icon={<PermissionIcon />}
                        errorMessage={_.get(formError, 'siteId') ? 'Same site ID, Try other ID.' : ''}
                      />
                      <CustomInputForm
                        disabled={!onEdit}
                        defaultValue={site?.city}
                        controllerName={'dataForm.city'}
                        inputRegister={'dataForm.city'}
                        inputId={'city'}
                        label={'City'}
                        type={'text'}
                        icon={<CityIcon />}
                      />
                      <CustomInputForm
                        disabled={true}
                        defaultValue={`${parsedSite?.createdByFirstName} ${parsedSite?.createdByLastName} ${parsedSite?.createdAt}`}
                        controllerName={'dataForm.createdAt'}
                        inputRegister={'dataForm.createdAt'}
                        inputId={'createdAt'}
                        label={'Created By'}
                        type={'text'}
                        icon={<CalenderIcon />}
                      />
                    </Stack>
                    {/*<Stack spacing={2} direction='row' sx={{ display: 'flex', justifyContent: 'flex-start' }}>*/}
                    {/*<CustomInputForm*/}
                    {/*  disabled={true}*/}
                    {/*  defaultValue={parsedSite?.lastActivity}*/}
                    {/*  controllerName={'dataFrom.lastActivity'}*/}
                    {/*  inputRegister={'dataFrom.lastActivity'}*/}
                    {/*  inputId={'lastActivity'}*/}
                    {/*  label={'Last Activity'}*/}
                    {/*  type={'text'}*/}
                    {/*  icon={<PulseIcon />}*/}
                    {/*/>*/}
                    {/*<CustomInputForm*/}
                    {/*  disabled={true}*/}
                    {/*  defaultValue={`${parsedSite?.updatedByFirstName} ${parsedSite?.updatedByLastName} ${parsedSite?.updatedAt}`}*/}
                    {/*  controllerName={'dataForm.updatedAt'}*/}
                    {/*  inputRegister={'dataForm.updatedAt'}*/}
                    {/*  inputId={'updatedAt'}*/}
                    {/*  label={'Last Edit'}*/}
                    {/*  type={'text'}*/}
                    {/*  icon={<UserInfoIcon />}*/}
                    {/*/>*/}
                    {/*</Stack>*/}
                    <StyledTypographySubTitle>Assigned Device</StyledTypographySubTitle>
                    <CustomInputForm
                      disabled={true}
                      defaultValue={site?.activeCpCount || 0}
                      controllerName={'dataForm.activeCpCount'}
                      inputRegister={'dataForm.activeCpCount'}
                      inputId={'activeCpCount'}
                      label={'CP'}
                      type={'text'}
                      icon={<CpIcon fill={'#037691'} />}
                      value={site.id}
                      to={site!.activeCpCount ? pagesEnum.CP + '/filter/sites' : ''}
                    />
                  </Stack>
                </Card>
                <Stack spacing={2}>
                  <StyledTypographySubTitle>Assigned Member</StyledTypographySubTitle>
                  <StyledTypographySpan>Click to see more information.</StyledTypographySpan>
                  <Stack spacing={5} direction='row' sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                    <SiteMember
                      title={'BWMR'}
                      icon={<EngineerIcon />}
                      count={site?.adminCount}
                      params={{ usertype: UserRole.ADMIN, site: site.id! }}
                      to={site?.adminCount ? pagesEnum.USERS_AND_PERMISSIONS + '/filter' : ''}
                    />
                    <SiteMember
                      title={'HCP'}
                      icon={<DoctorIcon />}
                      count={site?.doctorCount}
                      params={{ usertype: UserRole.DOCTOR, site: site.id! }}
                      to={site?.doctorCount ? pagesEnum.USERS_AND_PERMISSIONS + '/filter' : ''}
                    />
                    <SiteMember
                      title={'Patient'}
                      icon={<PatientIcon />}
                      count={site?.patientCount}
                      params={{ sites: site.id! }}
                      to={site?.patientCount ? pagesEnum.PATIENTS + '/filter' : ''}
                    />
                  </Stack>
                </Stack>
              </Stack>
            </StyledCardContent>
          </StyledCard>
          {onEdit && (
            <Stack spacing={2} direction='row' sx={{ display: 'flex', justifyContent: 'flex-end', m: 10 }}>
              <Button
                color='secondary'
                data-testid='cancel-button'
                size='large'
                onClick={() => {
                  setOnEdit(false);
                  navigate(`/${pagesEnum.SITES}`);
                }}
              >
                cancel
              </Button>
              <Button color='secondary' variant='contained' data-testid='save-button'>
                save
              </Button>
            </Stack>
          )}
        </form>
      </FormProvider>
    </Box>
  );
};

export default SiteInformation;
