import React from 'react';

interface IconProps {
  width?: string;
  height?: string;
}
const TrashIcon: React.FC<IconProps> = ({ width = '24px', height = '24px' }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M3.9 5.7002H20.1C20.5971 5.7002 21 6.10314 21 6.6002C21 7.09725 20.5971 7.5002 20.1 7.5002H18.3V18.3002C18.3003 19.0164 18.0159 19.7033 17.5095 20.2097C17.0031 20.7161 16.3162 21.0005 15.6 21.0002H8.4C7.68385 21.0004 6.99697 20.716 6.49057 20.2096C5.98418 19.7032 5.69978 19.0163 5.7 18.3002V7.5002H3.9C3.40294 7.5002 3 7.09725 3 6.6002C3 6.10314 3.40294 5.7002 3.9 5.7002ZM15.6 19.2002C16.0968 19.1995 16.4993 18.797 16.5 18.3002V7.5002H7.5V18.3002C7.50069 18.797 7.90323 19.1995 8.4 19.2002H15.6Z'
        fill='#591C48'
      />
      <path
        d='M10.2008 4.8H13.8008C14.2978 4.8 14.7008 4.39706 14.7008 3.9C14.7008 3.40294 14.2978 3 13.8008 3H10.2008C9.70372 3 9.30078 3.40294 9.30078 3.9C9.30078 4.39706 9.70372 4.8 10.2008 4.8Z'
        fill='#591C48'
      />
      <path
        d='M11.1008 15.6002V11.1002C11.1008 10.6031 10.6978 10.2002 10.2008 10.2002C9.70372 10.2002 9.30078 10.6031 9.30078 11.1002V15.6002C9.30078 16.0973 9.70372 16.5002 10.2008 16.5002C10.6978 16.5002 11.1008 16.0973 11.1008 15.6002Z'
        fill='#591C48'
      />
      <path
        d='M14.7004 15.6002V11.1002C14.7004 10.6031 14.2974 10.2002 13.8004 10.2002C13.3033 10.2002 12.9004 10.6031 12.9004 11.1002V15.6002C12.9004 16.0973 13.3033 16.5002 13.8004 16.5002C14.2974 16.5002 14.7004 16.0973 14.7004 15.6002Z'
        fill='#591C48'
      />
    </svg>
  );
};

export default TrashIcon;
