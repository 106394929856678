import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from 'hooks/redux.hooks';
import { getAllSites } from 'state/reducers/user.reducers';
import { createNewCp, getAllCps, setError } from 'state/reducers/cp.reducers';

import { Box, Button, Stack } from '@mui/material';

//styled
import { StyledCard, StyledCardContent, StyledTypographyTitle } from '../../styled-components';

//constants
import { CpInputsForm, ICp } from 'constants/cp.constants';
import { pagesEnum } from 'constants/ui.constants';
//components
import CustomInputForm from 'components/InputsForm/CustomInput';
//icons
import { CpIcon } from 'assets/icons/Cp.icon';
import { Site } from 'constants/sites.constants';
import SelectBox from 'components/SelectBox';
import CircularIndeterminate from 'components/Loading';
import ConfirmDialog from 'components/ConfirmDialog';

interface IFormInputs {
  dataFrom: CpInputsForm;
  cp: ICp;
  setOpenProfileModal: Dispatch<SetStateAction<boolean>>;
}

const NewCp: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { sites, loading: userLoading, error: sitesError } = useAppSelector((state) => state.user);
  const { cps, loading: cpsLoading, error: cpsError } = useAppSelector((state) => state.cp);
  const [assignSite, setAssignSite] = useState('');
  const [idNumberDuplicate, setIdNumberDuplicate] = useState<string>('');

  useEffect(() => {
    dispatch(getAllSites());
    dispatch(getAllCps({}));
  }, []);

  const methods = useForm<IFormInputs>({
    defaultValues: {}
  });

  const hasError = useMemo(
    () => !!cpsError || !!sitesError || !!idNumberDuplicate,
    [cpsError, sitesError, idNumberDuplicate]
  );

  const isLoading = useMemo(() => !!userLoading || !!cpsLoading, [userLoading, cpsLoading]);

  const isSubmitDisabled = useMemo(() => {
    return hasError || isLoading || !assignSite;
  }, [hasError, isLoading, assignSite]);

  const formSubmitHandler: SubmitHandler<IFormInputs> = (data: IFormInputs) => {
    const site = sites?.find((site: Site) => site.name == assignSite);
    const dataCreateCp = {
      site_id: site?.id,
      cp_identification: data.dataFrom.idNumber.toUpperCase(),
      active: true
    };
    dispatch(createNewCp(dataCreateCp)).then(() => navigate(-1));
  };

  const validateField = (event: any) => {
    const { id, value }: { id: keyof ICp; value: string } = event.target;

    if (!value?.length) {
      setIdNumberDuplicate('ID Number is required');
      return;
    }

    if (value?.length) {
      if (id === 'idNumber' && value) {
        const exist = !!cps?.find((cp: ICp) => {
          return cp[id].toUpperCase() === value.toUpperCase();
        });
        if (exist) {
          setIdNumberDuplicate('Same CP id, Try other id');
          return;
        }
        setIdNumberDuplicate('');
      }
    }
  };

  const clearError = () => {
    dispatch(setError(undefined));
    setIdNumberDuplicate('');
  };

  if (isLoading) return <CircularIndeterminate />;
  return (
    <Box sx={{ width: '100%', height: '100%' }}>
      <FormProvider {...methods}>
        <form noValidate onChange={validateField} onSubmit={methods.handleSubmit(formSubmitHandler)}>
          <StyledCard sx={{ background: 'transparent' }}>
            <StyledCardContent>
              <Stack spacing={3}>
                <ConfirmDialog
                  openDialog={!!cpsError}
                  setOpenDialog={clearError}
                  onConfirm={clearError}
                  dialogTitle={'This CP is already assigned to another site'}
                  dialogContentText={'Press Okay to continue'}
                  confirmLabel='Okay'
                />
                <StyledTypographyTitle sx={{ justifyContent: 'flex-start' }}>Add New CP</StyledTypographyTitle>
                <Stack direction='column' spacing={2} flexWrap='wrap' useFlexGap>
                  <CustomInputForm
                    disabled={false}
                    errorMessage={idNumberDuplicate}
                    defaultValue={''}
                    controllerName={'dataFrom.idNumber'}
                    inputRegister={'dataFrom.idNumber'}
                    inputId={'idNumber'}
                    label={'iPad Serial Number'}
                    type={'text'}
                    icon={<CpIcon />}
                  />
                  <SelectBox
                    key={'sites'}
                    withAllOption
                    allOptionLabel='Personal Site'
                    items={sites.map((site) => site?.name ?? '').filter((name) => name.length)}
                    values={assignSite}
                    label={'Assign to Site'}
                    inputLabel={'Assign to Site'}
                    onFilterChange={(filterType: string, filterValue: string) => {
                      setAssignSite(filterValue);
                    }}
                    inputId={'site'}
                    type={'sites'}
                  />
                </Stack>
              </Stack>
              <Stack direction='row' sx={{ display: 'flex', justifyContent: 'flex-end', m: 10 }}>
                <Button
                  color='secondary'
                  data-testid='cancel-button'
                  size='large'
                  variant='text'
                  onClick={() => {
                    navigate(`/${pagesEnum.CP}`);
                  }}
                >
                  cancel
                </Button>
                <Button
                  color='secondary'
                  variant='contained'
                  data-testid='save-button'
                  disabled={isSubmitDisabled}
                  type='submit'
                  size='large'
                >
                  save
                </Button>
              </Stack>
            </StyledCardContent>
          </StyledCard>
        </form>
      </FormProvider>
    </Box>
  );
};

export default NewCp;
