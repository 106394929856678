import React from 'react';
interface IconProps {
  width?: string;
  height?: string;
}

export const DoctorIcon: React.FC<IconProps> = ({ width = '58px', height = '58px' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 58 58'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <title>6A475B4C-8EC3-4723-8E07-2298C9290E89</title>
      <defs>
        <circle id='path-1' cx='25' cy='25' r='25'></circle>
        <filter x='-14.0%' y='-10.0%' width='128.0%' height='128.0%' filterUnits='objectBoundingBox' id='filter-2'>
          <feMorphology radius='1.5' operator='erode' in='SourceAlpha' result='shadowSpreadOuter1'></feMorphology>
          <feOffset dx='0' dy='2' in='shadowSpreadOuter1' result='shadowOffsetOuter1'></feOffset>
          <feGaussianBlur stdDeviation='3.5' in='shadowOffsetOuter1' result='shadowBlurOuter1'></feGaussianBlur>
          <feColorMatrix
            values='0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.4 0'
            type='matrix'
            in='shadowBlurOuter1'
          ></feColorMatrix>
        </filter>
      </defs>
      <g id='💎-BlueWind-Design-System-💎' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='Style-Guide---Global-elements' transform='translate(-389.000000, -2761.000000)'>
          <g id='Group-30' transform='translate(393.000000, 2763.000000)'>
            <g id='Oval'>
              <use fill='black' fillOpacity='1' filter='url(#filter-2)' xlinkHref='#path-1'></use>
              <use fill='#4E9FB2' fillRule='evenodd' xlinkHref='#path-1'></use>
            </g>
            <g id='icon-/-24px-/-doctor' transform='translate(9.375000, 9.375000)'>
              <rect id='Rectangle' x='0' y='0' width='31.25' height='31.25'></rect>
              <g id='stethoscope' transform='translate(2.604167, 2.604167)' fill='#FFFFFF'>
                <path
                  d='M6.51041667,16.3619792 L6.51041667,17.578125 C6.51041667,22.2526042 10.2994792,26.0416667 14.9739583,26.0416667 C17.21875,26.0416667 19.3710938,25.1497396 20.9583333,23.5625 C22.5455729,21.9752604 23.4375,19.8229167 23.4375,17.578125 L23.4375,14.1002604 C24.9544271,13.5638021 26.0416667,12.1158854 26.0416667,10.4166667 C26.0416667,8.26041667 24.2916667,6.51041667 22.1354167,6.51041667 C19.9791667,6.51041667 18.2291667,8.26041667 18.2291667,10.4166667 C18.2291667,12.1158854 19.3164062,13.5638021 20.8333333,14.1002604 L20.8333333,17.578125 C20.8333333,19.1315104 20.2161458,20.6223958 19.1171875,21.7213542 C18.0182292,22.8203125 16.5273438,23.4375 14.9739583,23.4375 C11.7382812,23.4375 9.11458333,20.8138021 9.11458333,17.578125 L9.11458333,16.3619792 L13.0963542,13.9726562 C14.6653646,13.0325521 15.625,11.3359375 15.625,9.50651042 L15.625,1.30208333 C15.625,0.583333333 15.0416667,0 14.3229167,0 L10.4166667,0 L10.4166667,2.60416667 L13.0208333,2.60416667 L13.0208333,9.50651042 C13.0208333,10.421875 12.5403646,11.2695312 11.7565104,11.7395833 L7.8125,14.1067708 L3.86848958,11.7395833 C3.08463542,11.2695312 2.60416667,10.421875 2.60416667,9.50651042 L2.60416667,2.60416667 L5.20833333,2.60416667 L5.20833333,0 L1.30208333,0 C0.583333333,0 0,0.583333333 0,1.30208333 L0,9.50651042 C0,11.3359375 0.959635417,13.0325521 2.52864583,13.9726562 L6.51041667,16.3619792 Z M22.1354167,9.11458333 C22.8541667,9.11458333 23.4375,9.69791667 23.4375,10.4166667 C23.4375,11.1354167 22.8541667,11.71875 22.1354167,11.71875 C21.4166667,11.71875 20.8333333,11.1354167 20.8333333,10.4166667 C20.8333333,9.69791667 21.4166667,9.11458333 22.1354167,9.11458333 Z'
                  id='Shape'
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
