import { styled } from '@mui/material/styles';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Container,
  Stack,
  TableCell,
  tableCellClasses,
  TableRow,
  Typography
} from '@mui/material';

// Define interfaces for props upfront
interface IStyledDropDownBoxProps {
  disabled?: boolean;
  isInactive?: boolean;
}

// Define each styled component once
const StyledBox = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledTypographyTitle = styled(Typography)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  textAlign: 'center',
  fontSize: '22px',
  fontWeight: 600,
  color: theme.palette.root.header
}));

const StyledTypographySubTitle = styled(Typography)(({ theme }) => ({
  fontSize: '20px',
  fontWeight: 600,
  color: theme.palette.root.sub
}));

const StyledCard = styled(Card)`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  box-shadow: none;
`;

const StyledCardContent = styled(CardContent)`
  width: 100%;
  padding: 0;
`;

const StyledTypography = styled(Typography)(({ theme }) => ({
  fontSize: '20px',
  fontWeight: '600',
  lineHeight: '1.5',
  color: theme.palette.root.header
}));

const StyledTypographySpan = styled(Typography)(({ theme }) => ({
  fontSize: '13px',
  fontWeight: '300',
  color: theme.palette.root.sub
}));

const StyledStatusBox = styled(Box)(({ isInactive, disabled }: IStyledDropDownBoxProps) => ({
  minHeight: 44,
  minWidth: 96,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '4px',
  textAlign: 'center',
  fontSize: '13px',
  cursor: disabled ? 'pointer' : 'default',
  borderRadius: '8px',
  color: isInactive ? '#fa3d3d' : '#039165',
  backgroundColor: isInactive ? '#f1d6d7' : '#D2F0E7'
}));

const StyledContainer = styled(Container)`
  height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
`;

const StyledMemberCard = styled(Card)(({ theme }) => ({
  height: '150px',
  width: '148px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  border: 'solid 0.5px #cbcfde',
  boxShadow: '0 8px 16px -5px rgba(184, 188, 204, 0.5), 0 0 4px -1px rgba(167, 169, 172, 0.2)',
  borderRadius: theme.shape.borderRadius
}));

const StyledIcon = styled(Avatar)`
  position: absolute;
  height: 58px;
  width: 58px;
  top: 0;
  background: 'none';
`;

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.root.selectionT,
    color: theme.palette.root.header
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14
  }
}));

const StyledTableRow = styled(TableRow)`
  border: 'none';
`;

const StyledHeaderBox = styled(Box)(() => ({ display: 'flex', justifyContent: 'space-between' }));

const StyledStack = styled(Stack)(() => ({
  padding: '12px 0',
  fontSize: '18px',
  display: 'flex'
}));

const StyledMemberBox = styled(Box)(() => ({
  position: 'relative',
  padding: '24px',
  width: 'fit-content',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}));

export {
  StyledBox,
  StyledTypographySubTitle,
  StyledTypographyTitle,
  StyledCard,
  StyledCardContent,
  StyledTypography,
  StyledTypographySpan,
  StyledStatusBox,
  StyledContainer,
  StyledMemberCard,
  StyledIcon,
  StyledTableCell,
  StyledTableRow,
  StyledHeaderBox,
  StyledStack,
  StyledMemberBox
};
