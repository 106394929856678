import { combineReducers } from '@reduxjs/toolkit';
import siteReducers from './site.reducers';
import uiReducer from './ui.features';
import cpReducers from './cp.reducers';
import userReducers from './user.reducers';
import patientsReducers from './patients.reducers';
import authReducers from './auth.reducers';
import globalReducer from './global.reducers';
import auditReducer from './audit.reducers';
import searchReducer from 'state/reducers/search.reducer';

const rootReducer = combineReducers({
  ui: uiReducer,
  cp: cpReducers,
  auth: authReducers,
  user: userReducers,
  sites: siteReducers,
  patients: patientsReducers,
  global: globalReducer,
  audit: auditReducer,
  search: searchReducer
});

export default rootReducer;
