import styled from '@emotion/styled';
import { Typography, Box } from '@mui/material';

const StyledBox = styled(Box)`
  & {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
`;

const StyledTypography = styled(Typography)`
  & {
    padding: 14px;
    align-items: center;
    color: #333333;
    font-size: 21px;
    font-weight: 300;
  }
`;

export { StyledBox, StyledTypography };
