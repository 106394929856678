import { CpIcon } from 'assets/icons/Cp.icon';
import { UserIcon } from 'assets/icons/User.icon';
import { PatientPIcon } from 'assets/icons/PatientP.icon';
import { AuditIcon } from 'assets/icons/Audit.icon';
import { SiteIcon } from 'assets/icons/Site.icon';

export enum orderBy {
  ASC = 'asc',
  DESC = 'desc'
}

export enum pagesEnum {
  SITES = 'sites',
  USERS_AND_PERMISSIONS = 'users-and-permission',
  CP = 'cp',
  PATIENTS = 'patients',
  AUDIT = 'audit'
}

export const DASHBOARD_BASE = '/';

export const DashboardPages = [
  {
    name: pagesEnum.SITES,
    link: '/' + pagesEnum.SITES,
    icon: SiteIcon
  },
  {
    name: pagesEnum.USERS_AND_PERMISSIONS,
    link: DASHBOARD_BASE + pagesEnum.USERS_AND_PERMISSIONS,
    icon: UserIcon
  },
  {
    name: pagesEnum.CP,
    link: DASHBOARD_BASE + pagesEnum.CP,
    icon: CpIcon
  },
  {
    name: pagesEnum.PATIENTS,
    link: DASHBOARD_BASE + pagesEnum.PATIENTS,
    icon: PatientPIcon
  },
  {
    name: pagesEnum.AUDIT,
    link: DASHBOARD_BASE + pagesEnum.AUDIT,
    icon: AuditIcon
  }
];

export const searchSourceToPages = (source: string) => {
  switch (source) {
    case 'site':
      return pagesEnum.SITES;
    case 'user':
      return pagesEnum.USERS_AND_PERMISSIONS;
    case 'cp':
      return pagesEnum.CP;
    case 'patient':
      return pagesEnum.PATIENTS;
    default:
      break;
  }
};

export enum timing {
  TODAY = 'today',
  YESTERDAY = 'yesterday',
  DATE = 'dd.MM.yyyy',
  DATE_FOR_SERVICE = 'yyyy-MM-dd',
  FULLDATE = 'dd.MM.yyyy hh:mm a',
  TIME = 'hh:mm a',
  MONTH_DAY = 'MMM dd, yyyy',
  DATE_MONTH = 'dd MMMM yyyy'
}
