import React from 'react';

interface IconProps {
  width?: string;
  height?: string;
}
const ShieldIcon: React.FC<IconProps> = ({ width = '24px', height = '24px' }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_7033_21232)'>
        <path
          d='M12.0008 3C14.3366 5.06658 17.3853 6.14257 20.5008 6C20.9544 7.54302 21.0932 9.16147 20.9089 10.7592C20.7247 12.3569 20.2211 13.9013 19.4282 15.3005C18.6353 16.6998 17.5692 17.9254 16.2933 18.9045C15.0173 19.8836 13.5576 20.5962 12.0008 21C10.444 20.5962 8.98429 19.8836 7.70836 18.9045C6.43242 17.9254 5.36632 16.6998 4.57341 15.3005C3.78049 13.9013 3.27694 12.3569 3.09269 10.7592C2.90843 9.16147 3.04722 7.54302 3.50081 6C6.61634 6.14257 9.66498 5.06658 12.0008 3Z'
          stroke='#04A0C5'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_7033_21232'>
          <rect width='24' height='24' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ShieldIcon;
