import { timing } from 'constants/ui.constants';
import { format, isValid } from 'date-fns';
import _ from 'lodash';
import { IUserPermission, userTitles } from 'constants/user.constants';

const convertToCamelCase = (str: string) => str.replace(/_[a-z]/g, (match: string) => `${match[1].toUpperCase()}`);

export const convertKeysToCamelCase = <T = any>(obj: Record<string, any>): T => {
  const res: Record<string, any> = {};
  _.each(_.keys(obj), (key: string) => {
    res[convertToCamelCase(key)] = obj[key];
  });
  return res as T;
};

export const convertToSnakeCase = (str: string) =>
  str.replace(/[A-Z]/g, (letter: string) => `_${letter.toLowerCase()}`).replace(/^_/, '');

export const convertKeysToSnakeCase = (obj: Record<string, any>): any => {
  const res: Record<string, any> = {};
  _.each(_.keys(obj), (key: string) => {
    res[convertToSnakeCase(key)] = obj[key];
  });
  return res;
};

export const convertKeysToCamelCaseArray = <T = any>(objs: Record<string, any>[]): T[] => {
  const res: Record<string, any>[] = [];
  objs.forEach((obj) => {
    res.push(convertKeysToCamelCase(obj));
  });
  return res as T[];
};

export const getFormattedDate = (d: Date, timeFormat?: timing, defaultValue?: string) =>
  !_.isNull(d) && isValid(new Date(d)) ? format(new Date(d), timeFormat || timing.DATE_MONTH) : defaultValue || d;

type UnknownArrayOrObject = unknown[] | Record<string, unknown>;

export const dirtyValues = (
  dirtyFields: UnknownArrayOrObject | boolean,
  allValues: UnknownArrayOrObject
): UnknownArrayOrObject => {
  // NOTE: Recursive function.

  // If *any* item in an array was modified, the entire array must be submitted, because there's no
  // way to indicate "placeholders" for unchanged elements. `dirtyFields` is `true` for leaves.
  if (dirtyFields === true || Array.isArray(dirtyFields)) {
    return allValues;
  }

  // Here, we have an object.
  return Object.fromEntries(
    Object.keys(dirtyFields).map((key) => [key, dirtyValues((dirtyFields as any)[key], (allValues as any)[key])])
  );
};

export function generateSequentialArray(i: number, j: number): number[] {
  return Array.from(Array(j - i + 1).keys(), (num) => num + i);
}

export const customSort = (aName: any, bName: any, term: string) => {
  console.log('aName', aName, 'bName', bName, 'term', term);
  const aContainsTerm = aName?.toLowerCase()?.includes(term);
  const bContainsTerm = bName?.toLowerCase()?.includes(term);

  if (aContainsTerm && !bContainsTerm) return -1;
  if (!aContainsTerm && bContainsTerm) return 1;

  return 0;
};

export const isPhoneNumberValid = (value: string) => {
  const phoneNumberRegex = /^\+\d{1,3}\d{10}$/;
  return !(value?.length > 0 && !phoneNumberRegex.test(value));
};

export const getUniqRolesSet = (user: IUserPermission | null) => {
  if (user) {
    return new Set(user.sitePermissions?.map((permission) => userTitles[permission.role as keyof typeof userTitles]));
  }
  return [];
};

export const getUserRoleType = (user: IUserPermission | null) => {
  if (!user || !user.sitePermissions) {
    return 'N/A';
  }
  if (user.sitePermissions.length === 0) {
    return 'N/A';
  }
  return userTitles[user.sitePermissions[0].role as keyof typeof userTitles];
};

export const filterByFields = (data: any[], value: string, fields: string[]) => {
  // Lowercase the value for case-insensitive comparison
  const lowerValue = value.toLowerCase();

  // If there's no value, return all data
  if (!lowerValue.length) {
    return data;
  }

  return data.filter((item) => {
    // Check each specified field for a match
    return fields.some((field) => {
      // Use empty string as fallback for undefined, then lowercase for comparison
      const safeFieldValue = (item[field] || '').toLowerCase();
      return safeFieldValue.includes(lowerValue);
    });
  });
};
