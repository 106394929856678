import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Stack } from '@mui/material';

import { StyledTypography, StyledTypographySubTitle } from './styled';
import { useAppSelector } from 'hooks/redux.hooks';

const ForgotPasswordVerify: React.FC = () => {
  const navigate = useNavigate();
  const { userDetails } = useAppSelector((state) => state.auth);

  useEffect(() => {}, []);
  return (
    <>
      <Stack spacing={6}>
        <Stack>
          <StyledTypography variant='h4' gutterBottom>
            Forgot Password?
          </StyledTypography>
          <StyledTypographySubTitle>
            We sent you an Email to {userDetails?.email} with instructions to reset your password.
          </StyledTypographySubTitle>
        </Stack>
        <Stack spacing={3} sx={{ alignItems: 'center' }}>
          <Button
            color='secondary'
            data-testid='continue-button'
            fullWidth
            type='submit'
            variant='contained'
            onClick={() => {
              navigate('/login');
            }}
            sx={{ height: '60px' }}
          >
            Continue
          </Button>
        </Stack>
      </Stack>
    </>
  );
};

export default ForgotPasswordVerify;
