import React from 'react';
interface IconProps {
  width?: string;
  height?: string;
  hover?: boolean;
  fill?: string;
}

export const AuditIcon: React.FC<IconProps> = ({ width = '24px', height = '24px', hover = true, fill = '#037691' }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M22 5C22 4.448 21.552 4 21 4H3C2.448 4 2 4.448 2 5V19C2 19.552 2.448 20 3 20H21C21.552 20 22 19.552 22 19V5ZM20 10H4V18H20V10ZM4 6V8H6V6H4ZM8 6V8H20V6H8Z'
        fill={hover ? fill : '#ffff'}
      />
    </svg>
  );
};
