import React from 'react';
interface IconProps {
  width?: string;
  height?: string;
  fill?: string;
  hover?: boolean;
}

export const ProfileIcon: React.FC<IconProps> = ({ width = '60px', height = '60px', fill = '#037691' }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 60 60' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <circle cx='30' cy='30' r='30' fill={fill} />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M44.981 40.3917C44.981 38.5387 43.8578 36.7735 41.8206 35.3841C39.1501 33.5633 34.8489 32.348 29.9905 32.348C25.1321 32.348 20.8309 33.5633 18.1604 35.3841C16.1232 36.7735 15 38.5387 15 40.3917V42.5138C15 43.1719 15.2618 43.8051 15.7283 44.2717C16.1949 44.7368 16.8266 45 17.4862 45L42.4948 44.9985C43.1543 45 43.7861 44.7368 44.2527 44.2717C44.7192 43.8051 44.981 43.1719 44.981 42.5123V40.3917Z'
        fill='white'
      />
      <circle cx='29.9905' cy='23.0437' r='8.04368' fill='white' />
    </svg>
  );
};
