import React from 'react';
interface IconProps {
  width?: string;
  height?: string;
  fill?: string;
  hover?: boolean;
}

export const EmailIcon: React.FC<IconProps> = ({ width = '24px', height = '24px', fill = '#037691', hover = true }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <title>9776D5DD-0D5E-4A11-B577-01A9E0A01C42</title>
      <g id='💎-BlueWind-Design-System-💎' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='Style-Guide---Global-elements' transform='translate(-227.000000, -2650.000000)'>
          <g id='icon-/-24px-/-mail' transform='translate(227.000000, 2650.000000)'>
            <rect id='Rectangle-Copy-6' x='0' y='0' width='24' height='24'></rect>
            <g id='mail' transform='translate(2.000000, 4.000000)' fill={fill} fillRule='nonzero'>
              <path
                d='M17,0 L3,0 C1.34389967,0.00181871114 0.00181871114,1.34389967 0,3 L0,13 C0.00181871114,14.6561003 1.34389967,15.9981813 3,16 L17,16 C18.6561003,15.9981813 19.9981813,14.6561003 20,13 L20,3 C19.9981813,1.34389967 18.6561003,0.00181871114 17,0 Z M3,2 L17,2 C17.4014067,2.00047069 17.7637604,2.24054429 17.9207,2.61 L10,9.7388 L2.0793,2.61 C2.23623959,2.24054429 2.5985933,2.00047069 3,2 Z M17,14 L3,14 C2.44801218,13.9992838 2.00071619,13.5519878 2,13 L2,5.2294 L10,12.4294 L18,5.2294 L18,13 C17.9992838,13.5519878 17.5519878,13.9992838 17,14 Z'
                id='Shape'
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
