import React, { Dispatch, SetStateAction } from 'react';

import { StyledReactCodeInput } from './styled';

interface IAuthCodeProps {
  authCode: string;
  setAuthCode: Dispatch<SetStateAction<string>>;
}

const AuthenticateCode: React.FC<IAuthCodeProps> = ({ authCode, setAuthCode }) => {
  const handleAuthCodeChange = (authCode: string) => {
    setAuthCode(authCode);
  };

  return (
    <StyledReactCodeInput
      data-testid='authCode'
      name='authCode'
      type='text'
      inputMode='numeric'
      isValid={true}
      fields={6}
      onChange={handleAuthCodeChange}
      value={authCode}
    />
  );
};

export default AuthenticateCode;
