import React from 'react';
interface IconProps {
  width?: string;
  height?: string;
  hover?: boolean;
  fill?: string;
}

export const PatientPIcon: React.FC<IconProps> = ({ width = '24px', height = '24px', fill = '#FFFFFF', hover }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 32 32'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <title>04DCB015-06F1-4770-BB75-A5FF50B9A770</title>
      <g id='💎-BlueWind-Design-System-💎' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='Style-Guide---Global-elements' transform='translate(-486.000000, -2772.000000)'>
          <g id='Group-30' transform='translate(477.000000, 2763.000000)'>
            <g id='icon-/-24px-/-patient' transform='translate(9.375000, 9.375000)'>
              <g id='head-side-view' transform='translate(3.906250, 2.604167)' fill={hover ? '#037691' : '#ffff'}>
                <path
                  d='M8.19531695,21.871173 L8.20078406,25.9783393 C8.20215084,26.7328005 8.81310036,27.34375 9.56756153,27.34375 L19.1350038,27.34375 C19.5313693,27.34375 19.9085998,27.171536 20.1682876,26.8722118 C20.4279753,26.5728875 20.5441514,26.1751553 20.4881135,25.7828901 L19.2088098,16.8769682 C20.8270743,15.053687 21.8685587,12.0836796 21.8685587,9.56744227 C21.8685587,4.28758091 17.5809778,0 12.3011165,0 C7.0212551,0 2.73367419,4.28758091 2.73367419,9.56744227 L2.73367419,10.6116603 C2.73367419,10.6116603 0.14499767,15.7903801 0.14499767,15.7903801 C-0.118790381,16.3165894 -0.0162820715,16.9507741 0.400585056,17.3676413 L4.50091746,21.472074 C4.75650484,21.7290282 5.1050331,21.8725398 5.4685959,21.8725398 L8.19531695,21.871173 Z M16.7130741,18.7221177 L13.1553524,21.5691152 C12.9079657,21.7659312 12.6059078,21.8684395 12.3024832,21.8684395 L10.9288719,21.8698062 L10.9329722,24.6101951 L17.5577426,24.6101951 L16.7130741,18.7221177 Z M4.24122974,13.7101448 L3.0302649,16.1320744 C3.0302649,16.1320744 6.033075,19.1389849 6.033075,19.1389849 L10.934339,19.1362513 L10.9275051,14.8240684 L4.24122974,13.7101448 Z M19.035229,10.8207772 L13.66106,14.4044677 L13.6651604,17.6601316 L17.6247147,14.4933083 C18.3409061,13.4709587 18.8356795,12.1260497 19.035229,10.8207772 L19.035229,10.8207772 Z M18.8616483,7.65122026 C18.0320144,4.81105668 15.4078016,2.73355493 12.3011165,2.73355493 C8.52881065,2.73355493 5.46722913,5.79513646 5.46722913,9.56744227 L5.46722913,10.9342197 C5.46722913,11.0039254 5.46176202,11.0722643 5.45219457,11.1406031 L11.9922248,12.2312916 L18.8616483,7.65122026 Z'
                  id='Shape'
                ></path>
              </g>
              <rect id='Rectangle-Copy' x='0' y='0' width='31.25' height='31.25'></rect>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
