import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'hooks/redux.hooks';
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form';

import { Box, Button, Card, Stack } from '@mui/material';

import { getFormattedDate } from '../../../utils/global.util';
import { deletePatient, getPatients, updatePatient } from 'state/reducers/patients.reducers';

//styled
import { StyledCard, StyledCardContent, StyledStatusBox, StyledTypographySubTitle } from '../../styled-components';
//constants
import { IPatient, PatientInputsForm } from 'constants/patients.constants';
import { DASHBOARD_BASE, pagesEnum } from 'constants/ui.constants';
//components
import CustomInputForm from '../../../components/InputsForm/CustomInput';
import ConfirmDialog from 'components/ConfirmDialog';
import NoInfo from 'components/NoInfo';
import CircularIndeterminate from 'components/Loading';
//icons
import { CalenderIcon } from 'assets/icons/Calender.icon';
import { PhoneIcon } from 'assets/icons/Phone.icon';
import { EmailIcon } from 'assets/icons/Email.icon';
import { EditIcon } from 'assets/icons/Edit.icon';
import DeletePatientModal from './DeletePatientModal';
import TrashIcon from 'assets/icons/Trash.icon';
import { palette } from '../../../theme/palette';
import { SiteIcon } from 'assets/icons/Site.icon';
import { TabsSwitcher } from 'components/TabsSwitcher';
import ArrowBackBtn from 'components/ArrowBackBtn/arrowBackBtn';
import { disableSearch } from 'state/reducers/search.reducer';

interface IFormInputs {
  dataFrom: PatientInputsForm;
  openProfileModal?: boolean;
  setOpenProfileModal?: Dispatch<SetStateAction<boolean>>;
}

export const PatientInformation = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [deletePatientError, setDeletePatientError] = useState('');
  const { patients, loading, error } = useAppSelector((state) => state.patients);
  const [patient, setPatient] = useState<IPatient | undefined>(undefined);

  const [onEdit, setOnEdit] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [statusInactive, setStatusInactive] = useState<boolean>();
  const methods = useForm<IFormInputs>({});

  useEffect(() => {
    if (!patients) {
      dispatch(getPatients());
    }
  }, []);

  useEffect(() => {
    if (patients?.length) {
      const findPatient = patients.find((patient: IPatient) => patient.guid === id);
      setPatient(findPatient);
      setStatusInactive(!findPatient?.active);
      methods.reset({
        dataFrom: {
          phone: findPatient?.phone || '-',
          email: findPatient?.email || '-',
          createdAt: `${findPatient?.createdByFirstName} ${findPatient?.createdByLastName} ${getFormattedDate(
            new Date(findPatient?.createdAt || '')
          )}`,
          lastActivity: findPatient?.lastActivity || '',
          updatedAt: `${findPatient?.updatedByFirstName} ${findPatient?.updatedByLastName} ${getFormattedDate(
            new Date(findPatient?.updatedAt || '')
          )}`,
          assignedSite: findPatient?.assignedSite || ''
        }
      });
    }
  }, [patients, id]);

  const onConfirmHandler = async () => {
    await dispatch(updatePatient({ id, patientData: { active: !patient?.active } }));
    setStatusInactive(!statusInactive);
    setOpenDialog(false);
    setOnEdit(false);
    await dispatch(getPatients());
  };

  const onConfirmDeleteHandler = async (password: string) => {
    const res = await dispatch(deletePatient({ id, password: password }));
    const payload = res.payload.data;
    if (payload?.success) {
      await dispatch(getPatients());
      dispatch(disableSearch(false));
      setShowDeleteDialog(false);
      setDeletePatientError('');
      navigate(DASHBOARD_BASE + pagesEnum.PATIENTS);
    } else {
      if (payload?.message === 'please supply id and password' || payload?.message === 'Incorrect password') {
        payload?.message && setDeletePatientError(payload?.message);
      } else {
        setDeletePatientError('Delete patient failed');
      }
    }
  };

  const closeDialog = () => {
    setOpenDialog(false);
    setOnEdit(false);
  };

  const formSubmitHandler: SubmitHandler<IFormInputs> = () => {};

  if (loading) return <CircularIndeterminate />;
  if (error) return <NoInfo />;
  return patient ? (
    <Box sx={{ width: '100%', height: '100%' }}>
      <StyledCard sx={{ background: 'transparent' }}>
        <StyledCardContent>
          <Stack spacing={3}>
            <ArrowBackBtn title={'Patient Info'} route={`/${pagesEnum.PATIENTS}`} />
            <Stack spacing={1} direction='row' alignItems='center' justifyContent='space-between'>
              <Stack spacing={2} direction='row' alignItems='center'>
                <StyledTypographySubTitle>
                  {patient?.firstName} {patient?.lastName}
                </StyledTypographySubTitle>
                {onEdit ? (
                  <TabsSwitcher
                    onClick={() => {
                      onEdit ? setOpenDialog(true) : {};
                    }}
                    isInactive={statusInactive}
                    disabled={!onEdit}
                  />
                ) : (
                  <StyledStatusBox isInactive={statusInactive}>
                    {statusInactive ? 'Inactive' : 'Active'}
                  </StyledStatusBox>
                )}
              </Stack>
              {!onEdit && (
                <Button
                  color='secondary'
                  data-testid='edit-button'
                  startIcon={<EditIcon />}
                  variant='outlined'
                  onClick={() => {
                    setOnEdit(true);
                  }}
                >
                  Edit Patient
                </Button>
              )}
              {onEdit && (
                <Button
                  color='secondary'
                  data-testid='delete-button'
                  startIcon={<TrashIcon />}
                  variant='outlined'
                  sx={{ fontWeight: 'bold', borderColor: palette.root.t_border }}
                  onClick={() => {
                    //The main problem that solves this part of the code is to prevent autofill Search Component in App Bar. the issue happens only in chromium engine browsers.
                    dispatch(disableSearch(true));
                    setShowDeleteDialog(true);
                  }}
                >
                  Delete
                </Button>
              )}
              <ConfirmDialog
                openDialog={openDialog}
                setOpenDialog={closeDialog}
                onConfirm={onConfirmHandler}
                dialogTitle={
                  statusInactive ? 'Convert patient to active' : 'Are you sure you want to inactivate patient?'
                }
                showCancel
                confirmLabel='OK'
              />
            </Stack>

            <FormProvider {...methods}>
              <form noValidate onSubmit={methods.handleSubmit(formSubmitHandler)}>
                <Card variant='outlined' sx={{ p: 3 }}>
                  <Stack spacing={2}>
                    <Stack direction='row' spacing={2} flexWrap='wrap' useFlexGap>
                      <CustomInputForm
                        disabled={!onEdit}
                        defaultValue={patient?.phone || '-'}
                        controllerName={'dataFrom.phone'}
                        inputRegister={'dataFrom.phone'}
                        inputId={'phone'}
                        label={'Phone Number'}
                        type={'text'}
                        icon={<PhoneIcon />}
                      />
                      <CustomInputForm
                        disabled={true}
                        defaultValue={patient?.email || '-'}
                        controllerName={'dataFrom.email'}
                        inputRegister={'dataFrom.email'}
                        inputId={'email'}
                        label={'Email'}
                        type={'text'}
                        icon={<EmailIcon />}
                      />
                      <CustomInputForm
                        disabled={true}
                        defaultValue={`${patient?.createdByFirstName} ${patient?.createdByLastName} ${getFormattedDate(
                          new Date(patient?.createdAt || '')
                        )}`}
                        controllerName={'dataFrom.createdAt'}
                        inputRegister={'dataFrom.createdAt'}
                        inputId={'createdBy'}
                        label={'Created By'}
                        type={'text'}
                        icon={<CalenderIcon />}
                      />
                      <CustomInputForm
                        disabled={true}
                        defaultValue={patient?.assignedSite}
                        controllerName={'dataFrom.assignedSite'}
                        inputRegister={'dataFrom.assignedSite'}
                        inputId={'assignedSite'}
                        label={'Assigned Site'}
                        type={'text'}
                        icon={<SiteIcon />}
                      />
                    </Stack>
                  </Stack>
                </Card>
              </form>
            </FormProvider>
          </Stack>
        </StyledCardContent>
      </StyledCard>
      {onEdit && (
        <Stack spacing={2} direction='row' sx={{ display: 'flex', justifyContent: 'flex-end', m: 10 }}>
          <Button
            color='secondary'
            size='large'
            variant='text'
            onClick={() => {
              setOnEdit(false);
              navigate(`/${pagesEnum.PATIENTS}`);
            }}
          >
            cancel
          </Button>
          <Button variant='contained' size='large' color='secondary'>
            save
          </Button>
        </Stack>
      )}
      <DeletePatientModal
        resetError={() => setDeletePatientError('')}
        isOpen={showDeleteDialog}
        onClose={() => {
          dispatch(disableSearch(false));
          setShowDeleteDialog(false);
          setDeletePatientError('');
        }}
        onConfirmDeletePatient={onConfirmDeleteHandler}
        error={deletePatientError || undefined}
      />
    </Box>
  ) : null;
};
