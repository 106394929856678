import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import { createTheme, ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { palette } from './palette';

ThemeProvider.propTypes = {
  children: PropTypes.node
};

function createFontFamily(fontFamily: string) {
  return {
    h1: { fontFamily },
    h2: { fontFamily },
    h3: { fontFamily },
    h4: { fontFamily },
    h5: { fontFamily },
    h6: { fontFamily },
    subtitle1: { fontFamily },
    subtitle2: { fontFamily },
    body1: { fontFamily },
    body2: { fontFamily },
    button: { fontFamily },
    caption: { fontFamily },
    overline: { fontFamily },
    input: { fontFamily }
  };
}

const theme = createTheme({
  palette,
  shape: { borderRadius: 12 },
  typography: createFontFamily('TitilliumWebSemiBold')
});

export default function ThemeProvider({ children }: InferProps<typeof ThemeProvider.propTypes>) {
  return (
    <StyledEngineProvider injectFirst>
      <MuiThemeProvider theme={theme}>
        <>
          <CssBaseline />
          {children}
        </>
      </MuiThemeProvider>
    </StyledEngineProvider>
  );
}
