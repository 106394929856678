import React from 'react';
import { SxProps, Theme } from '@mui/material/styles';
import Tab, { tabClasses } from '@mui/material/Tab';
import Tabs, { tabsClasses } from '@mui/material/Tabs';

// eslint-disable-next-line functional/no-mixed-type
type TProps = {
  isInactive?: boolean;
  disabled: boolean;
  onClick: () => void;
};

export const tabsStyles = () => ({
  root: {
    backgroundColor: '#eee',
    borderRadius: '10px',
    minHeight: 44
  },
  flexContainer: {
    position: 'relative',
    padding: '0 3px',
    zIndex: 1
  },
  indicator: {
    top: 3,
    bottom: 3,
    right: 3,
    height: 'auto',
    borderRadius: '8px',
    backgroundColor: '#fff',
    boxShadow: '0 4px 12px 0 rgba(0,0,0,0.16)'
  }
});

export const tabActiveItemStyles = (theme: Theme) => ({
  root: {
    fontWeight: 500,
    minHeight: 44,
    minWidth: 96,
    opacity: 0.7,
    color: theme.palette.text.primary,
    textTransform: 'initial',
    '&:hover': {
      opacity: 1
    },
    [`&.${tabClasses.selected}`]: {
      color: 'green',
      opacity: 1
    },
    [theme.breakpoints.up('md')]: {
      minWidth: 120
    }
  }
});

export const tabInactiveItemStyles = (theme: Theme) => ({
  root: {
    fontWeight: 500,
    minHeight: 44,
    minWidth: 96,
    opacity: 0.7,
    color: theme.palette.text.primary,
    textTransform: 'initial',
    '&:hover': {
      opacity: 1
    },
    [`&.${tabClasses.selected}`]: {
      color: 'red',
      opacity: 1
    },
    [theme.breakpoints.up('md')]: {
      minWidth: 120
    }
  }
});

function toSx<ClassKey extends string>(
  styles: (theme: Theme) => Partial<Record<ClassKey, any>>,
  classes: Record<ClassKey, string>
) {
  return function sxCallback(theme: Theme) {
    // eslint-disable-next-line functional/no-let
    let sx = {};
    Object.entries<any>(styles(theme)).forEach(([key, value]) => {
      if (key === 'root') {
        sx = { ...sx, ...value };
      } else {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        sx[`& .${classes[key]}`] = value;
      }
    });
    return sx;
  } as SxProps<Theme>;
}

export function TabsSwitcher({ onClick, isInactive, disabled }: TProps) {
  const activeSx = toSx(tabActiveItemStyles, tabClasses);
  const inactiveSx = toSx(tabInactiveItemStyles, tabClasses);

  return (
    <Tabs value={isInactive ? 0 : 1} onChange={onClick} sx={toSx(tabsStyles, tabsClasses)}>
      <Tab disabled={disabled} disableRipple label={'Inactive'} sx={inactiveSx} />
      <Tab disabled={disabled} disableRipple label={'Active'} sx={activeSx} />
    </Tabs>
  );
}
