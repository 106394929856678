import React from 'react';
interface IconProps {
  width?: string;
  height?: string;
  hover?: boolean;
  fill?: string;
}

export const SwiftIcon: React.FC<IconProps> = ({ width = '24px', height = '24px', hover, fill = '#ffffff' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <title>1A72977E-5B21-47C6-9BBA-D4FE9280BEF5</title>
      <g id='💎-BlueWind-Design-System-💎' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='Style-Guide---Global-elements' transform='translate(-448.000000, -2601.000000)'>
          <g id='icon-/-24px-/-swift-tracking' transform='translate(448.000000, 2601.000000)'>
            <g id='browser' transform='translate(2.000000, 2.000000)' fill='#037691'>
              <path
                d='M10,0 C4.481,0 0,4.481 0,10 C0,15.519 4.481,20 10,20 C15.519,20 20,15.519 20,10 C20,4.481 15.519,0 10,0 Z M13.943,11 L6.057,11 C6.36,13.713 7.854,16.536 10,17.857 C12.146,16.536 13.64,13.712 13.943,11 L13.943,11 Z M17.938,11 L15.951,11 C15.743,13.166 14.881,15.395 13.581,17.155 C15.917,15.983 17.601,13.699 17.938,11 Z M4.049,11 L2.062,11 C2.399,13.699 4.083,15.982 6.419,17.155 C5.119,15.395 4.257,13.166 4.049,11 L4.049,11 Z M6.419,2.845 C4.083,4.017 2.399,6.301 2.062,9 L4.049,9 C4.257,6.834 5.119,4.605 6.419,2.845 Z M13.581,2.845 C14.881,4.605 15.743,6.834 15.951,9 L17.938,9 C17.601,6.301 15.917,4.018 13.581,2.845 L13.581,2.845 Z M10,2.143 C7.854,3.464 6.36,6.288 6.057,9 L13.943,9 C13.64,6.287 12.146,3.464 10,2.143 L10,2.143 Z'
                id='path2'
              ></path>
            </g>
            <rect id='Rectangle-Copy-16' x='0' y='0' width='24' height='24'></rect>
          </g>
        </g>
      </g>
    </svg>
  );
};
