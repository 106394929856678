import styled from '@emotion/styled';
import ReactCodeInput from 'react-code-input';

const StyledReactCodeInput = styled(ReactCodeInput)`
  input {
    height: 80px;
    width: 60px;
    text-align: center;
    margin: 5px;
    font-size: 20px;
    font-weight: 600;
    color: #404040;
    box-sizing: border-box;
    border: 1px solid #dbdbdb;
    border-radius: 12px;
  }
  & input:focus {
    outline: none;
    border: 1px solid #666666;
  }
`;

export { StyledReactCodeInput };
