import React, { useDeferredValue, useEffect, useState } from 'react';
import { Box, Pagination, Stack } from '@mui/material';
import _ from 'lodash';

import { IColumnType } from 'components/Table/types';

import BasicTable from 'components/Table';

import { useAppDispatch, useAppSelector } from 'hooks/redux.hooks';
import NoInfo from 'components/NoInfo';

import {
  ApiNeedToModifiedChanges,
  AuditDefaultSort,
  AuditId,
  AuditTableColumn,
  AuditTableColumnTitles,
  IAudit
} from 'constants/audit.constants';
import { getAudits } from 'state/reducers/audit.reducers';
import { orderBy } from 'constants/ui.constants';
import { getSites } from 'state/reducers/site.reducers';
import { getPatients } from 'state/reducers/patients.reducers';
import { getModifiedAuditsChanges } from './Audit.services';
import { getAllUsers } from 'state/reducers/user.reducers';
import { StyledTypography } from '../styled-components';
import SearchComponent from 'components/Search/searchComponent';
import { filterByFields } from '../../utils/global.util';

const AuditPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const [searchValue, setSearchValue] = useState<string>('');
  const [pageNumber, setPageNumber] = useState(1);
  const [orderColumn, setOrderColumn] = useState('');
  const [modifiedAudits, setModifiedAudits] = useState<IAudit[]>([]);
  const [orderDirection, setOrderDirection] = useState(orderBy.DESC);
  const { audits, totalNumber, loading, error } = useAppSelector((state) => state.audit);
  const { sites } = useAppSelector((state) => state.sites);
  const { patients } = useAppSelector((state) => state.patients);
  const { users } = useAppSelector((state) => state.user);
  const [siteIdName, setSiteIdName] = useState<Record<number, string>>({});
  const [patientIdName, setPatientIdName] = useState<Record<string, string>>({});
  const [userIdName, setUserIdName] = useState<Record<number, string>>({});
  const debouncedValue = useDeferredValue(searchValue);

  useEffect(() => {
    dispatch(getSites());
    dispatch(getPatients());
    dispatch(getAllUsers());
  }, []);

  useEffect(() => {
    if (!!sites?.length && !!patients?.length) {
      const siteIdName = sites.reduce((acc: Record<number, string>, site) => {
        const key = site.id!;
        acc[key] = site.name!;
        return acc;
      }, {});
      const patientIdName = patients.reduce((acc: Record<string, string>, patient) => {
        const key = patient.guid!;
        acc[key] = patient.firstName + ' ' + patient.lastName;
        return acc;
      }, {});
      const userIdName = users.reduce((acc: Record<number, string>, user) => {
        const key = user.id!;
        acc[key] = user.firstName + ' ' + user.lastName;
        return acc;
      }, {});
      setSiteIdName(siteIdName);
      setPatientIdName(patientIdName);
      setUserIdName(userIdName);
    }
  }, [sites?.length, patients?.length]);

  const auditsColumns = () => {
    const columns: IColumnType[] = [];
    _.keys(AuditTableColumn).forEach((element) => {
      const props: IColumnType = {
        fieldId: AuditId,
        name: element,
        title: AuditTableColumnTitles[element as keyof typeof AuditTableColumnTitles] || element,
        type: AuditTableColumn[element]
      };
      columns.push(props);
    });
    return columns;
  };

  useEffect(() => {
    updateModifiedAudits();
  }, [audits, patientIdName, siteIdName]);

  const getAuditChanges = (audit: IAudit): string => {
    if (audit?.changes && audit.changes !== '{}') {
      if (ApiNeedToModifiedChanges.includes(audit.api)) {
        return getModifiedAuditsChanges(audit.changes, patientIdName, siteIdName, userIdName);
      } else {
        return audit.changes;
      }
    }

    return '{}';
  }
	
  const updateModifiedAudits = () => {
    if (
      !!Object.keys(patientIdName).length &&
      !!Object.keys(siteIdName).length &&
      !!Object.keys(userIdName).length &&
      !!audits?.length
    ) {
      const modified = audits?.map((audit) => ({
        ...audit,
        changes: getAuditChanges(audit)
      }));
      setModifiedAudits(modified);
    }
  };

  const onOrderPagination = (orderColumn: string, orderDirection: orderBy) => {
    setOrderColumn(orderColumn);
    setOrderDirection(orderDirection);
  };
  const handlePaginationChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPageNumber(value);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const filteredAuditsData = filterByFields(modifiedAudits, debouncedValue, ['firstName', 'lastName']);

  useEffect(() => {
    dispatch(getAudits({ pageNumber, orderColumn, orderDirection }));
  }, [pageNumber, orderColumn, orderDirection, dispatch]);

  if (error) return <NoInfo />;

  return (
    <Box sx={{ width: '100%' }}>
      <Stack spacing={2}>
        <StyledTypography>Audits</StyledTypography>
        <SearchComponent handleInputChange={handleInputChange} searchValue={searchValue} />
        <BasicTable
          fieldId='id'
          data={filteredAuditsData}
          columns={auditsColumns()}
          defaultSortBy={AuditDefaultSort}
          orderDirection={orderBy.DESC}
          onOrderPagination={onOrderPagination}
          maxLength={{ changes: 15 }}
        >
          <Pagination count={totalNumber} page={pageNumber} onChange={handlePaginationChange} />
        </BasicTable>
      </Stack>
    </Box>
  );
};

export default AuditPage;
