import React from 'react';
interface IconProps {
  width?: string;
  height?: string;
  hover?: boolean;
  fill?: string;
}

export const CityIcon: React.FC<IconProps> = ({ width = '24px', height = '24px', hover, fill = '#ffffff' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <title>1E76E3CA-8253-4036-AAB6-54D1D52A2F2A</title>
      <g id='💎-BlueWind-Design-System-💎' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='Style-Guide---Global-elements' transform='translate(-337.000000, -2650.000000)'>
          <g id='icon-/-24px-/-city' transform='translate(337.000000, 2650.000000)'>
            <path
              d='M4,22 C3.448,22 3,21.552 3,21 L3,3 C3,2.448 3.448,2 4,2 L14,2 C14.552,2 15,2.448 15,3 L15,10 L20,10 C20.552,10 21,10.448 21,11 L21,21 C21,21.552 20.552,22 20,22 L4,22 Z M10,20 L13,20 L13,4 L5,4 L5,20 L8,20 L8,18 C8,17.448 8.448,17 9,17 C9.552,17 10,17.448 10,18 L10,20 Z M15,12 L15,20 L19,20 L19,12 L15,12 Z M8,12 L10,12 C10.552,12 11,11.552 11,11 C11,10.448 10.552,10 10,10 L8,10 C7.448,10 7,10.448 7,11 C7,11.552 7.448,12 8,12 Z M8,8 L10,8 C10.552,8 11,7.552 11,7 C11,6.448 10.552,6 10,6 L8,6 C7.448,6 7,6.448 7,7 C7,7.552 7.448,8 8,8 Z'
              id='path2'
              fill='#037691'
            ></path>
            <rect id='Rectangle' x='0' y='0' width='24' height='24'></rect>
          </g>
        </g>
      </g>
    </svg>
  );
};
