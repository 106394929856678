import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IUserPermission } from 'constants/user.constants';
import { API_ENDPOINTS } from 'constants/db.constants';
import API from '../../services/api.service';
import { convertKeysToCamelCase } from '../../utils/global.util';
import _ from 'lodash';
import { Site } from 'constants/sites.constants';
import { updateUserProfile } from './auth.reducers';

interface IUserState {
  loading: boolean;
  error?: IStateError;
  users: IUserPermission[];
  user: IUserPermission | null;
  sites: Site[];
  errorCode?: number;
}

export interface IStateError {
  errorCode: number | null;
  errorMessage: string;
}

const initialState: IUserState = {
  loading: false,
  error: undefined,
  users: [],
  user: null,
  sites: []
};

export const getAllUsers = createAsyncThunk('user/getAllUsers', () =>
  API.getInstance().get(API_ENDPOINTS.USERS.GET_ALL_USERS)
);

export const getUserById = createAsyncThunk('user/getUserById', (id?: string) =>
  API.getInstance().get(`${API_ENDPOINTS.USERS.GET_USER}/${id}`)
);

export const getAllSites = createAsyncThunk('user/getAllSites', () =>
  API.getInstance().get(API_ENDPOINTS.SITES.GET_ALL_SITES)
);

export const createNewUser = createAsyncThunk('user/createNewUser', (newUserData: any, { rejectWithValue }) =>
  API.getInstance()
    .post(API_ENDPOINTS.USERS.CREATE_USER, newUserData)
    .then((response) => response.data)
    .catch((err) => rejectWithValue(err))
);

export const updateUser = createAsyncThunk('user/updateUser', (updatedUserData: any) =>
  API.getInstance().put(`${API_ENDPOINTS.USERS.UPDATE_USER}/${updatedUserData?.id}`, updatedUserData?.userData)
);

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setError: (state, { payload }) => {
      if (!_.keys(payload).length) {
        state.error = undefined;
        return;
      }
      state.error = {
        errorCode: payload.errorCode,
        errorMessage: payload.data?.message
      };
    }
  },
  extraReducers: {
    [getAllUsers.pending.type]: (state) => {
      state.loading = true;
      state.error = undefined;
    },
    [getAllUsers.fulfilled.type]: (state, { payload }) => {
      const convertedToCamelCaseData = _.map(payload?.data?.data, (user) => convertKeysToCamelCase(user));
      state.users = convertedToCamelCaseData;
      state.loading = false;
    },
    [getAllUsers.rejected.type]: (state, { payload }) => {
      state.loading = false;
      state.error = {
        errorCode: payload?.data?.statusCode,
        errorMessage: payload?.data?.message
      };
    },
    [getUserById.pending.type]: (state) => {
      state.loading = true;
      state.error = undefined;
    },
    [getUserById.fulfilled.type]: (state, { payload }) => {
      const convertedToCamelCaseData = convertKeysToCamelCase(payload?.data?.data);
      state.user = convertedToCamelCaseData;
      state.loading = false;
    },
    [getUserById.rejected.type]: (state, { payload }) => {
      state.loading = false;
      state.error = {
        errorCode: payload?.data?.statusCode,
        errorMessage: payload?.data?.message
      };
    },
    [getAllSites.pending.type]: (state) => {
      state.loading = true;
      state.error = undefined;
    },
    [getAllSites.fulfilled.type]: (state, { payload }) => {
      const convertedToCamelCaseData = _.map(payload?.data?.data, (site) => convertKeysToCamelCase(site));
      state.sites = convertedToCamelCaseData;
      state.loading = false;
    },
    [getAllSites.rejected.type]: (state, { payload }) => {
      state.loading = false;
      state.error = {
        errorCode: payload?.data?.statusCode,
        errorMessage: payload?.data?.message
      };
    },
    [createNewUser.pending.type]: (state) => {
      state.loading = true;
      state.error = undefined;
    },
    [createNewUser.fulfilled.type]: (state, { payload }) => {
      state.loading = false;
    },
    [createNewUser.rejected.type]: (state, { payload }) => {
      state.loading = false;
      state.error = {
        errorCode: payload?.data?.statusCode,
        errorMessage: payload?.data?.message
      };
    },
    [updateUser.pending.type]: (state) => {
      state.loading = true;
      state.error = undefined;
    },
    [updateUser.fulfilled.type]: (state, { payload }) => {
      const convertedToCamelCaseData = convertKeysToCamelCase(payload?.data);
      state.user = convertedToCamelCaseData;
      state.loading = false;
    },
    [updateUser.rejected.type]: (state, { payload }) => {
      state.loading = false;
    },
    [updateUserProfile.fulfilled.type]: (state, { payload }) => {
      const convertedToCamelCaseData = convertKeysToCamelCase(payload);
      const index = state.users.findIndex((u) => u.id === convertedToCamelCaseData.id);
      if (index !== -1) {
        state.users[index] = convertedToCamelCaseData;
      }
    }
  }
});

export const { setError } = userSlice.actions;

export default userSlice.reducer;
