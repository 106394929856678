/* eslint-disable functional/no-mixed-type */
import React, { ChangeEventHandler } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { styled, alpha } from '@mui/material/styles';
import { InputBase, TextField, Typography } from '@mui/material';

interface ISearchResultsAreaProps {
  show: boolean;
}
interface ISearchResultItem {
  id: string;
  name: string;
  source: string; // Assuming source is a string, adjust the type as necessary
}

interface ISearchComponentProps {
  handleInputChange: ChangeEventHandler<HTMLInputElement>;
  searchValue: string;
  showSearchResults?: boolean;
  searchResults?: ISearchResultItem[];
  onClick?: (item: ISearchResultItem) => void;
  isNavBarLayout?: boolean;
  disabled?: boolean;
}

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25)
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto'
  }
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: 1000
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  width: '100%',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch'
      }
    }
  }
}));

const StyledTextBase = styled(TextField)(({ theme }) => ({
  color: 'inherit',
  backgroundColor: '#ffff',
  borderRadius: theme.shape.borderRadius,
  width: '100%',
  '& .MuiInputBase-input': {
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch'
      }
    }
  }
}));

const SearchResultsArea = styled('div')(({ show }: ISearchResultsAreaProps) => ({
  display: show ? 'flex' : 'none',
  flexDirection: 'column',
  position: 'absolute',
  top: '42px',
  backgroundColor: 'white',
  width: '100%',
  borderRadius: '0 0 12px 12px',
  boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
  maxHeight: '200px',
  overflowY: 'scroll',
  zIndex: '999'
}));

const SearchResultItem = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  height: '40px',
  paddingInline: '32px 20px',
  paddingBlock: '0',
  justifyContent: 'space-between',
  alignItems: 'center',
  cursor: 'pointer',
  minHeight: '40px',
  '&:hover': {
    backgroundColor: '#00000020'
  }
}));

const SearchResultItemTitle = styled(Typography)(() => ({
  fontSize: '13px',
  fontWeight: '600',
  color: ' #333'
}));

const SearchResultItemType = styled(Typography)(() => ({
  fontSize: '13px',
  fontWeight: 'normal',
  color: ' #666'
}));

const SearchResultFooter = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  height: '40px',
  paddingInline: '0 20px',
  paddingBlock: '0',
  justifyContent: 'end',
  alignItems: 'center',
  minHeight: '40px'
}));

const SearchResultFooterTitle = styled(Typography)(() => ({
  fontSize: '13px',
  fontWeight: 'normal',
  color: ' #666'
}));

const SearchComponent: React.FC<ISearchComponentProps> = ({
  handleInputChange,
  searchValue,
  showSearchResults = false,
  searchResults = [],
  onClick,
  isNavBarLayout = false,
  disabled = false
}) => {
  return (
    <Search sx={{ minWidth: 160, maxWidth: 400 }}>
      <SearchIconWrapper>
        <SearchIcon />
      </SearchIconWrapper>
      {isNavBarLayout ? (
        <StyledInputBase
          placeholder='Search…'
          inputProps={{ 'aria-label': 'search' }}
          onChange={handleInputChange}
          value={searchValue}
          disabled={disabled}
        />
      ) : (
        <StyledTextBase
          placeholder='Search…'
          inputProps={{ 'aria-label': 'search' }}
          onChange={handleInputChange}
          value={searchValue}
          disabled={disabled}
        />
      )}
      <SearchResultsArea show={showSearchResults}>
        {searchResults?.map((item) => (
          <SearchResultItem key={item.id + item.source} onClick={() => onClick && onClick(item)}>
            <SearchResultItemTitle>{item.name}</SearchResultItemTitle>
            <SearchResultItemType>{item.source}</SearchResultItemType>
          </SearchResultItem>
        ))}
        {searchResults.length === 0 && searchValue.length > 0 && (
          <SearchResultFooter>
            <SearchResultFooterTitle>No Results</SearchResultFooterTitle>
          </SearchResultFooter>
        )}
      </SearchResultsArea>
    </Search>
  );
};

export default SearchComponent;
