import React, { Dispatch, SetStateAction } from 'react';
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form';

import { Button, Stack } from '@mui/material';

import { CheckCircle } from '@mui/icons-material';
import {
  StyledStatusBox,
  StyledTypographySpan,
  StyledTypographySubTitle,
  StyledTypographyTitle
} from '../../styled-components';
import CustomInputForm from '../../../components/InputsForm/CustomInput';
import { UserInfoIcon } from 'assets/icons/UserInfo.icon';
import { PhoneIcon } from 'assets/icons/Phone.icon';
import { EmailIcon } from 'assets/icons/Email.icon';
import { CalenderIcon } from 'assets/icons/Calender.icon';
import { PulseIcon } from 'assets/icons/Pulse.icon';
import { IUser } from 'constants/user.constants';
import { dirtyValues, getFormattedDate } from '../../../utils/global.util';

// eslint-disable-next-line functional/no-mixed-type
interface IProfileProps extends Partial<IUser> {
  disabled: boolean;
  setOnEdit: Dispatch<SetStateAction<boolean>>;
  onUpdateUser(data: Partial<IUser>): void;
}

const UserProfileForm: React.FC<IProfileProps> = (props: IProfileProps) => {
  const {
    firstName,
    lastName,
    active,
    isSuperAdmin,
    phoneNumber,
    email,
    disabled,
    lastLogin,
    setOnEdit,
    onUpdateUser,
    createdAt,
    updatedAt,
    createdUserFirstName,
    createdUserLastName,
    updatedUserFirstName,
    updatedUserLastName
  } = props;
  const methods = useForm<Partial<IUser>>({});

  const formSubmitHandler: SubmitHandler<Partial<IUser>> = (data: Partial<IUser>) => {
    onUpdateUser(dirtyValues(methods.formState.dirtyFields, data) as Partial<IUser>);
  };
  return (
    <FormProvider {...methods}>
      <form noValidate onSubmit={methods.handleSubmit(formSubmitHandler)}>
        <Stack spacing={3}>
          <StyledTypographyTitle>My Profile</StyledTypographyTitle>
          {!disabled && (
            <Button
              color='secondary'
              data-testid='edit-button'
              variant='outlined'
              onClick={() => {
                setOnEdit(true);
              }}
            >
              Edit
            </Button>
          )}
          <Stack spacing={1} direction='row' display='flex' alignItems='center'>
            <StyledTypographySubTitle>{`${firstName} ${lastName}`}</StyledTypographySubTitle>
            <StyledStatusBox isInactive={!active}>{active ? 'Active' : 'Inactive'}</StyledStatusBox>
          </Stack>
          <StyledTypographySpan>{isSuperAdmin ? 'Super admin' : ''}</StyledTypographySpan>
          <Stack spacing={2}>
            {disabled && (
              <Stack direction='row' spacing={2}>
                <CustomInputForm
                  disabled={!disabled}
                  defaultValue={firstName}
                  controllerName={'firstName'}
                  inputRegister={'firstName'}
                  inputId={'firstName'}
                  label={'First Name'}
                  type={'text'}
                  icon={<UserInfoIcon />}
                />
                <CustomInputForm
                  disabled={!disabled}
                  defaultValue={lastName}
                  controllerName={'lastName'}
                  inputRegister={'lastName'}
                  inputId={'lastName'}
                  label={'Last Name'}
                  type={'text'}
                  icon={<UserInfoIcon />}
                />
              </Stack>
            )}
            <Stack direction='row' spacing={2}>
              <CustomInputForm
                disabled={!disabled}
                defaultValue={phoneNumber}
                controllerName={'phoneNumber'}
                inputRegister={'phoneNumber'}
                inputId={'phoneNumber'}
                label={'Phone Number'}
                type={'text'}
                icon={<PhoneIcon />}
              />
              <CustomInputForm
                disabled={!disabled}
                defaultValue={email}
                controllerName={'email'}
                inputRegister={'email'}
                inputId={'email'}
                label={'E-mail'}
                type={'email'}
                icon={<EmailIcon />}
              />
            </Stack>
            <Stack direction='column' spacing={2}>
              <CustomInputForm
                disabled={true}
                defaultValue={`${createdUserFirstName} ${createdUserLastName} ${getFormattedDate(
                  new Date(createdAt || '')
                )}`}
                controllerName={'createdBy'}
                inputRegister={'createdBy'}
                inputId={'createdBy'}
                label={'Created By'}
                type={'text'}
                icon={<CalenderIcon />}
              />
              <CustomInputForm
                disabled={true}
                defaultValue={getFormattedDate(new Date(lastLogin || ''))}
                controllerName={'lastLogin'}
                inputRegister={'lastLogin'}
                inputId={'lastLogin'}
                label={'Last Login'}
                type={'text'}
                icon={<PulseIcon />}
              />
              <CustomInputForm
                disabled={true}
                defaultValue={`${updatedUserFirstName} ${updatedUserLastName} ${getFormattedDate(
                  new Date(updatedAt || '')
                )}`}
                controllerName={'lastEdit'}
                inputRegister={'lastEdit'}
                inputId={'lastEdit'}
                label={'Last Edit'}
                type={'text'}
                icon={<UserInfoIcon />}
              />
            </Stack>
          </Stack>
          {disabled && (
            <Stack spacing={2} direction='row' sx={{ display: 'flex', justifyContent: 'space-between', m: 10 }}>
              <Button
                color='secondary'
                variant='text'
                data-testid='cancel-button'
                size='large'
                onClick={() => {
                  setOnEdit(false);
                }}
              >
                cancel
              </Button>
              <Button variant='contained' color='secondary' data-testid='save-button' type='submit' size='large'>
                save
              </Button>
            </Stack>
          )}
        </Stack>
      </form>
    </FormProvider>
  );
};

export default UserProfileForm;
