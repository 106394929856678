import React, { useState } from 'react';

import { Box, Button, Divider, IconButton, Menu, Stack, Tooltip } from '@mui/material';
import {
  StyledMainGrid,
  StyledProfileCircle,
  StyledProfileOption,
  StyledProfileSubtitle,
  StyledProfileTitle
} from './styled';
import { ProfileIcon } from 'assets/icons/Profile.icon';
import { AccountCircle } from '@mui/icons-material';
import UserProfileModal from '../../pages/Profile';

// eslint-disable-next-line functional/no-mixed-type
interface INavBarModalProps {
  firstName: string;
  lastName: string;
  email: string;
  logout: () => void;
}

const NavBarModal = ({ firstName, lastName, email, logout }: INavBarModalProps) => {
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
  const [openProfileModal, setOpenProfileModal] = useState(false);
  const onOpenProfileModal = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleOpenProfileModal = () => {
    setAnchorElUser(null);
    setOpenProfileModal(true);
  };

  return (
    <Box sx={{ flexGrow: 0 }}>
      <Tooltip title='Open settings'>
        <IconButton onClick={onOpenProfileModal} sx={{ p: 0 }}>
          <AccountCircle
            sx={{
              width: '40px',
              height: '40px',
              color: '#F5981E'
            }}
          />
        </IconButton>
      </Tooltip>
      <Menu
        sx={{ mt: '45px' }}
        id='menu-appbar'
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        <Stack spacing={3} sx={{ p: 2 }}>
          <StyledMainGrid>
            <StyledProfileOption data-testid='profile-option'>
              <StyledProfileCircle>
                <ProfileIcon />
              </StyledProfileCircle>
              <StyledProfileTitle>{`${firstName} ${lastName}`}</StyledProfileTitle>
              <StyledProfileSubtitle>{email}</StyledProfileSubtitle>
            </StyledProfileOption>
            <Divider />
            <Button sx={{ mt: 1 }} variant='text' onClick={handleOpenProfileModal}>
              Profile
            </Button>
            <Divider />
            <Button sx={{ mt: 1 }} variant='text' onClick={() => logout()}>
              Sign out
            </Button>
          </StyledMainGrid>
        </Stack>
      </Menu>
      <UserProfileModal openProfileModal={openProfileModal} setOpenProfileModal={setOpenProfileModal} />
    </Box>
  );
};

export default NavBarModal;
