import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';

import { Box, Button, Card, Stack } from '@mui/material';

//styled
import { StyledCard, StyledCardContent, StyledStatusBox, StyledTypographySubTitle } from '../../styled-components';
//constants
import { ISitePermission, IUser, UsersAndPermissionInputsForm } from 'constants/user.constants';
//components
import CustomInputForm from '../../../components/InputsForm/CustomInput';
import ConfirmDialog from 'components/ConfirmDialog';
import { SitePermissionTable } from '../SitePermissionTable';
//icons
import { UserInfoIcon } from 'assets/icons/UserInfo.icon';
import { PhoneIcon } from 'assets/icons/Phone.icon';
import { EmailIcon } from 'assets/icons/Email.icon';
import { useAppDispatch, useAppSelector } from 'hooks/redux.hooks';
import { getAllSites, getUserById, updateUser } from 'state/reducers/user.reducers';
import _ from 'lodash';
import { convertToSnakeCase, getFormattedDate, getUserRoleType, isPhoneNumberValid } from '../../../utils/global.util';
import NoInfo from 'components/NoInfo';
import CircularIndeterminate from 'components/Loading';
import { EditIcon } from 'assets/icons/Edit.icon';
import { errorsMessages } from '../helper';
import ShieldIcon from 'assets/icons/Shield.icon';
import { TabsSwitcher } from 'components/TabsSwitcher';
import { pagesEnum } from 'constants/ui.constants';
import ArrowBackBtn from 'components/ArrowBackBtn/arrowBackBtn';

interface IFormInputs {
  dataForm: UsersAndPermissionInputsForm;
  sitePermissions: ISitePermission[];
  openProfileModal?: boolean;
  setOpenProfileModal?: Dispatch<SetStateAction<boolean>>;
}

export const UserInformation: React.FC = () => {
  const dispatch = useAppDispatch();
  const [onEdit, setOnEdit] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [formError, setFormError] = useState<{ [key: string]: string }>({});
  const { user, loading, error, sites, users } = useAppSelector((state) => state.user);
  const { id } = useParams();

  const form = useForm<IFormInputs>({
    reValidateMode: 'onChange',
    defaultValues: {
      dataForm: {
        firstName: user?.firstName,
        lastName: user?.lastName,
        email: user?.email,
        phoneNumber: user?.phoneNumber
      },
      sitePermissions: user?.sitePermissions
    }
  });

  const isDirtySitePermissions = !_.isEmpty(form.formState.dirtyFields);
  const selectedPermissions = form.watch('sitePermissions');

  const isDisabledSaveButton =
    !_.isEmpty(formError) || !form.formState.isValid || !isDirtySitePermissions || _.isEmpty(selectedPermissions);

  const sitePermissionsToEdit = _.differenceWith(sites, selectedPermissions, (a, b) => a.id === b.id);

  useEffect(() => {
    form.reset(user || {});
  }, [user]);

  useEffect(() => {
    dispatch(getUserById(id)).then(() => {
      dispatch(getAllSites());
    });
  }, [id]);

  const handleCancelClick = () => {
    setOnEdit(false);
    form.reset(user || {});
  };

  const formSubmitHandler: SubmitHandler<IFormInputs> = (data: IFormInputs) => {
    const { sitePermissions } = data;
    const df = form.formState.dirtyFields;
    const updatedUser = {
      id,
      userData: {}
    };
    if (_.has(df, 'sitePermissions')) {
      const parsedSitePermissions = _.map(sitePermissions, (sitePermission) => ({
        site_id: sitePermission.id,
        role: sitePermission.role
      }));
      _.assign(updatedUser.userData, { site_permissions: parsedSitePermissions });
    }
    if (!_.isEmpty(df.dataForm)) {
      _.keys(df.dataForm).forEach((key) => {
        _.assign(updatedUser.userData, { [convertToSnakeCase(key)]: _.get(data.dataForm, key) });
      });
    }
    dispatch(updateUser(updatedUser)).then((res) => {
      if (_.has(res, 'error')) {
        form.reset(user || {});
      }
      setOnEdit(false);
    });
  };

  const onConfirmHandler = () => {
    dispatch(updateUser({ id, userData: { active: !user?.active } }));
    setOpenDialog(false);
    setOnEdit(false);
  };

  const validateField = (event: any) => {
    const { id, value }: { id: keyof IUser; value: string } = event.target;
    if (id === 'phoneNumber') {
      const isValid = isPhoneNumberValid(value);
      if (!isValid) {
        setFormError({
          ...formError,
          [id]: 'Phone number invalid format. Example: +1234567890'
        });
      } else {
        setFormError({
          ..._.omit(formError, id)
        });
      }
    }

    if (value?.length > 0) {
      if (['email'].includes(id)) {
        const exist = !!users?.find((_user: IUser) => {
          return _user[id] === value && _user.id !== user?.id;
        });
        if (exist) {
          setFormError({
            ...formError,
            [id]: _.get(errorsMessages, id) || ''
          });
          return;
        }
        setFormError({
          ..._.omit(formError, id)
        });
      }
    }
  };

  if (error) return <NoInfo />;
  if (loading) return <CircularIndeterminate />;

  return (
    <Box sx={{ width: '100%', height: '100%' }}>
      <FormProvider {...form}>
        <form onChange={validateField} onSubmit={form.handleSubmit(formSubmitHandler)}>
          <StyledCard sx={{ background: 'transparent' }}>
            <StyledCardContent>
              <Stack spacing={3}>
                <ArrowBackBtn title={'User Info'} route={`/${pagesEnum.USERS_AND_PERMISSIONS}`} />
                <Stack spacing={1} direction='row' alignItems='center' justifyContent='space-between'>
                  <Stack spacing={2} direction='row' alignItems='center'>
                    <StyledTypographySubTitle>{user?.firstName + ' ' + user?.lastName}</StyledTypographySubTitle>
                    {onEdit ? (
                      <TabsSwitcher
                        onClick={() => {
                          onEdit ? setOpenDialog(true) : {};
                        }}
                        isInactive={!user?.active}
                        disabled={!onEdit}
                      />
                    ) : (
                      <StyledStatusBox isInactive={!user?.active}>
                        {!user?.active ? 'Inactive' : 'Active'}
                      </StyledStatusBox>
                    )}
                  </Stack>
                  {!onEdit && (
                    <Button
                      color='secondary'
                      startIcon={<EditIcon />}
                      variant='outlined'
                      onClick={() => {
                        setOnEdit(true);
                      }}
                    >
                      Edit User
                    </Button>
                  )}
                  <ConfirmDialog
                    openDialog={openDialog}
                    setOpenDialog={setOpenDialog}
                    onConfirm={onConfirmHandler}
                    confirmLabel='OK'
                    cancelLabel='Cancel'
                    showCancel
                    dialogTitle={user?.active ? 'Are you sure you want to inactivate user?' : 'Activate user?'}
                  />
                </Stack>

                <Card variant='outlined' sx={{ p: 3 }}>
                  <Stack spacing={2}>
                    {onEdit && (
                      <Stack spacing={2} direction='row'>
                        <CustomInputForm
                          disabled={!onEdit}
                          defaultValue={user?.firstName}
                          controllerName={'dataForm.firstName'}
                          inputRegister={'dataForm.firstName'}
                          inputId={'firstName'}
                          label={'First Name'}
                          type={'text'}
                          icon={<UserInfoIcon />}
                        />
                        <CustomInputForm
                          disabled={!onEdit}
                          defaultValue={user?.lastName}
                          controllerName={'dataForm.lastName'}
                          inputRegister={'dataForm.lastName'}
                          inputId={'lastName'}
                          label={'Last Name'}
                          type={'text'}
                          icon={<UserInfoIcon />}
                        />
                      </Stack>
                    )}
                    <Stack direction='row' spacing={2} flexWrap='wrap' useFlexGap>
                      <CustomInputForm
                        disabled={!onEdit}
                        defaultValue={user?.phoneNumber}
                        controllerName={'dataForm.phoneNumber'}
                        inputRegister={'dataForm.phoneNumber'}
                        inputId={'phoneNumber'}
                        label={'Phone Number'}
                        type={'text'}
                        icon={<PhoneIcon />}
                        errorMessage={_.get(formError, 'phoneNumber') || ''}
                        isRequired={false}
                      />
                      <CustomInputForm
                        disabled={true}
                        defaultValue={user?.email}
                        controllerName={'dataForm.email'}
                        inputRegister={'dataForm.email'}
                        inputId={'email'}
                        label={'Email'}
                        type={'text'}
                        icon={<EmailIcon />}
                        errorMessage={_.get(formError, 'email') || ''}
                      />
                      <CustomInputForm
                        disabled={true}
                        defaultValue={user?.isSuperAdmin ? 'Super Admin' : getUserRoleType(user)}
                        controllerName={''}
                        label={'User Type'}
                        type={'text'}
                        icon={<ShieldIcon />}
                        isRequired={false}
                      />
                      <CustomInputForm
                        disabled={true}
                        defaultValue={`${user?.createdUserFirstName} ${user?.createdUserLastName} ${
                          user?.createdAt ? getFormattedDate(user.createdAt) : ''
                        }`}
                        controllerName={'dataForm.createdAt'}
                        inputRegister={'dataForm.createdAt'}
                        inputId={'createdAt'}
                        label={'Created By'}
                        type={'text'}
                        icon={<UserInfoIcon />}
                      />
                    </Stack>
                  </Stack>
                </Card>
                {!user?.isSuperAdmin && (
                  <>
                    <StyledTypographySubTitle>Site Assignations </StyledTypographySubTitle>
                    <SitePermissionTable isEditMode={onEdit} sitePermissions={sitePermissionsToEdit} />
                  </>
                )}
              </Stack>
            </StyledCardContent>
          </StyledCard>
          {onEdit && (
            <Stack spacing={2} direction='row' sx={{ display: 'flex', justifyContent: 'flex-end', m: 10 }}>
              <Button
                color='secondary'
                size='large'
                variant='text'
                onClick={handleCancelClick}
                data-testid='cancel-button'
              >
                cancel
              </Button>
              <Button
                variant='contained'
                color='secondary'
                type='submit'
                size='large'
                disabled={isDisabledSaveButton}
                data-testid='save-button'
              >
                save
              </Button>
            </Stack>
          )}
        </form>
      </FormProvider>
    </Box>
  );
};
