import React from 'react';
interface IconProps {
  width?: string;
  height?: string;
  fill?: string;
  hover?: boolean;
}

export const LocationIcon: React.FC<IconProps> = ({
  width = '24px',
  height = '24px',
  fill = '#037691',
  hover = true
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <title>AD0E43DB-2207-44A8-8123-EBD95E57421E</title>
      <g id='💎-BlueWind-Design-System-💎' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='Style-Guide---Global-elements' transform='translate(-227.000000, -2501.000000)'>
          <g id='icon-/-24px-/-site' transform='translate(227.000000, 2501.000000)'>
            <g id='location' transform='translate(3.000000, 2.000000)' fill={hover ? fill : '#ffff'}>
              <path
                d='M8.89461152,0 C3.98589779,0 0,3.98589779 0,8.89461152 C0,10.7057768 0.706009789,12.4780281 1.74556751,14.0701636 C3.19761284,16.2938165 5.29118203,18.1650204 6.71765535,19.2890769 C6.71876717,19.2901887 6.719879,19.2913006 6.72099083,19.2924124 C7.99736758,20.2908325 9.80742102,20.2263466 11.0104172,19.1389803 C11.0115291,19.1389803 11.0115291,19.1378685 11.0126409,19.1378685 C12.4024239,17.8759455 14.5137823,15.7923827 15.9847287,13.6198739 C17.0687595,12.017732 17.789223,10.3511042 17.789223,8.89461152 C17.789223,3.98589779 13.8033252,0 8.89461152,0 L8.89461152,0 Z M8.89461152,2.22365288 C12.5758689,2.22365288 15.5655702,5.21335418 15.5655702,8.89461152 C15.5655702,9.97641864 14.9485065,11.1827503 14.1424323,12.3735164 C12.7782213,14.3903696 10.8102885,16.3171648 9.51945798,17.4901417 C9.11920046,17.8514853 8.51659053,17.87261 8.090761,17.5401739 C6.80771329,16.5284119 4.91316104,14.8551131 3.6078768,12.8549373 C2.80958541,11.6319282 2.22365288,10.2855064 2.22365288,8.89461152 C2.22365288,5.21335418 5.21335418,2.22365288 8.89461152,2.22365288 L8.89461152,2.22365288 Z M8.89461152,4.44730576 C7.05342693,4.44730576 5.5591322,5.94160049 5.5591322,7.78278508 C5.5591322,9.62396966 7.05342693,11.1182644 8.89461152,11.1182644 C10.7357961,11.1182644 12.2300908,9.62396966 12.2300908,7.78278508 C12.2300908,5.94160049 10.7357961,4.44730576 8.89461152,4.44730576 Z M8.89461152,6.67095864 C9.50833971,6.67095864 10.006438,7.16905688 10.006438,7.78278508 C10.006438,8.39651327 9.50833971,8.89461152 8.89461152,8.89461152 C8.28088332,8.89461152 7.78278508,8.39651327 7.78278508,7.78278508 C7.78278508,7.16905688 8.28088332,6.67095864 8.89461152,6.67095864 Z'
                id='Shape'
              ></path>
            </g>
            <rect id='Rectangle-Copy-3' x='0' y='0' width='24' height='24'></rect>
          </g>
        </g>
      </g>
    </svg>
  );
};
