export enum UserRole {
  NONE = 'None',
  BW_ADMIN = 'bw_admin',
  ADMIN = 'admin',
  DOCTOR = 'doctor',
  SITE_MANAGER = 'site_manager'
}

export interface IUser {
  id?: number;
  firstName?: string;
  lastName?: string;
  userName?: string;
  email?: string;
  phoneNumber?: string;
  password?: string;
  blockSince?: Date | null;
  active?: boolean;
  forcePasswordChange?: boolean;
  wrongPasswordCount?: number;
  lastLogin?: any;
  otp?: string;
  otpDate?: Date;
  isSuperAdmin?: boolean;
  createdAt?: Date;
  updatedAt?: Date;
  createdBy?: string;
  lastEdit?: Date;
  periodicBlock?: boolean;
  createdUserFirstName?: string;
  createdUserLastName?: string;
  updatedUserFirstName?: string;
  updatedUserLastName?: string;
}

export interface ISitePermission {
  id?: number;
  name?: string;
  role?: UserRole | undefined;
}

export interface IUserPermission extends IUser {
  sitePermissions: ISitePermission[];
}

export const ALLOWED_ROLE_CP = [UserRole.ADMIN, UserRole.DOCTOR, UserRole.SITE_MANAGER];
export const ALLOWED_ROLE_WEB_APP = [UserRole.BW_ADMIN];

export interface IAuthenticatedRequest {
  user?: IUser;
  site_role?: UserRole;
}

export type UsersAndPermission = {
  id?: number;
  lastName: string;
  firstName: string;
  siteCount: string;
  active: string;
  email?: string;
  phoneNumber?: string;
  createdBy?: string;
  lastActivity?: string;
  lastEdit?: string;
};

export type UsersAndPermissionInputsForm = {
  lastName: string;
  firstName: string;
  sites: string;
  status: string;
  email?: string;
  phoneNumber?: string;
  createdBy?: string;
  lastActivity?: string;
  lastEdit?: string;
};

export const userStatuses = ['Active', 'Inactive'];
export const UsersAndPermissionId: keyof UsersAndPermission = 'id';
export const UsersAndPermissionDefaultSort: keyof UsersAndPermission = 'lastName';

export const UsersAndPermissionTableColumn: { [key: string]: any } = {
  lastName: 'string',
  firstName: 'string',
  role: 'custom',
  sitePermissions: 'custom',
  active: 'custom'
};

export const UsersAndPermissionTableColumnTitles = {
  firstName: 'First Name',
  lastName: 'Last Name',
  role: 'User Type',
  sitePermissions: 'Sites',
  active: 'Status'
};

export type SitePermission = {
  id?: number;
  role: UserRole | undefined;
};

export const userTypes = ['Super Admin', 'BWMR', 'HCP'];

export const userTitles = {
  [UserRole.BW_ADMIN]: 'Super Admin',
  [UserRole.ADMIN]: 'BWMR',
  [UserRole.DOCTOR]: 'HCP'
};

export const userPermissionSiteRows: SitePermission[] = [
  { id: 1, role: UserRole.ADMIN },
  { id: 2, role: UserRole.DOCTOR }
];
