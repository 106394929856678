import { alpha } from '@mui/material/styles';
// ----------------------------------------------------------------------

// SETUP COLORS

function createGradient(color1: string, color2: string, color3?: string) {
  return `linear-gradient(to top, ${color1}, ${color2}, ${color3})`;
}

const root = {
  mainLightGrey: '#fbfbfb',
  profile: '#037691',
  search: '#ebf8fb',
  listItem: '#ebf8fb',
  mainBoxShadow: 'rgba(184, mainLightGrey188, 204, 0.3)',
  basicWhite: '#fff',
  header: '#333333',
  sub: '#666666',
  t_border: '#B8BCCC',
  boxShadowLight: 'rgba(167, 169, 172, 0.3)',
  boxShadowDark: 'rgba(167, 169, 172, 0.15)',
  button: '#591C48',
  buttonHover: '#333333',
  resetText: '#024d5f',
  resetButtonBackground: '#eef6f8',
  selectionT: 'rgba(203, 207, 222, 0.1);',
  sideBarTextHover: '#037691',
  sideBarSelectHover: '#F5981E',
  sideBarHover: '#ebf8fb'
};

declare module '@mui/material/styles/createPalette' {
  interface ICustomPalette {
    root: { [key: string]: string };
  }

  interface Palette extends ICustomPalette {}
  interface PaletteOptions extends ICustomPalette {}
}

const grey = {
  0: '#FFFFFF',
  100: '#F9FAFB',
  200: '#F4F6F8',
  300: '#DFE3E8',
  400: '#C4CDD5',
  500: '#919EAB',
  600: '#637381',
  700: '#454F5B',
  800: '#212B36',
  900: '#161C24',
  500_8: alpha('#919EAB', 0.08),
  500_12: alpha('#919EAB', 0.12),
  500_16: alpha('#919EAB', 0.16),
  500_24: alpha('#919EAB', 0.24),
  500_32: alpha('#919EAB', 0.32),
  500_48: alpha('#919EAB', 0.48),
  500_56: alpha('#919EAB', 0.56),
  500_80: alpha('#919EAB', 0.8)
};

const primary = {
  lighter: '#CDECF3',
  light: '#9BD9E8',
  main: '#0a3f4d',
  dark: '#36B3D1',
  darker: '#04A0C5',
  contrastText: '#fff'
};

const gradient_sidebar = {
  dark: '#0f9cc9',
  main: '#66c4da',
  light: '#35b2d0'
};

const secondary = {
  lighter: '#D6E4FF',
  light: '#84A9FF',
  main: '#591C48',
  dark: '#591C48',
  darker: '#091A7A',
  contrastText: '#fff'
};

const info = {
  lighter: '#D0F2FF',
  light: '#D0F2FF',
  main: '#1890FF',
  dark: '#0C53B7',
  darker: '#04297A',
  contrastText: '#fff'
};

const success = {
  lighter: '#E9FCD4',
  light: '#AAF27F',
  main: '#039165',
  dark: '#229A16',
  darker: '#08660D',
  contrastText: grey[800]
};

const warning = {
  lighter: '#FFF7CD',
  light: '#FFF7CD',
  main: '#FFC107',
  dark: '#B78103',
  darker: '#7A4F01',
  contrastText: grey[800]
};

const error = {
  lighter: '#FFE7D9',
  light: '#fff0f4',
  main: '#FF4842',
  dark: '#B72136',
  darker: '#7A0C2E',
  contrastText: '#fff'
};

const gradients = {
  primary: createGradient(gradient_sidebar.dark, gradient_sidebar.main, gradient_sidebar.light),
  info: createGradient(info.light, info.main),
  success: createGradient(success.light, success.main),
  warning: createGradient(warning.light, warning.main),
  error: createGradient(error.light, error.main)
};

export const action = {
  hover: alpha(grey[500], 0.08),
  selected: alpha(grey[500], 0.16),
  disabled: alpha(grey[500], 0.8),
  disabledBackground: alpha(grey[500], 0.24),
  focus: alpha(grey[500], 0.24),
  hoverOpacity: 0.08,
  disabledOpacity: 0.48
};

const base = {
  root,
  primary,
  secondary,
  info,
  success,
  warning,
  error,
  grey,
  gradients,
  divider: alpha(grey[500], 0.2),
  action
};

// ----------------------------------------------------------------------

export const palette = {
  ...base,
  action: {
    ...base.action,
    active: grey[600]
  }
};
