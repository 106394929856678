import { styled } from '@mui/material/styles';
import { Box, List } from '@mui/material';

const DrawerHeader = styled(Box)(({ theme }) => ({
  color: theme.palette.root.basicWhite,
  padding: '24px'
}));

const CustomList = styled(List)(({ theme }) => ({
  margin: '12px',
  color: theme.palette.primary.main,
  '& .selected': {
    color: theme.palette.root.sideBarSelectHover,
    backgroundColor: theme.palette.root.sideBarHover
  }
}));

export { DrawerHeader, CustomList };
