import React from 'react';
interface IconProps {
  width?: string;
  height?: string;
  hover?: boolean;
  color?: string;
  colorD?: string;
}

export const UserIcon: React.FC<IconProps> = ({
  width = '24px',
  height = '24px',
  hover,
  color = '#ffffff',
  colorD = '#2C3E50'
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <title>01E9C30D-5754-4735-B74A-3BEB207B442D</title>
      <g id='💎-BlueWind-Design-System-💎' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='Style-Guide---Global-elements' transform='translate(-227.000000, -2551.000000)'>
          <g id='Icons/24px/05-Beneficiary' transform='translate(227.000000, 2551.000000)'>
            <polygon id='Path' points='0 0 24 0 24 24 0 24'></polygon>
            <circle
              id='Oval'
              stroke={hover ? '#037691' : '#ffff'}
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
              cx='9'
              cy='7'
              r='4'
            ></circle>
            <path
              d='M3,21 L3,19 C3,16.790861 4.790861,15 7,15 L11,15 C13.209139,15 15,16.790861 15,19 L15,21'
              id='Path'
              stroke={hover ? '#037691' : '#ffff'}
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            ></path>
            <path
              d='M16,3.13 C17.7699148,3.58316979 19.0078433,5.17799115 19.0078433,7.005 C19.0078433,8.83200885 17.7699148,10.4268302 16,10.88'
              id='Path'
              stroke={hover ? '#037691' : '#ffff'}
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            ></path>
            <path
              d='M21,21 L21,19 C20.9895609,17.1845709 19.7578185,15.6038348 18,15.15'
              id='Path'
              stroke={hover ? '#037691' : '#ffff'}
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
};
