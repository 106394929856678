import { DataType } from 'components/Table/types';

export interface IPatient {
  id?: string;
  siteId?: number;
  lastName?: string;
  firstName?: string;
  assignedSite?: string;
  sites?: string;
  country?: string;
  active?: boolean;
  lastActivity?: string;
  phone?: string;
  email?: string;
  identityNumber?: string;
  createdBy?: string;
  updatedAt?: string;
  createdByFirstName?: string;
  createdByLastName?: string;
  updatedByFirstName?: string;
  updatedByLastName?: string;
  guid?: string;
  createdAt?: string;
}

export enum PatientStatus {
  Active = 'Active',
  Inactive = 'Inactive',
  In_Transfer = 'In Transfer'
}

export interface PatientInputsForm {
  assignedSite?: string;
  active?: string;
  lastActivity?: string;
  updatedAt?: string;
  phone?: string;
  email?: string;
  identityNumber?: string;
  createdAt: string;
  createdByFirstName?: string;
  createdByLastName?: string;
}

export const PatientId: keyof IPatient = 'guid';
export const PatientDefaultSort: keyof IPatient = 'lastName';

export const PatientTableColumn: { [key: string]: DataType } = {
  lastName: 'string',
  firstName: 'string',
  assignedSite: 'string',
  country: 'string',
  active: 'custom'
};

export const PatientTableColumnTitles = {
  lastName: 'Last Name',
  firstName: 'First Name',
  assignedSite: 'Assigned Site',
  country: 'Country',
  active: 'Status',
  lastActivity: 'Last Activity'
};
