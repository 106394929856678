import { DataType } from 'components/Table/types';

export interface ICp {
  id?: number;
  idNumber: string;
  assignedSite: string;
  country: string;
  status: string;
  lastActivity: string;
  lastEdit?: string;
  createdAt: string;
  updated_at?: Date;
  site_id: number;
  cp_identification: string;
  active: boolean;
  created_by_first_name?: string;
  created_by_last_name?: string;
  updated_by_first_name?: string;
  updated_by_last_name?: string;
}

export enum ICpActive {
  Active = 'Active',
  Inactive = 'Inactive'
}

export type CpInputsForm = {
  idNumber: string;
  createdAt?: string;
  lastLogin?: string;
  lastEdit?: string;
  assignedSite?: string;
};
export const CpId: keyof ICp = 'id';
export const CpDefaultSort: keyof ICp = 'idNumber';

export const CpTableColumn: { [key: string]: DataType } = {
  idNumber: 'string',
  assignedSite: 'string',
  country: 'string',
  active: 'custom'
};

export const CpTableColumnTitles = {
  idNumber: 'iPad Serial Number',
  assignedSite: 'Assigned Site',
  country: 'Country',
  active: 'Status',
  lastActivity: 'Last Activity'
};

export const cpMember = ['FCS', 'HCP', 'Patient'];
