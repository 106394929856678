/* eslint-disable indent */
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Typography, ListItem, ListItemButton, ListItemIcon, ListItemText, Toolbar, Drawer, Box } from '@mui/material';

import { DashboardPages } from 'constants/ui.constants';
import { useAppSelector } from 'hooks/redux.hooks';
import BrandLogo from 'assets/images/BrandLogo';
import { CustomList, DrawerHeader } from '../../theme/layouts/sidebar';
const DashboardSidebar: React.FC = () => {
  const navigate = useNavigate();
  const { serverVersion } = useAppSelector((state) => state.global);
  const location = useLocation();

  const selectedPageNameFormat = (selectedPageName: string): string => {
    return selectedPageName === 'sites'
      ? 'Sites'
      : selectedPageName === 'users-and-permission'
      ? 'Users and permission'
      : selectedPageName === 'cp'
      ? 'CP'
      : selectedPageName === 'patients'
      ? 'Patients'
      : selectedPageName === 'audit'
      ? 'Audit'
      : '';
  };
  const drawerWidth = 316;
  return (
    <Drawer
      variant='permanent'
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        ['& .MuiDrawer-paper']: { width: drawerWidth, boxSizing: 'border-box' }
      }}
    >
      <Toolbar />
      <DrawerHeader>
        <BrandLogo width={'90px'} height={'50px'} />
      </DrawerHeader>
      {DashboardPages.map((page, index) => (
        <CustomList key={index} disablePadding>
          <ListItem
            disablePadding
            className={location.pathname.match('^' + page.link) ? 'selected' : ''}
            sx={{
              borderRadius: '8px',
              '& .MuiListItemButton-root:hover': {
                borderRadius: '8px'
              }
            }}
          >
            <ListItemButton
              data-testid='tab-button'
              onClick={() => {
                navigate(page.link);
              }}
            >
              <ListItemIcon>{<page.icon hover={true} />}</ListItemIcon>
              <ListItemText
                primaryTypographyProps={{ fontSize: '16px', fontWeight: 600 }}
                primary={selectedPageNameFormat(page.name)}
              />
            </ListItemButton>
          </ListItem>
        </CustomList>
      ))}
      <Box sx={{ marginTop: 'auto' }}>
        <Typography sx={{ paddingInline: '24px', paddingBlock: '12px', fontSize: '14px' }}>
          App Version {process.env.VERSION}
        </Typography>
        <Typography sx={{ paddingInline: '24px', paddingBottom: '24px', fontSize: '14px' }}>
          Server Version {serverVersion}
        </Typography>
      </Box>
    </Drawer>
  );
};
export default DashboardSidebar;
