import { PreloadedState, configureStore, createListenerMiddleware } from '@reduxjs/toolkit';
import { API_ENDPOINTS } from 'constants/db.constants';
import { IUser } from 'constants/user.constants';
import jwtDecode from 'jwt-decode';
import _ from 'lodash';
import rootReducer from './reducers/';
import { setUserDetails, setUserProfileDetails } from './reducers/auth.reducers';
import API from 'services/api.service';
//services

// Create the middleware instance and methods
const listenerMiddleware = createListenerMiddleware();

listenerMiddleware.startListening({
  actionCreator: setUserDetails,
  effect: (action, listenerApi) => {
    const jwtDecoded = jwtDecode(action.payload.token) as IUser;
    if (jwtDecoded && _.has(jwtDecoded, 'id')) {
      const id = _.get(jwtDecoded, 'id');
      API.getInstance()
        .get(`${API_ENDPOINTS.USERS.GET_USER}/${id}`)
        .then((res) => {
          listenerApi.dispatch(setUserProfileDetails(res.data.data));
        })
        .catch((error) => {
          console.log('error loading user', error);
        });
    }
  }
});
export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).prepend(listenerMiddleware.middleware)
});

// Infer the `RootState` and `AppDispatch` types from the store itself

export function setupStore(preloadedState?: PreloadedState<RootState>) {
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({ serializableCheck: false }).prepend(listenerMiddleware.middleware),
    preloadedState
  });
}

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
