import React, { useDeferredValue, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Stack } from '@mui/material';
import { Add } from '@mui/icons-material';
import _ from 'lodash';

import { SiteTableColumn, SiteId, SiteDefaultSort, SiteTableColumnTitles, Site } from 'constants/sites.constants';
import { userStatuses } from 'constants/user.constants';
import { ColumnAlign, IColumnType } from 'components/Table/types';

import SelectBox from 'components/SelectBox';
import BasicTable from 'components/Table';

import { StyledHeaderBox, StyledTypography, StyledTypographySpan } from '../styled-components';
import { pagesEnum } from 'constants/ui.constants';
import { useAppDispatch, useAppSelector } from 'hooks/redux.hooks';
import { getSites } from 'state/reducers/site.reducers';
import CircularIndeterminate from 'components/Loading';
import NoInfo from 'components/NoInfo';
import { getAllUsers } from 'state/reducers/user.reducers';
import { filterByFields } from '../../utils/global.util';
import SearchComponent from 'components/Search/searchComponent';

const SitesPage: React.FC = () => {
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState<string>('');
  const debouncedValue = useDeferredValue(searchValue);
  const { sites, loading, error } = useAppSelector((state) => state.sites);
  const parsedSitesOptions = useMemo(() => _.uniq(_.map(sites, (site) => site.name)), [sites]);
  const parsedCountriesOptions = useMemo(() => _.uniq(_.map(sites, (site) => site.country)), [sites]);
  const [filterValues, setFilterValues] = useState<{ [key: string]: any }>();
  const dispatch = useAppDispatch();

  const sitesColumnAlign: { [key: string]: ColumnAlign } = {
    number: 'center',
    string: 'left'
  };

  const onFilterChange = (filterType: string, filterValue: string) => {
    const updatedFilterValues = {
      ...filterValues,
      [filterType]: { ...filterValues![filterType as keyof typeof filterValues], value: filterValue }
    };
    setFilterValues(updatedFilterValues);
  };

  const sitesColumns = () => {
    const columns: IColumnType[] = [];
    _.keys(SiteTableColumn).forEach((element) => {
      const props: IColumnType = {
        fieldId: SiteId,
        name: element,
        title: SiteTableColumnTitles[element as keyof typeof SiteTableColumnTitles] || element,
        type: SiteTableColumn[element],
        align: sitesColumnAlign[SiteTableColumn[element] as keyof typeof SiteTableColumn],

        customValueHandle: (val, rec) => {
          if (_.isArray(val)) {
            return val.length;
          }
          return rec.active ? 'Active' : 'Inactive';
        }
      };
      columns.push(props);
    });
    return columns;
  };
  const updatedTableData = () => {
    const items = sites || [];
    return items.filter((site: Site) => {
      return isSiteStatusMatches(site.active!) && isCountryMatches(site.country!) && isSiteNameMatches(site.name!);
    });
  };
  const isSiteStatusMatches = (isActive: boolean) => {
    const isActiveStr = isActive === true ? 'Active' : isActive === false ? 'Inactive' : null;
    return (
      filterValues?.status.value === isActiveStr ||
      !filterValues?.status.value ||
      filterValues?.status.value === 'All' ||
      filterValues?.status.value === ''
    );
  };

  const isCountryMatches = (country: string) => {
    return _.includes([country, 'All', ''], filterValues?.country.value);
  };
  const isSiteNameMatches = (name: string) => {
    return _.includes([name, 'All', ''], filterValues?.sites.value);
  };

  const onResetSelections = () => {
    const resetedFilterValues = { ...filterValues };
    _.chain(filterValues)
      .keys()
      .forEach((filterValue) => {
        resetedFilterValues[filterValue as keyof typeof filterValues].value = 'All';
      })
      .value();
    setFilterValues(resetedFilterValues);
    setSearchValue('');
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const filteredSitesData = filterByFields(updatedTableData(), debouncedValue, ['country', 'assignedBWMR', 'name']);

  useEffect(() => {
    if (parsedSitesOptions && parsedCountriesOptions) {
      setFilterValues({
        country: {
          displayName: 'Country',
          options: parsedCountriesOptions,
          value: 'All'
        },
        sites: {
          displayName: 'Sites',
          options: parsedSitesOptions,
          value: 'All'
        },
        status: {
          displayName: 'Status',
          options: userStatuses,
          value: 'All'
        }
      });
    }
  }, [parsedSitesOptions, parsedCountriesOptions]);

  useEffect(() => {
    dispatch(getSites());
    dispatch(getAllUsers());
  }, []);

  if (error) {
    return <NoInfo />;
  }
  if (loading) return <CircularIndeterminate />;
  return (
    <Box sx={{ width: '100%' }}>
      <Stack spacing={2}>
        <StyledHeaderBox>
          <StyledTypography>Sites</StyledTypography>
          <Button
            color='secondary'
            variant='contained'
            startIcon={<Add />}
            onClick={() => {
              navigate(`/${pagesEnum.SITES}/new`);
            }}
          >
            Add New Site
          </Button>
        </StyledHeaderBox>
        <Stack direction='row' justifyContent='start' alignItems='center' spacing={2} flexWrap='nowrap'>
          <SearchComponent handleInputChange={handleInputChange} searchValue={searchValue} />
          {_.map(_.keys(filterValues), (filterValuesKey) => {
            return (
              <SelectBox
                items={filterValues![filterValuesKey as keyof typeof filterValues].options}
                values={filterValues![filterValuesKey as keyof typeof filterValues].value}
                label={filterValues![filterValuesKey as keyof typeof filterValues].displayName}
                inputLabel={filterValues![filterValuesKey as keyof typeof filterValues].displayName}
                type={filterValuesKey}
                onFilterChange={onFilterChange}
                withAllOption
                key={filterValuesKey}
              />
            );
          })}
          <Button variant='text' size='small' onClick={onResetSelections} sx={{ marginBottom: '8px' }}>
            Reset filter
          </Button>
        </Stack>
        <StyledTypographySpan>Showing {`${updatedTableData()?.length}`} results</StyledTypographySpan>
        <BasicTable
          fieldId='id'
          data={(filteredSitesData as { [key: string]: any }[]) || []}
          columns={sitesColumns()}
          defaultSortBy={SiteDefaultSort}
          actionsCell={[
            {
              element: (
                <Button
                  color='secondary'
                  sx={{ marginRight: '46px', textTransform: 'none', fontWeight: 'bold' }}
                  variant='text'
                >
                  Info
                </Button>
              ),
              onClick: (site) => {
                navigate(`/${pagesEnum.SITES}/${site.id}`);
              }
            }
          ]}
        />
      </Stack>
    </Box>
  );
};

export default SitesPage;
