import React, { useDeferredValue, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Button, Menu, MenuItem, Stack } from '@mui/material';
import { Add } from '@mui/icons-material';
import _ from 'lodash';

import {
  ISitePermission,
  UserRole,
  UsersAndPermissionDefaultSort,
  UsersAndPermissionId,
  UsersAndPermissionTableColumn,
  UsersAndPermissionTableColumnTitles,
  userStatuses,
  userTitles
} from 'constants/user.constants';
import { IColumnType } from 'components/Table/types';

import SelectBox from 'components/SelectBox';
import BasicTable from 'components/Table';

import { StyledHeaderBox, StyledTypography, StyledTypographySpan } from '../styled-components';
import { pagesEnum } from 'constants/ui.constants';
import { getAllSites, getAllUsers } from 'state/reducers/user.reducers';
import { useAppDispatch, useAppSelector } from 'hooks/redux.hooks';
import CircularIndeterminate from 'components/Loading';
import { menuOptions } from './helper';
import { Site } from 'constants/sites.constants';
import { filterByFields } from '../../utils/global.util';
import SearchComponent from 'components/Search/searchComponent';

const UsersAndPermissionPage: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { type: userType, siteId } = useParams();
  const { users, sites, loading } = useAppSelector((state) => state.user);

  const parsedUserTypesOptions = useMemo(() => _.values(userTitles), [userTitles]);
  const parsedSitesOptions = useMemo(() => _.uniq(_.map(sites, (site) => site?.name)), [sites]);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [filterValues, setFilterValues] = useState<{ [key: string]: any }>({});
  const [searchValue, setSearchValue] = useState<string>('');
  const debouncedValue = useDeferredValue(searchValue);

  const open = Boolean(anchorEl);

  useEffect(() => {
    setFilterValues({
      ...filterValues,
      userType: {
        displayName: 'User Type',
        value: 'All',
        options: parsedUserTypesOptions
      },
      sites: {
        displayName: 'Sites',
        value: 'All',
        options: parsedSitesOptions
      },
      status: {
        displayName: 'Status',
        value: 'All',
        options: userStatuses
      }
    });
  }, [parsedUserTypesOptions.length, parsedSitesOptions.length, setFilterValues]);

  useEffect(() => {
    dispatch(getAllUsers());
    dispatch(getAllSites());
  }, []);

  useEffect(() => {
    if (filterValues) {
      if (userType) {
        if (filterValues.userType) {
          if (filterValues.userType.value !== userTitles[userType as keyof typeof userTitles])
            onFilterChange('userType', userTitles[userType as keyof typeof userTitles]);
        }
      }
      if (siteId)
        if (filterValues.sites) {
          const site = sites.find((site: Site) => {
            return site.id !== undefined && site.id === Number(siteId);
          });
          if (site?.name && filterValues.sites.value !== site.name) {
            onFilterChange('sites', site.name);
          }
        }
    }
  }, [userType, siteId, filterValues, users]);

  const onFilterChangeParam = (filterType: string, filterValue: string) => {
    if (userType) navigate(`/${pagesEnum.USERS_AND_PERMISSIONS}`, { replace: true });
    onFilterChange(filterType, filterValue);
  };

  const onFilterChange = (filterType: string, filterValue: string) => {
    const updatedFilterValues = {
      ...filterValues,
      [filterType]: { ...filterValues[filterType as keyof typeof filterValues], value: filterValue }
    };
    setFilterValues(updatedFilterValues);
  };

  const updateTable = () => {
    if (!_.keys(filterValues).length) return [];
    return users.filter((user) => {
      return (
        isUserStatusMatches(Boolean(user.active)) &&
        isUserTypesMatches(user.sitePermissions, !!user.isSuperAdmin) &&
        isUserSitesMatches(user.sitePermissions)
      );
    });
  };

  const isUserStatusMatches = (isActive: boolean) => {
    return (
      (filterValues.status.value === 'Active' && isActive) ||
      (filterValues.status.value === 'Inactive' && !isActive) ||
      !filterValues.status.value ||
      filterValues.status.value === 'All'
    );
  };

  const isUserTypesMatches = (sitePermissions: ISitePermission[], isAdmin: boolean) => {
    return (
      !filterValues.userType.value ||
      filterValues.userType.value === 'All' ||
      (filterValues.userType.value === 'Super Admin' && isAdmin) ||
      sitePermissions.some((sitePermission) => {
        const userType = userTitles[sitePermission.role as keyof typeof userTitles];

        return userType === filterValues.userType.value;
      })
    );
  };

  const isUserSitesMatches = (sitePermissions: ISitePermission[]) => {
    return (
      !filterValues.sites.value ||
      filterValues.sites.value === 'All' ||
      sitePermissions.some((sitePermission) => {
        return sitePermission.name === filterValues.sites.value;
      })
    );
  };

  const usersColumns = () => {
    const columns: IColumnType[] = [];
    _.keys(UsersAndPermissionTableColumn).forEach((element) => {
      const props: IColumnType = {
        fieldId: UsersAndPermissionId,
        name: element,
        title:
          UsersAndPermissionTableColumnTitles[element as keyof typeof UsersAndPermissionTableColumnTitles] || element,
        type: UsersAndPermissionTableColumn[element],
        customValueHandle: (val, rec) => {
          if (element === 'role') {
            if (rec?.isSuperAdmin) {
              return userTitles[UserRole.BW_ADMIN];
            }
            if (rec.sitePermissions && Array.isArray(rec.sitePermissions) && rec.sitePermissions.length) {
              return userTitles[rec.sitePermissions[0].role as keyof typeof userTitles];
            } else {
              return 'N/A';
            }
          }
          if (_.isArray(val)) {
            return val.length;
          }
          return rec.active ? 'Active' : 'Inactive';
        }
      };
      columns.push(props);
    });
    return columns;
  };

  const onResetSelections = () => {
    const resetedFilterValues = { ...filterValues };
    _.chain(filterValues)
      .keys()
      .forEach((filterValue) => {
        resetedFilterValues[filterValue as keyof typeof filterValues].value = 'All';
      })
      .value();
    setFilterValues(resetedFilterValues);
    setSearchValue('');
    navigate(`/${pagesEnum.USERS_AND_PERMISSIONS}`, { replace: true });
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onAddNewUserClick = (newUserType: string) => {
    handleClose();
    navigate(`/${pagesEnum.USERS_AND_PERMISSIONS}/new/${newUserType}`);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const filteredUsersAndPermissionsData = filterByFields(updateTable(), debouncedValue, ['firstName', 'lastName']);

  if (loading) return <CircularIndeterminate />;

  return (
    <Box sx={{ width: '100%' }}>
      <Stack spacing={2}>
        <StyledHeaderBox>
          <StyledTypography>Users and Permission</StyledTypography>
          <Button
            color='secondary'
            sx={{ width: '122px' }}
            variant='contained'
            startIcon={<Add />}
            onClick={handleClick}
          >
            Add New
          </Button>
          <Menu
            id='basic-menu'
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button'
            }}
          >
            <MenuItem
              onClick={() => {
                onAddNewUserClick('new-super-admin');
              }}
            >
              {menuOptions.superAdmin}
            </MenuItem>
            <MenuItem
              onClick={() => {
                onAddNewUserClick('new-BWMR');
              }}
            >
              {menuOptions.bwmr}
            </MenuItem>
            <MenuItem
              onClick={() => {
                onAddNewUserClick('new-HCP');
              }}
            >
              {menuOptions.hcp}
            </MenuItem>
          </Menu>
        </StyledHeaderBox>
        <Stack direction='row' justifyContent='start' alignItems='center' spacing={2} flexWrap='nowrap'>
          <SearchComponent handleInputChange={handleInputChange} searchValue={searchValue} />
          {_.map(_.keys(filterValues), (filterValuesKey) => {
            return (
              <SelectBox
                key={filterValuesKey}
                items={filterValues[filterValuesKey as keyof typeof filterValues].options}
                values={filterValues[filterValuesKey as keyof typeof filterValues].value}
                label={filterValues[filterValuesKey as keyof typeof filterValues].displayName}
                inputLabel={filterValues[filterValuesKey as keyof typeof filterValues].displayName}
                type={filterValuesKey}
                onFilterChange={onFilterChangeParam}
                withAllOption
              />
            );
          })}
          <Button variant='text' size='small' onClick={onResetSelections} sx={{ marginBottom: '8px' }}>
            Reset filter
          </Button>
        </Stack>
        <StyledTypographySpan>Showing {`${updateTable()?.length}`} results</StyledTypographySpan>
        <BasicTable
          fieldId='id'
          data={filteredUsersAndPermissionsData}
          columns={usersColumns()}
          defaultSortBy={UsersAndPermissionDefaultSort}
          actionsCell={[
            {
              element: (
                <Button
                  color='secondary'
                  sx={{ marginRight: '46px', textTransform: 'none', fontWeight: 'bold' }}
                  variant='text'
                >
                  Info
                </Button>
              ),
              onClick: (user) => {
                navigate(`/${pagesEnum.USERS_AND_PERMISSIONS}/${user.id}`);
              }
            }
          ]}
        />
      </Stack>
    </Box>
  );
};

export default UsersAndPermissionPage;
