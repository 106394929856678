import { Info } from '@mui/icons-material';
import { StyledTypographyValidation } from 'components/AuthorizationForm/styled';
import React, { useMemo } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { StyledTextField, StyledInputBox } from './styled';

// eslint-disable-next-line functional/no-mixed-type
interface InputProps {
  disabled: boolean;
  defaultValue: any;
  controllerName: string;
  inputRegister?: string;
  inputId?: string;
  label?: string;
  type?: string;
  icon?: React.ReactNode;
  errorMessage?: string;
  onChangeHandle?: any;
  to?: string | boolean;
  value?: number | string;
  isRequired?: boolean;
  validate?: (value: string) => boolean;
}

const CustomInputForm: React.FC<InputProps> = ({
  disabled,
  defaultValue,
  controllerName,
  inputRegister,
  inputId,
  label,
  type,
  icon,
  errorMessage,
  onChangeHandle,
  to,
  value,
  isRequired,
  validate
}) => {
  const {
    register,
    control,
    formState: { errors, isValid }
  } = useFormContext();
  const navigate = useNavigate();
  const isclickable = useMemo(
    () => !!((to || String(to).length) && value && (value > 0 || String(value)?.length)),
    [to, value]
  );

  return (
    <>
      <StyledInputBox
        error={!!errorMessage}
        isclickable={isclickable}
        onClick={() => isclickable && navigate(`/${to}/${encodeURIComponent(value!)}`)}
        disabled={disabled}
      >
        {icon}
        <Controller
          name={controllerName}
          control={control}
          rules={{
            required: isRequired ?? true,
            validate
          }}
          render={() => (
            <StyledTextField
              error={!!errorMessage}
              fullWidth
              id={inputId}
              label={label}
              type={type}
              defaultValue={defaultValue}
              variant='standard'
              {...register(`${inputRegister}`)}
              sx={{
                '& .MuiInputBase-input': {
                  cursor: 'text'
                },
                marginLeft: '14px',
                '& .MuiInputBase-input.MuiInputBase-readOnly': {
                  WebkitTextFillColor: '#333333',
                  cursor: 'default'
                }
              }}
              inputProps={{ 'data-testid': inputId }}
              InputProps={{
                disableUnderline: true,
                readOnly: disabled,
                ...(onChangeHandle !== undefined && { onChange: onChangeHandle })
              }}
            />
          )}
        />
      </StyledInputBox>
      {errorMessage && (
        <StyledTypographyValidation variant='subtitle1' gutterBottom>
          <Info sx={{ color: '#333333', paddingRight: '8px' }} />
          {errorMessage}
        </StyledTypographyValidation>
      )}
    </>
  );
};

export default CustomInputForm;
