import React, { useDeferredValue, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Button, Stack } from '@mui/material';
import _ from 'lodash';

import {
  PatientTableColumn,
  PatientId,
  PatientDefaultSort,
  PatientTableColumnTitles,
  IPatient,
  PatientStatus
} from 'constants/patients.constants';
import { IColumnType } from 'components/Table/types';

import SelectBox from 'components/SelectBox';
import BasicTable from 'components/Table';
import { StyledTypography, StyledTypographySpan } from '../styled-components';
import { pagesEnum } from 'constants/ui.constants';
import { useAppDispatch, useAppSelector } from 'hooks/redux.hooks';
import { getPatients } from 'state/reducers/patients.reducers';
import CircularIndeterminate from 'components/Loading';
import NoInfo from 'components/NoInfo';
import { userStatuses } from 'constants/user.constants';
import SearchComponent from 'components/Search/searchComponent';
import { filterByFields } from '../../utils/global.util';

const PatientsPage: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { siteId } = useParams();
  const { patients, loading, error } = useAppSelector((state) => state.patients);
  const parsedSitesOptions = useMemo(() => _.uniq(_.map(patients, (patient) => patient?.assignedSite)), [patients]);
  const parsedCountriesOptions = useMemo(() => _.uniq(_.map(patients, (patient) => patient?.country)), [patients]);
  const [filterValues, setFilterValues] = useState<{ [key: string]: any }>();
  const [searchValue, setSearchValue] = useState<string>('');
  const debouncedValue = useDeferredValue(searchValue);

  const onFilterChange = (filterType: string, filterValue: string) => {
    const updatedFilterValues = {
      ...filterValues,
      [filterType]: { ...filterValues?.[filterType as keyof typeof filterValues], value: filterValue }
    };
    setFilterValues(updatedFilterValues);
  };

  const onFilterChangeParam = (filterType: string, filterValue: string) => {
    if (siteId) navigate(`/${pagesEnum.PATIENTS}`, { replace: true });
    onFilterChange(filterType, filterValue);
  };

  const updatedTableData = () => {
    const items = patients || [];
    return items.filter((patient: IPatient) => {
      return (
        isUserStatusMatches(patient.active!) &&
        isCountryMatches(patient.country!) &&
        isPatientSitesMatches(patient.assignedSite!)
      );
    });
  };

  const isUserStatusMatches = (isActive: boolean) => {
    const isActiveStr = isActive === true ? PatientStatus.Active : isActive === false ? PatientStatus.Inactive : null;
    return (
      filterValues?.status.value === isActiveStr ||
      !filterValues?.status.value ||
      filterValues?.status.value === 'All' ||
      filterValues?.status.value === ''
    );
  };

  const isCountryMatches = (ptCountry: string) => {
    return _.includes([ptCountry, 'All', ''], filterValues?.country.value);
  };

  const isPatientSitesMatches = (site: string) => {
    return _.includes([site, 'All', ''], filterValues?.sites.value);
  };

  const patientsColumns = () => {
    const columns: IColumnType[] = [];
    _.keys(PatientTableColumn).forEach((element) => {
      const props: IColumnType = {
        fieldId: PatientId,
        name: element,
        title: PatientTableColumnTitles[element as keyof typeof PatientTableColumnTitles] || element,
        type: PatientTableColumn[element],
        customValueHandle: (val, rec) =>
          rec.active
            ? rec.siteToTransferTo
              ? PatientStatus.In_Transfer
              : PatientStatus.Active
            : PatientStatus.Inactive
      };
      columns.push(props);
    });
    return columns;
  };

  const onResetSelections = () => {
    setFilterValues({
      sites: {
        displayName: 'Sites',
        options: parsedSitesOptions,
        value: 'All'
      },
      country: {
        displayName: 'Country',
        options: parsedCountriesOptions,
        value: 'All'
      },
      status: {
        displayName: 'Status',
        options: userStatuses,
        value: 'All'
      }
    });
    navigate(`/${pagesEnum.PATIENTS}`, { replace: true });
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const filteredPatientsData = filterByFields(updatedTableData(), debouncedValue, [
    'firstName',
    'lastName',
    'country',
    'assignedSite'
  ]);

  useEffect(() => {
    dispatch(getPatients());
  }, []);

  useEffect(() => {
    setFilterValues({
      ...filterValues,
      sites: {
        displayName: 'Sites',
        options: parsedSitesOptions,
        value: 'All'
      },
      country: {
        displayName: 'Country',
        options: parsedCountriesOptions,
        value: 'All'
      },
      status: {
        displayName: 'Status',
        options: userStatuses,
        value: 'All'
      }
    });
  }, [parsedSitesOptions, parsedCountriesOptions, setFilterValues]);

  useEffect(() => {
    if (siteId && filterValues) {
      if (filterValues.sites) {
        const site = patients?.find((patient) => patient?.siteId == +siteId);
        if (site) {
          if (filterValues.sites.value !== site.assignedSite) onFilterChange('sites', site.assignedSite!);
        } else navigate(`/${pagesEnum.PATIENTS}`, { replace: true });
      }
    }
  }, [siteId, filterValues, patients]);

  if (error) return <NoInfo />;
  if (loading) return <CircularIndeterminate />;

  return (
    <Box sx={{ width: '100%' }}>
      <Stack spacing={2}>
        <StyledTypography>Patients</StyledTypography>
        <Stack direction='row' justifyContent='start' alignItems='center' spacing={2} flexWrap='nowrap'>
          <SearchComponent handleInputChange={handleInputChange} searchValue={searchValue} />
          {_.map(_.keys(filterValues), (filterValuesKey) => {
            return (
              <SelectBox
                items={filterValues?.[filterValuesKey as keyof typeof filterValues].options}
                values={filterValues?.[filterValuesKey as keyof typeof filterValues].value}
                label={filterValues?.[filterValuesKey as keyof typeof filterValues].displayName}
                inputLabel={filterValues?.[filterValuesKey as keyof typeof filterValues].displayName}
                type={filterValuesKey}
                onFilterChange={onFilterChangeParam}
                withAllOption
                key={filterValuesKey}
              />
            );
          })}

          <Button variant='text' size='small' onClick={onResetSelections} sx={{ marginBottom: '8px' }}>
            Reset filter
          </Button>
        </Stack>
        <StyledTypographySpan>Showing {`${updatedTableData()?.length}`} results</StyledTypographySpan>
        <BasicTable
          fieldId='id'
          data={(filteredPatientsData as { [key: string]: any }[]) || []}
          columns={patientsColumns()}
          defaultSortBy={PatientDefaultSort}
          actionsCell={[
            {
              element: (
                <Button
                  color='secondary'
                  sx={{ marginRight: '46px', textTransform: 'none', fontWeight: 'bold' }}
                  variant='text'
                >
                  Info
                </Button>
              ),
              onClick: (patient) => {
                navigate(`/${pagesEnum.PATIENTS}/${patient.guid}`);
              }
            }
          ]}
        />
      </Stack>
    </Box>
  );
};

export default PatientsPage;
