import { createAsyncThunk, createSlice, current } from '@reduxjs/toolkit';
import { API_ENDPOINTS } from 'constants/db.constants';
import API from '../../services/api.service';
import { convertKeysToCamelCase, getFormattedDate } from '../../utils/global.util';
import { IPatient } from 'constants/patients.constants';
import _ from 'lodash';

interface ICpState {
  patients?: IPatient[];
  patient?: IPatient | null;
  loading?: boolean;
  login?: boolean;
  error?: IStateError;
}

export interface IStateError {
  errorCode: number | null;
  errorMessage: string;
}

const initialState: ICpState = {
  patient: null,
  loading: false,
  error: undefined,
  login: false
};

export const getPatients = createAsyncThunk('patients/getPatients', () =>
  API.getInstance().get(API_ENDPOINTS.PATIENTS.GET_PATIENTS)
);

export const updatePatient = createAsyncThunk('patient/updatePatient', (data: any, { rejectWithValue }) =>
  API.getInstance()
    .put(`${API_ENDPOINTS.PATIENTS.UPDATE_PATIENT}/${data?.id}`, data?.patientData)
    .then((response) => response?.data?.data[0])
    .catch((err) => rejectWithValue(err))
);

export const deletePatient = createAsyncThunk('patient/deletePatient', (data: any, { rejectWithValue }) =>
  API.getInstance()
    .post(`${API_ENDPOINTS.PATIENTS.DELETE_PATIENT}`, data)
    .then((res) => res.data)
    .catch((err) => rejectWithValue(err))
);

export const patientSlice = createSlice({
  name: 'patients',
  initialState,
  reducers: {
    setError: (state, { payload }) => {
      state.error = {
        errorCode: payload.errorCode,
        errorMessage: payload.data?.message
      };
    }
  },
  extraReducers: {
    [getPatients.pending.type]: (state) => {
      state.loading = true;
      state.error = undefined;
    },
    [getPatients.fulfilled.type]: (state, { payload }) => {
      const convertedToCamelCaseData = _.map(payload?.data?.patients, (patient) => {
        return {
          ...convertKeysToCamelCase(patient),
          lastActivity: getFormattedDate(new Date(patient.last_activity))
        };
      });
      state.patients = convertedToCamelCaseData || [];
      state.loading = false;
    },
    [getPatients.rejected.type]: (state, { payload }) => {
      state.loading = false;
      state.patients = [];
      state.error = {
        errorCode: payload?.data?.statusCode,
        errorMessage: payload?.data?.message
      };
    },
    [updatePatient.pending.type]: (state) => {
      state.loading = true;
      state.error = undefined;
    },
    [updatePatient.fulfilled.type]: (state, { payload }) => {
      const patients = [...current(state.patients!)];
      const patientIdx = patients.findIndex((patient) => patient.guid === payload.guid);
      if (patientIdx > -1) {
        patients[patientIdx] = { ...patients[patientIdx], ...payload };
        state.patients = [...patients];
      }
      state.loading = false;
    },
    [updatePatient.rejected.type]: (state, { payload }) => {
      state.loading = false;
      state.error = {
        errorCode: payload?.data?.statusCode,
        errorMessage: payload?.data?.message
      };
    }
  }
});

export const { setError } = patientSlice.actions;

export default patientSlice.reducer;
