import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form';

import { Box, Button, Stack } from '@mui/material';

//styled
import {
  StyledCard,
  StyledCardContent,
  StyledTypographySubTitle,
  StyledTypographyTitle
} from '../../styled-components';
//constants
import { ISitePermission, IUser, UserRole, UsersAndPermissionInputsForm } from 'constants/user.constants';
import { pagesEnum } from 'constants/ui.constants';
//components
import CustomInputForm from '../../../components/InputsForm/CustomInput';
import { SitePermissionTable } from '../SitePermissionTable';
//icons
import { EmailIcon } from 'assets/icons/Email.icon';
import { PhoneIcon } from 'assets/icons/Phone.icon';
import { UserInfoIcon } from 'assets/icons/UserInfo.icon';
import { useAppDispatch, useAppSelector } from 'hooks/redux.hooks';
import { createNewUser, getAllSites } from 'state/reducers/user.reducers';
import _ from 'lodash';
import { convertKeysToSnakeCase, isPhoneNumberValid } from '../../../utils/global.util';
import { errorsMessages, setErrorMessage } from '../helper';
import { emailValidation } from 'components/AuthorizationForm/utils/passwordValidation';

interface IFormInputs {
  dataForm: UsersAndPermissionInputsForm;
  sitePermissions: ISitePermission[];
}

export const NewUser: React.FC = () => {
  const navigate = useNavigate();
  const { userType } = useParams();
  const [formError, setFormError] = useState<Record<string, string>>({});
  const dispatch = useAppDispatch();
  const { sites, users } = useAppSelector((state) => state.user);

  useEffect(() => {
    dispatch(getAllSites());
  }, []);

  const titlePermission = {
    'new-BWMR': {
      title: 'BWMR',
      role: UserRole.ADMIN
    },
    'new-HCP': {
      title: 'HCP',
      role: UserRole.DOCTOR
    }
  };

  const setSelectedSitePermission = (): UserRole | undefined => {
    return _.has(titlePermission, String(userType)) ? _.get(titlePermission, userType || '').role : undefined;
  };

  const methods = useForm<IFormInputs>({
    reValidateMode: 'onChange',
    defaultValues: {
      dataForm: { phoneNumber: '' },
      sitePermissions: [{ id: undefined, role: setSelectedSitePermission() }]
    }
  });
  const formSubmitHandler: SubmitHandler<IFormInputs> = (newUserData: IFormInputs) => {
    newUserData.dataForm.email = newUserData?.dataForm?.email?.toLowerCase().trim();
    if (userType === 'new-super-admin') {
      const parsedNewUserData = {
        ...convertKeysToSnakeCase(newUserData.dataForm),
        is_super_admin: true
      };
      dispatch(createNewUser(parsedNewUserData)).then((res) => {
        if (_.has(res, 'error')) {
          setErrorMessage(res, setFormError, formError);
        } else {
          navigate(-1);
        }
      });
    } else {
      const parsedNewUserData = {
        ...convertKeysToSnakeCase(newUserData.dataForm),
        site_permissions: _.map(newUserData.sitePermissions, (sitePermission) => ({
          site_id: sitePermission.id,
          role: sitePermission.role
        }))
      };
      dispatch(createNewUser(parsedNewUserData)).then((res) => {
        if (_.has(res, 'error')) {
          setErrorMessage(res, setFormError, formError);
        } else {
          navigate(-1);
        }
      });
    }
  };

  const validateField = (event: any) => {
    const { id, value }: { id: keyof IUser; value: string } = event.target;
    if (id === 'phoneNumber') {
      const isValid = isPhoneNumberValid(value);
      if (!isValid) {
        setFormError({
          ...formError,
          [id]: 'Phone number invalid format. Example: +1234567890'
        });
      } else {
        setFormError({
          ..._.omit(formError, id)
        });
      }
    }

    if (id === 'email' && !emailValidation(value)) {
      setFormError({
        ...formError,
        [id]: 'Invalid email'
      });
      return;
    }

    if (value?.length) {
      if (['email'].includes(id) && value) {
        const exist = !!users?.find((user: IUser) => {
          return user[id] === value;
        });
        if (exist) {
          setFormError({
            ...formError,
            [id]: _.get(errorsMessages, id) || ''
          });
          return;
        }
        setFormError({
          ..._.omit(formError, id)
        });
      }
    }
  };

  return (
    <Box sx={{ width: '100%', height: '100%' }}>
      <FormProvider {...methods}>
        <form onChange={validateField} onSubmit={methods.handleSubmit(formSubmitHandler)}>
          <StyledCard sx={{ background: 'transparent' }}>
            <StyledCardContent>
              <Stack spacing={3}>
                <StyledTypographyTitle sx={{ justifyContent: 'flex-start' }}>
                  {userType === 'new-super-admin'
                    ? 'Add New Super Admin'
                    : `Add New ${_.get(titlePermission, userType || '').title} User`}
                </StyledTypographyTitle>
                <StyledTypographySubTitle sx={{ justifyContent: 'flex-start' }}>
                  User Information
                </StyledTypographySubTitle>
                <Stack direction='row' spacing={2} flexWrap='wrap' useFlexGap>
                  <CustomInputForm
                    disabled={false}
                    defaultValue={''}
                    controllerName={'dataForm.firstName'}
                    inputRegister={'dataForm.firstName'}
                    inputId={'firstName'}
                    label={'First Name'}
                    type={'text'}
                    icon={<UserInfoIcon />}
                  />
                  <CustomInputForm
                    disabled={false}
                    defaultValue={''}
                    controllerName={'dataForm.lastName'}
                    inputRegister={'dataForm.lastName'}
                    inputId={'lastName'}
                    label={'Last Name'}
                    type={'text'}
                    icon={<UserInfoIcon />}
                  />
                  <CustomInputForm
                    disabled={false}
                    defaultValue={''}
                    controllerName={'dataForm.phoneNumber'}
                    inputRegister={'dataForm.phoneNumber'}
                    inputId={'phoneNumber'}
                    label={'Phone Number'}
                    type={'text'}
                    icon={<PhoneIcon />}
                    errorMessage={_.get(formError, 'phoneNumber') || ''}
                    isRequired={false}
                  />

                  <CustomInputForm
                    disabled={false}
                    defaultValue={''}
                    controllerName={'dataForm.email'}
                    inputRegister={'dataForm.email'}
                    inputId={'email'}
                    label={'Email'}
                    type={'text'}
                    icon={<EmailIcon />}
                    errorMessage={_.get(formError, 'email') || ''}
                  />
                </Stack>
                {userType !== 'new-super-admin' && (
                  <>
                    <StyledTypographySubTitle>Site Assignations</StyledTypographySubTitle>
                    <SitePermissionTable isEditMode={false} sitePermissions={sites} />
                  </>
                )}
              </Stack>
              <Stack spacing={2} direction='row' sx={{ display: 'flex', justifyContent: 'flex-end', m: 10 }}>
                <Button
                  color='secondary'
                  data-testid='cancel-button'
                  size='large'
                  variant='text'
                  onClick={() => {
                    navigate(`/${pagesEnum.USERS_AND_PERMISSIONS}`);
                  }}
                >
                  cancel
                </Button>
                <Button
                  color='secondary'
                  variant='contained'
                  data-testid='save-button'
                  disabled={!methods.formState.isValid || Object.keys(formError).length !== 0}
                  type='submit'
                  size='large'
                >
                  save
                </Button>
              </Stack>
            </StyledCardContent>
          </StyledCard>
        </form>
      </FormProvider>
    </Box>
  );
};
