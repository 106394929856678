type passwordValidationFields = {
  isCharactersLengthValid: boolean;
  isLowerCaseValid: boolean;
  isUpperCaseValid: boolean;
  isNumbersIncludedValid: boolean;
  isSpecialCharactersValid: boolean;
};

export const passwordValidation = (password: string): passwordValidationFields => {
  const result: passwordValidationFields = {
    isCharactersLengthValid: false,
    isLowerCaseValid: false,
    isUpperCaseValid: false,
    isNumbersIncludedValid: false,
    isSpecialCharactersValid: false
  };
  if (!password) {
    return result;
  }
  if (password.length >= 8 && password.length <= 14) {
    result.isCharactersLengthValid = true;
  }
  if (/[A-Z]/.test(password)) {
    result.isUpperCaseValid = true;
  }
  if (/[a-z]/.test(password)) {
    result.isLowerCaseValid = true;
  }
  if (/(?=.*[0-9])/.test(password)) {
    result.isNumbersIncludedValid = true;
  }
  if (/(?=.*[@#$%^&+!=]).*$/.test(password)) {
    result.isSpecialCharactersValid = true;
  }
  return result;
};

export const emailValidation = (email: string): boolean => {
  return /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email);
};
