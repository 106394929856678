const translateAuditsKeyToMissingKey = {
  siteId: 'site_name',
  patientId: 'patient_name',
  transferToSiteId: 'site_name',
  guid: 'patient_name',
  created_by: 'created_by_user_name',
  updated_by: 'updated_by_user_name'
} as const;

const getTypeByKeyName = (key: string): 'User' | 'Patient' | 'Site' => {
  if (key.includes('site')) return 'Site';
  if (key.includes('patient')) return 'Patient';
  return 'User';
};
export const getModifiedAuditsChanges = (
  changes: string,
  patientIdName: Record<string, string>,
  siteIdName: Record<number, string>,
  userIdName: Record<number, string>
) => {
  const parsedChanges: Record<string, string | number> = JSON.parse(changes);
  //eslint-disable-next-line functional/no-loop-statement
  for (const key in parsedChanges) {
    if (key in translateAuditsKeyToMissingKey) {
      const translatedKey = translateAuditsKeyToMissingKey[key as keyof typeof translateAuditsKeyToMissingKey];
      const originalValue = parsedChanges[key] as string | number;

      parsedChanges[translatedKey] =
        getTypeByKeyName(translatedKey as string) === 'Patient'
          ? patientIdName[originalValue as string]
          : getTypeByKeyName(translatedKey as string) === 'Site'
          ? siteIdName[originalValue as number]
          : userIdName[originalValue as number];
    }

    if (key === 'OTP') {
      parsedChanges[key] = '******';
    }
  }
  return JSON.stringify(parsedChanges);
};
