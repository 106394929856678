import React from 'react';
interface IconProps {
  width?: string;
  height?: string;
  hover?: boolean;
  fill?: string;
}

export const PulseIcon: React.FC<IconProps> = ({ width = '24px', height = '24px', hover, fill = '#037691' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <title>4DFD52EB-3050-46A0-8B4E-75660693BCC7</title>
      <g id='💎-BlueWind-Design-System-💎' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='Style-Guide---Global-elements' transform='translate(-227.000000, -2601.000000)'>
          <g id='Icons/-24pt/-Implantation/-Pulse-width' transform='translate(227.000000, 2601.000000)'>
            <polygon id='Path' points='0 0 24 0 24 24 0 24'></polygon>
            <path
              d='M21,12 L19,12 C18.106,12 17.338,11.143 17.239,10 C16.943,6.55 16.49,4 14.49,4 C12.49,4 11.99,7.582 11.99,12 C11.99,16.418 11.49,20 9.49,20 C7.49,20 7.038,17.453 6.741,14 C6.641,12.853 5.874,12 4.978,12 L2.978,12'
              id='Path'
              stroke={fill}
              strokeWidth='1.5'
              strokeLinecap='round'
              strokeLinejoin='round'
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
};
