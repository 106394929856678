import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form';
import { Box, Button, Stack } from '@mui/material';
import { CheckCircle, Circle, Info } from '@mui/icons-material';

import { StyledTypography, StyledTypographyValidation, StyledTypographySubTitle } from './styled';
import { passwordValidation } from './utils/passwordValidation';
import CustomInputPassword from '../InputsForm/CustomInputPassword';
import { useAppDispatch } from 'hooks/redux.hooks';
import { resetPassword } from 'state/reducers/auth.reducers';

interface IFormInputs {
  newPassword: string;
  confirmPassword: string;
}

const ResetPassword: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const methods = useForm<IFormInputs>();

  const [watchPassword, watchConfirmPassword] = methods.watch(['newPassword', 'confirmPassword']);

  const [message, setMessage] = useState<string | null>(null);

  const formSubmitHandler: SubmitHandler<IFormInputs> = ({ newPassword }: IFormInputs) => {
    dispatch(resetPassword({ password: newPassword }))
      .unwrap()
      .then(() => navigate('/sites'))
      .catch((e) => {
        setMessage(e.data.message);
      });
  };

  useEffect(() => {
    if (message) setMessage('');
  }, [methods.watch('newPassword'), methods.watch('confirmPassword')]);

  const checkPassword = useMemo(() => {
    return passwordValidation(watchPassword);
  }, [watchPassword]);

  const isFormValid = useCallback(() => {
    const totalValidationSuccess = Object.values(checkPassword).reduce((totalErrors, currentError) => {
      return currentError ? totalErrors + 1 : totalErrors;
    }, 0);

    return (
      (methods.formState.dirtyFields.newPassword &&
        totalValidationSuccess >= 4 &&
        checkPassword.isCharactersLengthValid) ||
      !methods.formState.dirtyFields.newPassword
    );
  }, [checkPassword]);

  const isConfirmPasswordVerify = useCallback(() => {
    return (
      (methods.formState.dirtyFields.confirmPassword && watchPassword === watchConfirmPassword) ||
      !methods.formState.dirtyFields.confirmPassword
    );
  }, [watchPassword, watchConfirmPassword]);

  return (
    <FormProvider {...methods}>
      <form noValidate onSubmit={methods.handleSubmit(formSubmitHandler)}>
        <Stack spacing={4}>
          <Stack spacing={0}>
            <StyledTypography variant='h4' gutterBottom>
              Reset Password
            </StyledTypography>
            <StyledTypographySubTitle>Please enter your new password</StyledTypographySubTitle>
          </Stack>
          <Stack spacing={3}>
            <CustomInputPassword
              controllerName={'newPassword'}
              inputRegister={'newPassword'}
              inputId={'newPassword'}
              label={'New Password'}
            />
            <Stack spacing={0}>
              <CustomInputPassword
                controllerName={'confirmPassword'}
                inputRegister={'confirmPassword'}
                inputId={'confirmPassword'}
                label={'Confirm New Password'}
              />

              {!isFormValid() && (
                <Box>
                  <StyledTypographyValidation variant='subtitle1' gutterBottom>
                    <Info sx={{ color: '#333333', padding: '8px 8px 0 0', alignSelf: 'flex-start' }} /> Your password
                    must be 8-14 characters and include at least 3 of the following:
                  </StyledTypographyValidation>
                  <Box sx={{ paddingLeft: '14px' }}>
                    <StyledTypographyValidation variant='subtitle1' gutterBottom>
                      {checkPassword.isLowerCaseValid ? (
                        <CheckCircle fontSize='small' sx={{ color: '#56CB8F', paddingRight: '8px' }} />
                      ) : (
                        <Circle fontSize='small' sx={{ color: '#DBDBDB', paddingRight: '8px' }} />
                      )}
                      Lower case letters
                    </StyledTypographyValidation>
                    <StyledTypographyValidation variant='subtitle1' gutterBottom>
                      {checkPassword.isUpperCaseValid ? (
                        <CheckCircle fontSize='small' sx={{ color: '#56CB8F', paddingRight: '8px' }} />
                      ) : (
                        <Circle fontSize='small' sx={{ color: '#DBDBDB', paddingRight: '8px' }} />
                      )}
                      Upper case letters
                    </StyledTypographyValidation>
                    <StyledTypographyValidation variant='subtitle1' gutterBottom>
                      {checkPassword.isNumbersIncludedValid ? (
                        <CheckCircle fontSize='small' sx={{ color: '#56CB8F', paddingRight: '8px' }} />
                      ) : (
                        <Circle fontSize='small' sx={{ color: '#DBDBDB', paddingRight: '8px' }} />
                      )}
                      Numbers
                    </StyledTypographyValidation>
                    <StyledTypographyValidation variant='subtitle1' gutterBottom>
                      {checkPassword.isSpecialCharactersValid ? (
                        <CheckCircle fontSize='small' sx={{ color: '#56CB8F', paddingRight: '8px' }} />
                      ) : (
                        <Circle fontSize='small' sx={{ color: '#DBDBDB', paddingRight: '8px' }} />
                      )}
                      Special characters (e.g., #$%&)
                    </StyledTypographyValidation>
                  </Box>
                </Box>
              )}
              {!isConfirmPasswordVerify() && isFormValid() && (
                <Box>
                  <StyledTypographyValidation variant='subtitle1' gutterBottom>
                    <Info sx={{ color: '#333333', paddingRight: '8px' }} /> 2 passwords are different, Try again.
                  </StyledTypographyValidation>
                </Box>
              )}
              {message && (
                <Box>
                  <StyledTypographyValidation variant='subtitle1' gutterBottom>
                    <Info sx={{ color: '#333333', paddingRight: '8px' }} /> {message}
                  </StyledTypographyValidation>
                </Box>
              )}
            </Stack>
            <Button
              color='secondary'
              data-testid='submit-button'
              fullWidth
              type='submit'
              variant='contained'
              disabled={!isFormValid() || !isConfirmPasswordVerify() || !methods.formState.isValid}
              sx={{ height: '60px' }}
            >
              Submit
            </Button>
          </Stack>
        </Stack>
      </form>
    </FormProvider>
  );
};

export default ResetPassword;
