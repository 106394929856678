import React, { useState } from 'react';
import { Box, Button, Dialog, DialogContent, TextField } from '@mui/material';
import { StyledStack } from '../../../styled-components';
import { StyledTypographyValidation } from 'components/AuthorizationForm/styled';
import { Info } from '@mui/icons-material';
import { StyledDialogTitle } from 'components/ConfirmDialog/styled';

interface IDeletePatientModalProps {
  isOpen: boolean;
  error?: string;
  onClose: () => void;
  onConfirmDeletePatient: (password: string) => void;
  resetError: () => void;
}

const DeletePatientModal = ({
  isOpen,
  onClose,
  onConfirmDeletePatient,
  error,
  resetError
}: IDeletePatientModalProps) => {
  const [password, setPassword] = useState('');

  return (
    <Dialog onClose={onClose} open={isOpen}>
      <StyledDialogTitle style={{ margin: 'auto' }}>Delete Patient</StyledDialogTitle>
      <DialogContent>
        <StyledStack
          spacing={1}
          direction='row'
          sx={{ display: 'flex', justifyContent: 'flex-start', fontWeight: 'bolder' }}
        >
          Are you sure you want to delete this patient?
          <br />
          The patient`s data will be irreversibly erased.
        </StyledStack>
        <StyledStack spacing={3} direction='row' sx={{ display: 'flex', justifyContent: 'flex-start' }}>
          To complete the deletion of the patient, enter your password:
        </StyledStack>
        <StyledStack direction='row' sx={{ display: 'flex', justifyContent: 'flex-start' }}>
          <TextField
            id='filled-required'
            value={password}
            label='Password'
            defaultValue='Please enter password'
            type={'password'}
            onChange={(e: any) => {
              setPassword(e.target.value);
              resetError();
            }}
            inputProps={{ 'data-testid': 'password' }}
          />
        </StyledStack>
        {!!error && (
          <StyledTypographyValidation style={{ alignItems: 'flex-start', margin: 0 }} variant='subtitle1' gutterBottom>
            <Info sx={{ color: '#333333', padding: '8px 8px 0 0', alignSelf: 'flex-start' }} />
            {error}
          </StyledTypographyValidation>
        )}
      </DialogContent>
      <Box sx={{ display: 'flex', m: 5, justifyContent: 'space-between' }}>
        <Button color='secondary' variant='text' onClick={onClose}>
          Cancel
        </Button>
        <Button
          color='secondary'
          variant='contained'
          data-testid='yes-button'
          disabled={!password.length}
          onClick={() => onConfirmDeletePatient(password)}
        >
          Yes
        </Button>
      </Box>
    </Dialog>
  );
};

export default DeletePatientModal;
