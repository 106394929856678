import React, { useDeferredValue, useEffect, useRef, useState } from 'react';
import { AppBar, Box, Toolbar, Typography } from '@mui/material';

import { useAppDispatch, useAppSelector } from 'hooks/redux.hooks';
import NavBarModal from 'components/navBarModal';
import { logout, setOnUserLogOut } from 'state/reducers/auth.reducers';
import { useNavigate } from 'react-router-dom';
import { searchSourceToPages as searchSourceToPagesEnum, timing } from 'constants/ui.constants';
import { getSearchResults, resetSearchResults } from 'state/reducers/global.reducers';
import { format } from 'date-fns';
import SearchComponent from 'components/Search/searchComponent';

const events = ['mousemove', 'mousedown', 'click', 'scroll', 'keypress'];

export default function DashboardNavbar() {
  const [showSearchResults, setShowSearchResults] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const { userDetails } = useAppSelector((state) => state.auth);
  const { isSearchDisabled } = useAppSelector((state) => state.search);
  const searchResults = useAppSelector((state) => state.global.searchResults);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const logoutIntervalRef = useRef<ReturnType<typeof setInterval> | null>(null);
  const debouncedValue = useDeferredValue(searchValue);

  useEffect(() => {
    if (debouncedValue.length === 0) {
      setShowSearchResults(false);
      dispatch(resetSearchResults());
      return;
    }
    onSearch(debouncedValue);
  }, [debouncedValue]);

  const listenerHandler = () => {
    resetTimer();
    localStorage.setItem('logoutTTL', (+new Date()).toString());
    handleLogoutTimer();
  };

  useEffect(() => {
    if (userDetails) {
      Object.values(events).forEach((item) => {
        window.addEventListener(item, listenerHandler);
      });
    }
  }, []);

  const onLogout = async () => {
    await dispatch(setOnUserLogOut());
    dispatch(logout());
    navigate('/login');
  };

  const onSearch = (value: string) => {
    dispatch(getSearchResults({ searchText: value }));
    setShowSearchResults(true);
  };

  const resetTimer = () => {
    if (logoutIntervalRef?.current) {
      clearTimeout(logoutIntervalRef.current);
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };

  const handleLogoutTimer = () => {
    logoutIntervalRef.current = setInterval(async () => {
      const deltaTimeMilliseconds = new Date().getTime() - Number(window.localStorage.getItem('logoutTTL'));
      const doLogout = deltaTimeMilliseconds > 1000 * 60 * 30;

      if (doLogout) {
        resetTimer();
        Object.values(events).forEach((item) => {
          window.removeEventListener(item, listenerHandler);
        });
        await onLogout();
      }
    }, 1000);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position='fixed' sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <Toolbar>
          <NavBarModal
            firstName={userDetails?.firstName ?? ''}
            lastName={userDetails?.lastName ?? ''}
            email={userDetails?.email ?? ''}
            logout={() => onLogout()}
          />
          <Typography
            variant='h6'
            noWrap
            component='div'
            sx={{ ml: 1, flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
          >
            Hi, {userDetails?.firstName} {userDetails?.lastName}
            {userDetails?.lastLogin && (
              <Typography sx={{ fontSize: '11px', lineHeight: '17px', fontWeight: '600' }}>
                Last login: {format(new Date(userDetails?.lastLogin), timing.DATE_MONTH)}
              </Typography>
            )}
          </Typography>
          <SearchComponent
            disabled={isSearchDisabled}
            handleInputChange={handleInputChange}
            searchValue={searchValue}
            showSearchResults={showSearchResults}
            searchResults={searchResults}
            isNavBarLayout={true}
            onClick={(item) => {
              navigate(`/${searchSourceToPagesEnum(item.source)}/${item.id}`);
              setShowSearchResults(false);
              setSearchValue('');
            }}
          />
        </Toolbar>
      </AppBar>
    </Box>
  );
}
