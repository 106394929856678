import _ from 'lodash';

export const errorsMessagesKeys = {
  email: 'email',
  phoneNumber: 'phoneNumber',
  nonExistPhoneNumber: 'phoneNumber'
};

export const errorsMessages = {
  email: 'Same email exist, Try other email.',
  phoneNumber: 'Same phone number exist, Try other number.',
  nonExistPhoneNumber: 'The number does not exist'
};

export const menuOptions = {
  superAdmin: 'Super Admin',
  bwmr: 'BWMR',
  hcp: 'HCP'
};

export function setErrorMessage(res: any, setFormError: any, formError: any) {
  // eslint-disable-next-line functional/no-let
  let key;
  // eslint-disable-next-line functional/no-try-statement
  try {
    const message = res.payload.data.message;
    key = message.match('phone_number') ? 'phoneNumber' : key;
    key = message.match('email') ? 'email' : key;
    key = message.match('PhoneNumbers') ? 'nonExistPhoneNumber' : key;
    if (key) {
      setFormError({
        ...formError,
        [_.get(errorsMessagesKeys, key)]: _.get(errorsMessages, key)
      });
    }
    // eslint-disable-next-line no-empty
  } catch (e) {}
}
