import React, { useEffect } from 'react';

import { BrowserRouter } from 'react-router-dom';
import AppRoutes from './routes';
import ThemeProvider from '../src/theme';
import './theme/fonts.css';
import { useAppDispatch } from 'hooks/redux.hooks';
import { setUserDetails } from 'state/reducers/auth.reducers';
import { getVersion } from 'state/reducers/global.reducers';

const App: React.FC = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getVersion());
    const updateUserData = () => {
      const token = localStorage.getItem('token');
      token && dispatch(setUserDetails({ token }));
    };
    updateUserData();
  }, []);

  return (
    <BrowserRouter>
      <ThemeProvider>
        <AppRoutes />
      </ThemeProvider>
    </BrowserRouter>
  );
};
export default App;
