import React from 'react';

export interface IObjectViewProps {
  jsonString: string;
}
export const ObjectView: React.FC<IObjectViewProps> = ({ jsonString }: IObjectViewProps) => {
  // Step 1: Parse the JSON string into a JavaScript object
  const jsonObject = JSON.parse(jsonString);

  // Step 2: Create JSX elements based on the key-value pairs in the object
  const jsxElements = Object.entries(jsonObject).map(([key, value]) => (
    <p key={key}>
      {key}: {JSON.stringify(value)}
    </p>
  ));

  // Step 3: Render the JSX elements
  return <div>{jsxElements}</div>;
};
