import React from 'react';
// @mui
import { Box, Typography } from '@mui/material';

export default function PageNotFound() {
  return (
    <Box sx={{ height: '100vh', placeItems: 'center', display: 'grid' }}>
      <Typography>OOPS! Something went wrong.</Typography>
      <Typography>the page you are looking for is not found</Typography>
    </Box>
  );
}
