import React from 'react';
interface IconProps {
  width?: string;
  height?: string;
  fill?: string;
  hover?: boolean;
}

export const PermissionIcon: React.FC<IconProps> = ({
  width = '24px',
  height = '24px',
  fill = '#037691',
  hover = true
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <title>5FE260FF-600A-4723-A52D-A8A6183340F8</title>
      <g id='💎-BlueWind-Design-System-💎' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='Style-Guide---Global-elements' transform='translate(-393.000000, -2551.000000)'>
          <g id='icon-/-24px-/-permission' transform='translate(393.000000, 2551.000000)'>
            <path
              d='M22,7.00025 C22,5.92325 21.311,4.96825 20.29,4.62825 C18.346,3.98025 14.193,2.59625 12.79,2.12825 C12.277,1.95725 11.722,1.95725 11.209,2.12825 C9.806,2.59625 5.653,3.98025 3.709,4.62825 C2.688,4.96825 2,5.92325 2,7.00025 L2,11.50525 C2,14.59225 3.673,17.43725 6.372,18.93625 C8.814,20.29325 11.514,21.79325 11.514,21.79325 C11.816,21.96025 12.183,21.96025 12.485,21.79325 C12.485,21.79325 15.185,20.29325 17.628,18.93625 C20.326,17.43725 22,14.59225 22,11.50525 L22,7.00025 Z M20,7.00025 C20,6.78425 19.862,6.59325 19.658,6.52525 C17.713,5.87725 13.561,4.49325 12.158,4.02525 C12.055,3.99125 11.944,3.99125 11.841,4.02525 C10.438,4.49325 6.286,5.87725 4.341,6.52525 C4.137,6.59325 4,6.78425 4,7.00025 L4,11.50525 C4,13.86625 5.279,16.04125 7.343,17.18725 L12,19.77425 L16.656,17.18725 C18.72,16.04125 20,13.86625 20,11.50525 L20,7.00025 Z M11.343,13.16125 L16.292,8.21125 C16.683,7.82125 17.316,7.82125 17.707,8.21125 C18.097,8.60225 18.097,9.23525 17.707,9.62625 L12.05,15.28225 C11.659,15.67325 11.026,15.67325 10.636,15.28225 L7.807,12.45425 C7.417,12.06425 7.417,11.43025 7.807,11.04025 C8.197,10.64925 8.831,10.64925 9.221,11.04025 L11.343,13.16125 Z'
              id='Shape'
              fill={fill}
            ></path>
            <rect id='Rectangle-Copy-7' x='0' y='0' width='24' height='24'></rect>
          </g>
        </g>
      </g>
    </svg>
  );
};
