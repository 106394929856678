import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form';
import { Button, Stack, Typography } from '@mui/material';

import { StyledTypography, StyledTypographySubTitle } from './styled';
import CustomDefaultInput from '../InputsForm/CustomDefaultInput';
import { emailValidation } from './utils/passwordValidation';
import { useAppDispatch } from 'hooks/redux.hooks';
import { resetPasswordByEmail, setUserNameAndPassword } from 'state/reducers/auth.reducers';

interface IFormInputs {
  email: string;
}

const ForgotPassword: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const methods = useForm<IFormInputs>({ mode: 'onTouched' });
  const [forgotEmail] = methods.watch(['email']);
  const isEmailValid = emailValidation(forgotEmail);

  const formSubmitHandler: SubmitHandler<IFormInputs> = (data: IFormInputs) => {
    const dataEmail = {
      email: data.email,
      url: 'url......'
    };
    dispatch(resetPasswordByEmail(dataEmail));
    dispatch(setUserNameAndPassword(data));
    navigate('#forgot-password-verify');
  };

  return (
    <FormProvider {...methods}>
      <form noValidate onSubmit={methods.handleSubmit(formSubmitHandler)}>
        <Stack spacing={6}>
          <Stack>
            <StyledTypography variant='h4' gutterBottom>
              Forgot Password?
            </StyledTypography>
            <StyledTypographySubTitle>
              We`ll send you an email with instructions to reset your password.
            </StyledTypographySubTitle>
          </Stack>
          <Stack spacing={3} sx={{ alignItems: 'center' }}>
            <CustomDefaultInput
              controllerName={'email'}
              inputRegister={'email'}
              inputId={'email'}
              label={'E-mail'}
              type={'email'}
            />
            <Button
              color='secondary'
              data-testid='continue-button'
              fullWidth
              disabled={!methods.formState.isValid || !methods.formState.isDirty || !isEmailValid}
              type='submit'
              variant='contained'
              sx={{ height: '60px' }}
            >
              Continue
            </Button>
            <Typography
              component={Link}
              to='/Login'
              sx={{ color: '#333333', textDecoration: 'none', textAlign: 'center', fontSize: 17, width: 'fit-content' }}
            >
              Cancel
            </Typography>
          </Stack>
        </Stack>
      </form>
    </FormProvider>
  );
};

export default ForgotPassword;
