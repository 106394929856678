import { InputAdornment } from '@mui/material';
import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';

import { StyledTextField } from './styled';

interface InputProps {
  defaultValue?: any;
  controllerName: string;
  inputRegister: string;
  inputId: string;
  label: string;
  type: string;
  icon?: React.ReactNode;
  maxWidth?: string;
  error?: boolean;
}

const CustomDefaultInput: React.FC<InputProps> = ({
  controllerName,
  inputRegister,
  inputId,
  label,
  type,
  icon,
  maxWidth,
  error = false
}) => {
  const {
    register,
    control,
    watch,
    formState: { errors, isValid, isDirty }
  } = useFormContext();
  return (
    <Controller
      name={controllerName}
      control={control}
      rules={{
        required: true
      }}
      render={() => (
        <StyledTextField
          error={error}
          fullWidth
          id={inputId}
          inputProps={{
            'data-testid': inputId
          }}
          label={label}
          type={type}
          variant='outlined'
          sx={{ maxWidth: `${maxWidth}` }}
          InputProps={icon ? { startAdornment: <InputAdornment position='start'>{icon}</InputAdornment> } : {}}
          {...register(`${inputRegister}`)}
        />
      )}
    />
  );
};

export default CustomDefaultInput;
