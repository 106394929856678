import React, { Dispatch, SetStateAction, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form';
import { Box, Button, Stack } from '@mui/material';

import {
  StyledCard,
  StyledCardContent,
  StyledTypographySubTitle,
  StyledTypographyTitle
} from '../../styled-components';
import { Site, SiteInputsForm } from 'constants/sites.constants';
import { pagesEnum } from 'constants/ui.constants';
import { LocationIcon } from 'assets/icons/Location.icon';
import { SwiftIcon } from 'assets/icons/Swift.icon';
import { CityIcon } from 'assets/icons/City.icon';
import { PermissionIcon } from 'assets/icons/Permission.icon';
import CustomInputForm from 'components/InputsForm/CustomInput';
import { useAppDispatch, useAppSelector } from 'hooks/redux.hooks';
import { createSite, setError } from 'state/reducers/site.reducers';
import { convertKeysToSnakeCase } from '../../../utils/global.util';
import _ from 'lodash';

interface IFormInputs {
  dataForm: SiteInputsForm;
  openProfileModal: boolean;
  setOpenProfileModal: Dispatch<SetStateAction<boolean>>;
}

const NewSite: React.FC = () => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate(`/${pagesEnum.SITES}/new`);
  }, []);
  const methods = useForm<IFormInputs>({
    defaultValues: {
      dataForm: {}
    }
  });
  const dispatch = useAppDispatch();

  const { error, sites, loading } = useAppSelector((state) => state.sites);

  const formSubmitHandler: SubmitHandler<IFormInputs> = (newSiteData: IFormInputs) => {
    const parsedNewSiteData = {
      ...convertKeysToSnakeCase(newSiteData.dataForm)
    };
    dispatch(createSite(parsedNewSiteData));
    navigate('/sites');
  };

  const validateField = (event: any) => {
    const { id, value }: { id: keyof Site; value: string } = event.target;

    if (value?.length) {
      if (['name', 'siteId'].includes(id) && value) {
        const exist = !!sites?.find((site: Site) => {
          return site[id] === value;
        });
        if (exist) {
          dispatch(
            setError({
              key: id,
              error: true
            })
          );
          return;
        }
        dispatch(
          setError({
            key: id,
            error: false
          })
        );
      }
    }
  };

  const isSubmitDisabled = useMemo(() => {
    return !_.isEmpty(error) || loading;
  }, [error, loading]);

  return (
    <Box sx={{ width: '100%' }}>
      <FormProvider {...methods}>
        <form noValidate onChange={validateField} onSubmit={methods.handleSubmit(formSubmitHandler)}>
          <StyledCard sx={{ background: 'transparent' }}>
            <StyledCardContent>
              <Stack spacing={3}>
                <StyledTypographyTitle sx={{ justifyContent: 'flex-start' }}>Add New Site</StyledTypographyTitle>
                <StyledTypographySubTitle sx={{ justifyContent: 'flex-start' }}>
                  Site Information
                </StyledTypographySubTitle>
                <Stack direction='row' spacing={2} flexWrap='wrap' useFlexGap>
                  <CustomInputForm
                    disabled={false}
                    defaultValue={''}
                    controllerName={'dataForm.name'}
                    inputRegister={'dataForm.name'}
                    inputId={'name'}
                    label={'Site Name'}
                    type={'text'}
                    icon={<LocationIcon />}
                    errorMessage={_.has(error, 'name') ? 'Same site name, Try other name.' : ''}
                  />

                  <CustomInputForm
                    disabled={false}
                    defaultValue={''}
                    controllerName={'dataForm.country'}
                    inputRegister={'dataForm.country'}
                    inputId={'country'}
                    label={'Country'}
                    type={'text'}
                    icon={<SwiftIcon />}
                  />

                  <CustomInputForm
                    disabled={false}
                    defaultValue={''}
                    controllerName={'dataForm.city'}
                    inputRegister={'dataForm.city'}
                    inputId={'city'}
                    label={'City'}
                    type={'text'}
                    icon={<CityIcon />}
                  />
                  <CustomInputForm
                    disabled={false}
                    defaultValue={''}
                    controllerName={'dataForm.siteId'}
                    inputRegister={'dataForm.siteId'}
                    inputId={'siteId'}
                    label={'Site ID'}
                    type={'text'}
                    icon={<PermissionIcon />}
                    errorMessage={_.has(error, 'siteId') ? 'Same site ID, Try other ID.' : ''}
                  />
                </Stack>
              </Stack>
              <Stack spacing={2} direction='row' sx={{ display: 'flex', right: 20, justifyContent: 'flex-end', m: 10 }}>
                <Button
                  color='secondary'
                  data-testid='cancel-button'
                  size='large'
                  variant='text'
                  onClick={() => {
                    navigate(`/${pagesEnum.SITES}`);
                  }}
                >
                  cancel
                </Button>
                <Button
                  color='secondary'
                  variant='contained'
                  data-testid='save-button'
                  disabled={!methods.formState.isValid || isSubmitDisabled}
                  type='submit'
                >
                  save
                </Button>
              </Stack>
            </StyledCardContent>
          </StyledCard>
        </form>
      </FormProvider>
    </Box>
  );
};

export default NewSite;
