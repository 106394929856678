/* eslint-disable functional/no-try-statement */
/* eslint-disable functional/no-this-expression */
/* eslint-disable functional/no-class */
import axios, { AxiosError } from 'axios';
import { SERVER_BASE_URL } from 'constants/db.constants';

axios.interceptors.request.use(
  (config: any) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

export default class ApiService {
  static instance: ApiService;

  constructor() {
    if (ApiService.instance) {
      return ApiService.instance;
    }
    ApiService.instance = this;
  }

  static getInstance = () => ApiService.instance || new ApiService();

  static getServerUrl = () => SERVER_BASE_URL /*SERVER_URLS[process.env.NODE_ENV as Environment]*/;

  get = async (partialUrl: string, params: any = {}): Promise<any> => {
    const completeUrl = ApiService.getServerUrl() + partialUrl;
    try {
      return await axios.get(completeUrl, { params: params });
    } catch (error: any) {
      return this.handleResponseError(error);
    }
  };

  put = async (partialUrl: string, data: Record<string, any> = {}): Promise<any> => {
    const completeUrl = ApiService.getServerUrl() + partialUrl;
    try {
      return await axios.put(completeUrl, data);
    } catch (error: any) {
      return this.handleResponseError(error);
    }
  };

  post = async (partialUrl: string, data: Record<string, any> = {}): Promise<any> => {
    const completeUrl = ApiService.getServerUrl() + partialUrl;
    try {
      return await axios.post(completeUrl, data);
    } catch (error: any) {
      return this.handleResponseError(error);
    }
  };

  handleResponseError = (error: AxiosError<any>) => {
    const apiError = {
      message: error.message,
      data: error?.response?.data,
      statusCode: error?.response?.status
    };
    return Promise.reject(apiError);
  };
}
