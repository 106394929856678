import React from 'react';
interface IconProps {
  width?: string;
  height?: string;
  fill?: string;
  hover?: boolean;
}

export const EditIcon: React.FC<IconProps> = ({ width = '24px', height = '24px', fill = '#591C48', hover = true }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 17 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <title>B18D010C-1723-442B-A4E7-5382C43E9A74</title>
      <g id='💎-BlueWind-Design-System-💎' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <path
          d='M14.837 1.16263C13.2838 -0.387543 10.7687 -0.387543 9.21545 1.16263L7.71858 2.6595L0.982962 9.39543C0.86052 9.51771 0.781138 9.67648 0.75678 9.8478L0.00806687 15.0866C-0.0247803 15.3163 0.0436548 15.5489 0.195634 15.7241C0.347613 15.8994 0.568173 16 0.800144 16C0.838051 16.0001 0.875914 15.9974 0.913435 15.992L6.15267 15.2437C6.32395 15.2189 6.48263 15.1394 6.60503 15.0171L14.8378 6.78502C15.5835 6.03949 16.0024 5.02826 16.0024 3.97382C16.0024 2.91939 15.5835 1.90816 14.8378 1.16263H14.837Z'
          fill={fill}
        />
        <path d='M7.55659 3.1116L12.8907 8.44574' stroke='white' strokeLinecap='square' />
      </g>
    </svg>
  );
};
