export const GET_CONFIG = {
  baseURL: '',
  withCredentials: false,
  headers: {
    Accept: '*/*',
    'Content-Type': 'application/json'
  }
};

// Server Base Url
export const SERVER_BASE_URL = process.env.REACT_APP_API_URL;

// Local Base  Url
// export const SERVER_BASE_URL = 'http://localhost:5000';

// API endpoints
export const API_ENDPOINTS = {
  LOGIN: '/api/login/token',
  USER_LOG_OUT: '/api/webapp/logout',
  AUTHENTICATE: '/api/openRouter/twoFactorAuthentication',
  RESET_PASSWORD: '/api/webapp/user/resetPassword',
  RESET_PASSWORD_BY_EMAIL: '/api/openRouter/resetPassword',
  SAVE_CURRENT_USER_PASSWORD: '/api/openRouter/saveCurrentPassword',
  SEARCH: '/api/webapp/global/search',
  VERSION: '/api/openRouter/version',
  USERS: {
    GET_ALL_USERS: '/api/webapp/user/all',
    GET_USER: '/api/webapp/user',
    CREATE_USER: '/api/webapp/user',
    UPDATE_USER: '/api/webapp/user/update',
    UPDATE_USER_PROFILE: '/api/webapp/user/update'
  },
  SITES: {
    CREATE_SITES: '/api/webapp/sites',
    GET_ALL_SITES: '/api/webapp/sites/all',
    MAKE_SITE_INACTIVE: '/api/webapp/sites/inactive',
    UPDATE_SITE: '/api/webapp/sites'
  },
  CP: {
    GET_ALL_CP: '/api/webapp/cps/getAll',
    CREATE_CP: '/api/webapp/cps',
    UPDATE_CP: '/api/webapp/cps',
    GET_CP_BY_ID: '/api/webapp/cps/get/'
  },
  PATIENTS: {
    GET_PATIENTS: '/api/webapp/patients',
    UPDATE_PATIENT: '/api/webapp/patients/update',
    DELETE_PATIENT: '/api/webapp/patients/deletePatient'
  },
  AUDIT: {
    GET: '/api/webapp/audit'
  }
};
