import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isSearchDisabled: false
};

export const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    disableSearch: (state: { isSearchDisabled: boolean }, { payload }) => {
      state.isSearchDisabled = payload;
    }
  }
});

export const { disableSearch } = searchSlice.actions;

export default searchSlice.reducer;
