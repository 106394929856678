import React, { useEffect, useState } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import { ROUTES } from './routes.constants';

import { pagesEnum } from 'constants/ui.constants';

import DashboardLayout from '../components/Layout';

import Login from '../pages/Login';

import SitesPage from '../pages/Sites';
import SiteInformation from '../pages/Sites/SiteInformation';
import NewSite from '../pages/Sites/NewSite';

import UsersAndPermissionPage from '../pages/UsersAndPermission';
import { UserInformation } from '../pages/UsersAndPermission/UserInformation';
import { NewUser } from '../pages/UsersAndPermission/NewUser';

import CpPage from '../pages/Cp';
import CpInformation from '../pages/Cp/CpInformation';
import NewCp from '../pages/Cp/NewCp';

import PatientsPage from '../pages/Patients';
import { PatientInformation } from '../pages/Patients/PatientInformation';

import PageNotFound from 'components/404App';
import { useAppSelector } from 'hooks/redux.hooks';
import AuditPage from '../pages/Audit';

const env = process.env.NODE_ENV;

const AppRoutes: React.FC = () => {
  const { userDetails } = useAppSelector((state) => state.auth);
  const [routs, setRouts] = useState<any[]>([]);

  const filterRouts = [
    {
      path: '/',
      element: <Navigate to={`${ROUTES.LOGIN}`} replace />
    },
    {
      path: '/login',
      element: <Login />
    },
    {
      path: '/*',
      element: <PageNotFound />
    }
  ];

  const pagesRouts = [
    {
      path: '/',
      element: <Navigate to={`${ROUTES.SITES}`} replace />
    },
    {
      path: '/' + pagesEnum.SITES,
      element: <DashboardLayout />,
      children: [
        { path: '', element: <SitesPage /> },
        { path: ':id/*', element: <SiteInformation /> },
        { path: 'new/*', element: <NewSite /> }
      ]
    },
    {
      path: '/' + pagesEnum.CP,
      element: <DashboardLayout />,
      children: [
        { path: '', element: <CpPage /> },
        { path: 'filter/sites/:siteId', element: <CpPage /> },
        { path: ':id/*', element: <CpInformation /> },
        { path: 'new/*', element: <NewCp /> }
      ]
    },
    {
      path: '/' + pagesEnum.USERS_AND_PERMISSIONS,
      element: <DashboardLayout />,
      children: [
        { path: '', element: <UsersAndPermissionPage /> },
        { path: 'filter/usertype/:type/site/:siteId', element: <UsersAndPermissionPage /> },
        { path: ':id/*', element: <UserInformation /> },
        { path: 'new/:userType/*', element: <NewUser /> }
      ]
    },
    {
      path: '/' + pagesEnum.PATIENTS,
      element: <DashboardLayout />,
      children: [
        { path: '', element: <PatientsPage /> },
        { path: 'filter/sites/:siteId', element: <PatientsPage /> },
        { path: ':id/*', element: <PatientInformation /> }
      ]
    },
    {
      path: '/login',
      element: <Login />
    },
    {
      path: '/*',
      element: <PageNotFound />
    },
    {
      path: '/' + pagesEnum.AUDIT,
      element: <DashboardLayout />,
      children: [{ path: '', element: <AuditPage /> }]
    }
  ];

  useEffect(() => {
    if (env === 'test') {
      setRouts(pagesRouts);
    } else {
      setRouts(userDetails?.id ? pagesRouts : filterRouts);
    }
  }, [userDetails]);

  return useRoutes(routs);
};
export default AppRoutes;
