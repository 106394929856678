import { DataType } from 'components/Table/types';

export interface IAudit {
  id?: string;
  lastName?: string;
  firstName?: string;
  siteName?: string;
  changes?: string;
  cpIdentification: string;
  api: string;
  createdAt: Date;
}

export const AuditId: keyof IAudit = 'id';

export const AuditDefaultSort: keyof IAudit = 'createdAt';

export const AuditTableColumn: { [key: string]: DataType } = {
  createdAt: 'string',
  siteName: 'string',
  lastName: 'string',
  firstName: 'string',
  api: 'string',
  changes: 'json',
  httpStatus: 'string',
  cpIdentification: 'string'
};

export const AuditTableColumnTitles = {
  siteName: 'Site Name',
  lastName: 'Last Name',
  firstName: 'First Name',
  api: 'API',
  changes: 'changes',
  cpIdentification: 'Cp Identification',
  httpStatus: 'Result',
  createdAt: 'Date'
};

export const ApiNeedToModifiedChanges = [
  'cpapp/patients/transferRequest',
  'update patient',
  'cpapp/patients/checkAvailabilityIdentity',
  'openRouter/twoFactorAuthentication'
];
