import { createAsyncThunk, createSlice, current } from '@reduxjs/toolkit';
import { API_ENDPOINTS } from 'constants/db.constants';
import API from '../../services/api.service';
import { Site } from 'constants/sites.constants';
import { convertKeysToCamelCase, getFormattedDate } from '../../utils/global.util';
import _ from 'lodash';

interface ISiteState {
  sites?: Array<Site>;
  loading?: boolean;
  error?: IStateError;
}

export interface IStateError {
  [key: string]: any;
}

const initialState: ISiteState = {
  sites: undefined,
  loading: false,
  error: undefined
};

export const getSites = createAsyncThunk('site/getSites', (_, { rejectWithValue }) =>
  API.getInstance()
    .get(API_ENDPOINTS.SITES.GET_ALL_SITES)
    .then((response) => response.data)
    .catch((err) => {
      return rejectWithValue(err);
    })
);

export const createSite = createAsyncThunk('site/createSite', (data: any, { rejectWithValue }) => {
  return API.getInstance()
    .post(API_ENDPOINTS.SITES.CREATE_SITES, data)
    .then((response) => response.data)
    .catch((err) => {
      return rejectWithValue(err);
    });
});

export const updateSite = createAsyncThunk('site/updateSite', (data: any, { rejectWithValue }) =>
  API.getInstance()
    .put(`${API_ENDPOINTS.SITES.UPDATE_SITE}/${data?.id}`, data?.siteData)
    .then((response) => response.data)
    .catch((err) => rejectWithValue(err))
);

export const inactiveSite = createAsyncThunk('site/inactive', (data: any, { rejectWithValue }) =>
  API.getInstance()
    .post(`${API_ENDPOINTS.SITES.MAKE_SITE_INACTIVE}`, data)
    .then((response) => response.data)
    .catch((err) => rejectWithValue(err))
);

export const siteSlice = createSlice({
  name: 'site',
  initialState,
  reducers: {
    setError: (state, { payload }) => {
      if (payload.error) {
        state.error = { ...state.error, [payload.key]: true };
      } else {
        state.error = _.omit(state.error, payload.key);
      }
    }
  },
  extraReducers: {
    [getSites.pending.type]: (state) => {
      state.loading = true;
      state.error = undefined;
    },
    [getSites.fulfilled.type]: (state, { payload }) => {
      const convertedToCamelCaseData = _.map(payload?.data, (site) => {
        return {
          ...convertKeysToCamelCase(site),
          lastActivity: getFormattedDate(new Date(site.last_activity)),
          assignedBWMR: site.admin_count || '0'
        };
      });
      state.sites = convertedToCamelCaseData;
      state.loading = false;
    },
    [getSites.rejected.type]: (state, { payload }) => {
      state.loading = false;
      state.error = {
        errorCode: payload?.data?.statusCode,
        errorMessage: payload?.data?.message
      };
    },
    [createSite.pending.type]: (state) => {
      state.loading = true;
      state.error = undefined;
    },
    [createSite.fulfilled.type]: (state) => {
      state.loading = true;
    },
    [createSite.rejected.type]: (state, { payload }) => {
      state.loading = false;
      state.error = {
        errorCode: payload?.data?.statusCode,
        errorMessage: payload?.data?.message
      };
    },
    [updateSite.pending.type]: (state) => {
      state.loading = true;
      state.error = undefined;
    },
    [updateSite.fulfilled.type]: (state, { payload }) => {
      const sites: Site[] = [...current(state).sites!];
      const siteIndex = sites?.findIndex((site) => site.id === payload.data.id);
      if (siteIndex >= 0) {
        sites[siteIndex] = { ...sites[siteIndex], ...payload.data };
        state.sites = sites;
      }
      state.loading = false;
    },
    [updateSite.rejected.type]: (state, { payload }) => {
      state.loading = false;
      state.error = {
        errorCode: payload?.data?.statusCode,
        errorMessage: payload?.data?.message
      };
    },
    [inactiveSite.pending.type]: (state) => {
      state.loading = true;
      state.error = undefined;
    },
    [inactiveSite.fulfilled.type]: (state, { payload }) => {
      const sites: Site[] = [...current(state).sites!];
      const siteIndex = sites?.findIndex((site) => site.id === payload.data.id);
      if (siteIndex >= 0) {
        sites[siteIndex] = { ...sites[siteIndex], ...payload.data };
        state.sites = sites;
      }
      state.loading = false;
    },
    [inactiveSite.rejected.type]: (state, { payload }) => {
      state.loading = false;
      state.error = {
        errorCode: payload?.data?.statusCode,
        errorMessage: payload?.data?.message,
        appCode: payload?.data?.app_code
      };
    }
  }
});

export const { setError } = siteSlice.actions;
export default siteSlice.reducer;
