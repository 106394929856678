import React from 'react';
interface IconProps {
  width?: string;
  height?: string;
  hover?: boolean;
  fill?: string;
}

export const CpIcon: React.FC<IconProps> = ({ width = '24px', height = '24px', hover = true, fill = '#037691' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <title>49943DE1-1AFE-4CC7-AB7B-AFA258E57C8B</title>
      <g id='💎-BlueWind-Design-System-💎' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='Style-Guide---Global-elements' transform='translate(-282.000000, -2650.000000)'>
          <g id='icon-/-24px-/-cp' transform='translate(282.000000, 2650.000000)'>
            <g id='tablet' transform='translate(3.000000, 2.000000)' fill={hover ? fill : '#ffff'}>
              <path
                d='M18,4 C18,1.791 16.209,0 14,0 L4,0 C1.791,0 0,1.791 0,4 L0,16 C0,18.209 1.791,20 4,20 L14,20 C16.209,20 18,18.209 18,16 L18,4 Z M16,4 L16,16 C16,17.105 15.105,18 14,18 L4,18 C2.895,18 2,17.105 2,16 L2,4 C2,2.895 2.895,2 4,2 L14,2 C15.105,2 16,2.895 16,4 Z M7,16 L11,16 L11,14 L7,14 L7,16 Z'
                id='Shape'
              ></path>
            </g>
            <rect id='Rectangle-Copy-2' x='0' y='0' width='24' height='24'></rect>
          </g>
        </g>
      </g>
    </svg>
  );
};
