import styled from '@emotion/styled';
import { Card, Typography, Button } from '@mui/material';

const StyledCard = styled(Card)`
  & {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px 68px 32px 68px;
    border-radius: 12px;
    border: solid 1px #dbdbdb;
    box-shadow: 0 0 4px -1px rgba(167, 169, 172, 0.2), 0 8px 12px -2px rgba(167, 169, 172, 0.16);
  }
`;

const StyledTypography = styled(Typography)`
  & {
    text-align: center;
    color: #333333;
  }
`;

const StyledTypographySubTitle = styled(Typography)`
  & {
    text-align: center;
    font-size: 22px;
    font-weight: 300;
    color: #333333;
  }
`;

const StyledTypographyValidation = styled(Typography)`
  & {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: auto;
    color: #333333;
  }
`;

const StyledTypographyLock = styled(Typography)`
  & {
    display: flex;
    justify-content: center;
    padding-bottom: 31px;
    color: #333333;
  }
`;

const StyledLockButton = styled(Button)`
  & {
    height: 60px;
    color: black;
    border-radius: 12px;
    font-size: 22px;
    background-color: #dbdbdb;
  }
`;

const StyledSpan = styled.span`
  & {
    text-align: center;
    font-size: 17px;
    color: #333333;
  }
`;

export {
  StyledCard,
  StyledTypography,
  StyledTypographySubTitle,
  StyledSpan,
  StyledTypographyValidation,
  StyledTypographyLock,
  StyledLockButton
};
