import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ICp, ICpActive } from 'constants/cp.constants';
import { API_ENDPOINTS } from 'constants/db.constants';
import API from '../../services/api.service';
import _ from 'lodash';
import { getFormattedDate } from '../../utils/global.util';
import { timing } from 'constants/ui.constants';

export interface IStateError {
  errorCode: number | null;
  errorMessage: string;
}
interface ICpState {
  cps?: null | ICp[];
  cp?: null | ICp;
  loading: boolean;
  error?: IStateError;
  login: boolean;
}

const initialState: ICpState = {
  cps: undefined,
  cp: null,
  loading: false,
  error: undefined,
  login: false
};

export const getAllCps = createAsyncThunk(API_ENDPOINTS.CP.GET_ALL_CP, (data: any, { rejectWithValue }) => {
  return API.getInstance()
    .get(API_ENDPOINTS.CP.GET_ALL_CP, data)
    .then((response) => response.data)
    .catch((err) => rejectWithValue(err));
});

export const getCpById = createAsyncThunk(API_ENDPOINTS.CP.GET_CP_BY_ID, (id?: string) =>
  API.getInstance()
    .get(`${API_ENDPOINTS.CP.GET_CP_BY_ID}${id}`)
    .then((response) => response.data)
);

export const createNewCp = createAsyncThunk(API_ENDPOINTS.CP.CREATE_CP, (data: any, { rejectWithValue }) =>
  API.getInstance()
    .post(API_ENDPOINTS.CP.CREATE_CP, data)
    .then((response) => response.data)
    .catch((err) => rejectWithValue(err))
);

export const updateCp = createAsyncThunk(API_ENDPOINTS.CP.UPDATE_CP, (data: any, { rejectWithValue }) => {
  {
    const { cpId, updateCpData } = data;
    return API.getInstance()
      .put(`${API_ENDPOINTS.CP.UPDATE_CP}/${cpId}`, updateCpData)
      .then((response) => response.data)
      .catch((err) => rejectWithValue(err));
  }
});

export const cpSlice = createSlice({
  name: 'cp',
  initialState,
  reducers: {
    setError: (state, { payload }) => {
      if (!_.keys(payload).length) {
        state.error = undefined;
        return;
      }
      state.error = {
        errorCode: payload.errorCode,
        errorMessage: payload.data?.message
      };
    }
  },
  extraReducers: {
    [getAllCps.pending.type]: (state) => {
      state.loading = true;
      state.error = undefined;
    },
    [getAllCps.fulfilled.type]: (state, { payload }) => {
      // TODO use func
      state.cps = payload.data?.map((cp: any) => {
        return {
          id: cp.id,
          idNumber: cp.cp_identification,
          assignedSite: cp.name,
          country: cp.country,
          active: cp.active,
          lastActivity: getFormattedDate(cp?.last_used, timing.DATE) || getFormattedDate(cp?.created_at, timing.DATE),
          lastEdit: getFormattedDate(cp?.updated_at),
          createdAt: getFormattedDate(cp?.created_at),
          created_by_first_name: cp.created_by_first_name,
          created_by_last_name: cp.created_by_last_name,
          updated_by_first_name: cp.updated_by_first_name,
          updated_by_last_name: cp.updated_by_last_name
        };
      });
      state.loading = false;
      state.login = true;
    },
    [getAllCps.rejected.type]: (state, { payload }) => {
      state.loading = false;
      state.error = {
        errorCode: payload?.data?.statusCode,
        errorMessage: payload?.data?.message
      };
    },
    [createNewCp.pending.type]: (state) => {
      state.loading = true;
      state.error = undefined;
    },
    [createNewCp.fulfilled.type]: (state) => {
      state.loading = false;
    },
    [createNewCp.rejected.type]: (state, { payload }) => {
      state.loading = false;
      state.error = {
        errorCode: payload?.data?.statusCode,
        errorMessage: payload?.data?.message
      };
    },
    [updateCp.pending.type]: (state) => {
      state.loading = true;
      state.error = undefined;
    },
    [updateCp.fulfilled.type]: (state) => {
      state.loading = false;
    },
    [updateCp.rejected.type]: (state, { payload }) => {
      state.loading = false;
      state.error = {
        errorCode: payload?.data?.statusCode,
        errorMessage: payload?.data?.message
      };
    }
  }
});

export const { setError } = cpSlice.actions;

export default cpSlice.reducer;
