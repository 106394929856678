import React from 'react';
interface IconProps {
  width?: string;
  height?: string;
  hover?: boolean;
  color?: string;
  colorD?: string;
}

export const UserInfoIcon: React.FC<IconProps> = ({
  width = '24px',
  height = '24px',
  hover,
  color = '#ffffff',
  colorD = '#2C3E50'
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <title>2A342575-6DD1-489A-8BBB-A7B07A26B263</title>
      <g id='💎-BlueWind-Design-System-💎' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='Style-Guide---Global-elements' transform='translate(-448.000000, -2551.000000)'>
          <g id='icon-/-24px-/-user' transform='translate(448.000000, 2551.000000)'>
            <g id='user-(3)' transform='translate(3.000000, 2.000000)' fill='#037691'>
              <path
                d='M12.5122222,10.1088889 C12.2277778,9.97333333 11.8988889,9.96333333 11.6077778,10.0844444 C11.3166667,10.2044444 11.09,10.4433333 10.9844444,10.74 C10.6788889,11.6033333 9.85555556,12.2222222 8.88888889,12.2222222 C7.92222222,12.2222222 7.09888889,11.6033333 6.79333333,10.74 C6.68777778,10.4433333 6.46111111,10.2044444 6.17,10.0844444 C5.87888889,9.96333333 5.55,9.97333333 5.26555556,10.1088889 C5.26555556,10.1088889 3.26444444,11.0688889 1.89222222,11.7266667 C0.735555556,12.2811111 0,13.45 0,14.7322222 L0,16.6666667 C0,18.5077778 1.49222222,20 3.33333333,20 L14.4444444,20 C16.2855556,20 17.7777778,18.5077778 17.7777778,16.6666667 C17.7777778,16.0133333 17.7777778,15.3177778 17.7777778,14.7322222 C17.7777778,13.45 17.0422222,12.2811111 15.8855556,11.7266667 L12.5122222,10.1088889 Z M12.5122222,12.5733333 L14.9244444,13.73 C15.31,13.9155556 15.5555556,14.3044444 15.5555556,14.7322222 L15.5555556,16.6666667 C15.5555556,17.28 15.0577778,17.7777778 14.4444444,17.7777778 L3.33333333,17.7777778 C2.72,17.7777778 2.22222222,17.28 2.22222222,16.6666667 L2.22222222,14.7322222 C2.22222222,14.3044444 2.46777778,13.9155556 2.85333333,13.73 C2.85333333,13.73 5.26555556,12.5733333 5.26555556,12.5733333 C6.07111111,13.7055556 7.39444444,14.4444444 8.88888889,14.4444444 C10.3833333,14.4444444 11.7066667,13.7055556 12.5122222,12.5733333 Z M8.88888889,0 C6.43555556,0 4.44444444,1.99111111 4.44444444,4.44444444 C4.44444444,6.89777778 6.43555556,8.88888889 8.88888889,8.88888889 C11.3422222,8.88888889 13.3333333,6.89777778 13.3333333,4.44444444 C13.3333333,1.99111111 11.3422222,0 8.88888889,0 Z M8.88888889,2.22222222 C10.1155556,2.22222222 11.1111111,3.21777778 11.1111111,4.44444444 C11.1111111,5.67111111 10.1155556,6.66666667 8.88888889,6.66666667 C7.66222222,6.66666667 6.66666667,5.67111111 6.66666667,4.44444444 C6.66666667,3.21777778 7.66222222,2.22222222 8.88888889,2.22222222 Z'
                id='Shape'
              ></path>
            </g>
            <rect id='Rectangle' x='0' y='0' width='24' height='24'></rect>
          </g>
        </g>
      </g>
    </svg>
  );
};
