import React, { useState } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { IconButton, InputAdornment } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

import { StyledTextField } from './styled';

interface InputProps {
  defaultValue?: any;
  controllerName: string;
  inputRegister: string;
  inputId: string;
  label: string;
}

const CustomInputPassword: React.FC<InputProps> = ({ controllerName, inputRegister, inputId, label }) => {
  const {
    register,
    control,
    watch,
    formState: { errors, isValid, isDirty, dirtyFields }
  } = useFormContext();

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <Controller
      name={controllerName}
      control={control}
      rules={{
        required: true,
        minLength: 8,
        maxLength: 14
      }}
      render={() => (
        <StyledTextField
          fullWidth
          id={inputId}
          label={label}
          {...register(`${inputRegister}`)}
          type={showPassword ? 'text' : 'password'}
          inputProps={{
            'data-testid': inputId
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton
                  aria-label='toggle password visibility'
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge='end'
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            )
          }}
        />
      )}
    />
  );
};

export default CustomInputPassword;
