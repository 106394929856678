import React from 'react';

import AuthorizationForm from 'components/AuthorizationForm/index';
import MainLogo from 'components/MainLogo';
import { StyledBox, StyledContainer } from '../styled-components';
import { LinearProgress } from '@mui/material';
import { useAppSelector } from 'hooks/redux.hooks';

const LoginPage: React.FC = () => {
  const { loading } = useAppSelector((state) => state.auth);
  return (
    <>
      {loading && <LinearProgress sx={{ width: '100%', position: 'absolute', top: 0 }} />}
      <StyledContainer>
        <StyledBox sx={{ width: '100%' }}>
          <MainLogo />
          <AuthorizationForm />
        </StyledBox>
      </StyledContainer>
    </>
  );
};

export default LoginPage;
