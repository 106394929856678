import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import API from '../../services/api.service';
import { API_ENDPOINTS } from 'constants/db.constants';
import { ISearchResult } from 'constants/global.constans';
import { convertKeysToCamelCase, customSort } from '../../utils/global.util';

export interface IStateError {
  errorCode: number | null;
  errorMessage: string;
  appCode?: number | null;
}

interface IGlobalState {
  loading: boolean;
  error?: IStateError;
  searchResults: ISearchResult[];
  serverVersion: string | null;
}

export const getSearchResults = createAsyncThunk('global/getSearchResults', (data: any, { rejectWithValue }) =>
  API.getInstance()
    .get(API_ENDPOINTS.SEARCH, data)
    .then((response) => ({ data: response.data, searchText: data.searchText }))
    .catch((err) => rejectWithValue(err))
);

export const getVersion = createAsyncThunk('global/getVersion', (_, { rejectWithValue }) =>
  API.getInstance()
    .get(API_ENDPOINTS.VERSION)
    .then((response) => response.data)
    .catch((err) => rejectWithValue(err))
);

const initialState: IGlobalState = {
  error: undefined,
  loading: false,
  searchResults: [],
  serverVersion: null
};

export const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    setError: (state, { payload }) => {
      state.error = {
        errorCode: payload.errorCode,
        errorMessage: payload.data?.message
      };
    },
    resetSearchResults: (state) => {
      state.searchResults = [];
    }
  },
  extraReducers: {
    [getSearchResults.pending.type]: (state) => {
      state.loading = true;
      state.error = undefined;
    },
    [getSearchResults.fulfilled.type]: (state, { payload }) => {
      const convertedToCamelCaseData = convertKeysToCamelCase(payload.data);
      state.searchResults = convertedToCamelCaseData.searchResult?.sort((a: any, b: any) =>
        customSort(a.name, b.name, payload.searchText)
      );
      state.loading = false;
    },
    [getSearchResults.rejected.type]: (state, { payload }) => {
      state.error = {
        errorCode: payload?.statusCode,
        errorMessage: payload?.message
      };
      state.loading = false;
    },
    [getVersion.pending.type]: (state) => {
      state.loading = true;
      state.error = undefined;
    },
    [getVersion.fulfilled.type]: (state, { payload }) => {
      const convertedToCamelCaseData = convertKeysToCamelCase(payload);
      state.serverVersion = convertedToCamelCaseData.version;
      state.loading = false;
    },
    [getVersion.rejected.type]: (state, { payload }) => {
      state.error = {
        errorCode: payload?.statusCode,
        errorMessage: payload?.message
      };
      state.loading = false;
    }
  }
});

// Action creators are generated for each case reducer function
export const { setError, resetSearchResults } = globalSlice.actions;

export default globalSlice.reducer;
