import { DataType } from 'components/Table/types';

export type Site = {
  id?: number;
  name?: string;
  country?: string;
  city?: string;
  active?: boolean;
  lastActivity?: string;
  assignedBWMR?: number;
  createdAt?: string;
  updatedAt?: string;
  patientCount?: number;
  siteId?: string;
  siteManagerCount?: number;
  updatedByFirstName?: string;
  updatedByLastName?: string;
  doctorCount?: number;
  createdByLastName?: string;
  createdByFirstName?: string;
  adminCount?: number;
  activeCpCount?: number;
};

export type SiteInputsForm = {
  name?: string;
  country?: string;
  city?: string;
  active?: boolean;
  lastActivity?: string;
  assignedBWMR?: number;
  createdAt?: string;
  updatedAt?: string;
  patientCount?: number;
  siteId?: string;
  siteManagerCount?: number;
  updatedByFirstName?: string;
  updatedByLastName?: string;
  doctorCount?: number;
  createdByLastName?: string;
  createdByFirstName?: string;
  adminCount?: number;
  activeCpCount?: number;
};
export const SiteId: keyof Site = 'id';
export const SiteDefaultSort: keyof Site = 'name';

export const SiteTableColumn: { [key: string]: DataType } = {
  name: 'string',
  country: 'string',
  active: 'custom',
  // lastActivity: 'date',
  assignedBWMR: 'number'
};

export const SiteTableColumnTitles = {
  name: 'Site Name',
  country: 'Country',
  active: 'Status',
  lastActivity: 'Last Activity',
  assignedBWMR: 'Assigned BWMR'
};
