import React, { useState } from 'react';
import _ from 'lodash';
import { TableBody, TableRow, Typography, Box, Table, Stack, TableCell, Tooltip } from '@mui/material';

import {
  formatActiveCell,
  formatValue,
  getComparator,
  handleRequestSort,
  limitLength,
  stableSort
} from './tableHelper';
import { initialTableState, ITableProps, ITableState } from './types';
import EnhancedTableHead from './EnhancedTableHead';

import { CustomTableContainer, CustomTableInfoCell } from './styled';
import CustomModal from '../../components/Modal';
import { orderBy } from 'constants/ui.constants';
import { palette } from '../../theme/palette';

const BasicTable: React.FC<ITableProps> = ({
  data,
  columns,
  rowsClass,
  actionsCell,
  defaultSortBy,
  orderDirection,
  maxLength,
  children
}: ITableProps) => {
  const [tableState, setTableState] = useState<ITableState>({
    ...initialTableState,
    sortBy: defaultSortBy || '',
    orderBy: orderDirection || orderBy.ASC
  });
  //const [showPopup, setShowPopup] = useState<boolean>(false);
  //const [popupText, setPopupText] = useState<string>('');

  // const handlePopup = (text: string) => {
  //   setShowPopup(true);
  //   setPopupText(text);
  // };

  const slicedData = (): typeof data => stableSort(data, getComparator(tableState.orderBy, tableState.sortBy ?? ''));

  return (
    <Stack direction='column' justifyContent='center' alignItems='center' spacing={2}>
      <CustomTableContainer sx={{ maxHeight: 480, minWidth: 650 }}>
        <Table sx={{ whiteSpace: 'nowrap' }} size='medium' stickyHeader aria-label='simple table'>
          <EnhancedTableHead
            headCells={columns}
            tableState={tableState}
            setState={setTableState}
            order={tableState.orderBy!}
            orderBy={tableState.sortBy!}
            onRequestSort={handleRequestSort}
            rowCount={data.length}
            actionsCell={actionsCell}
          />
          <TableBody>
            {slicedData().map((row: Record<string, number>, rowIndex: React.Key | null | undefined) => (
              <TableRow
                key={rowIndex}
                className={rowsClass}
                data-testid='table-row'
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                {columns.map((column, columnIndex) => {
                  const cellValue: any = _.get(row, column.name); // Use of any should be avoided if possible, but without knowing the data structure it's necessary here
                  const formattedValue = formatValue(column.type, cellValue, column, row);
                  const shouldPopup: boolean =
                    formattedValue.value?.length > (maxLength?.[column.name] || 5) ||
                    formattedValue.length > (maxLength?.[formattedValue] || 5);
                  return (
                    <TableCell
                      key={columnIndex}
                      align={column.align}
                      className='hoverBox'
                      {...column.eventsHandle}
                      id={String(_.get(row, column.fieldId))}
                      component='th'
                      scope='row'
                      sx={{ color: palette.root.sub }}
                    >
                      <Tooltip title={shouldPopup ? (typeof formattedValue === 'object' ? formattedValue.object : formattedValue) : ''}>
                        <Typography
                          component='span'
                          variant='body1'
                          sx={{ ...(column.name === 'active' ? formatActiveCell(row) : null) }}
                          // onClick={() => {
                          // 	if (shouldPopup) {
                          // 		handlePopup(typeof formattedValue === 'object' ? formattedValue.object : formattedValue);
                          // 	}
                          // }}
                        >
                          {typeof formattedValue === 'number'
                            ? formattedValue
                            : limitLength(formattedValue, maxLength?.[column.name] ?? 15)}
                        </Typography>
                      </Tooltip>
                    </TableCell>
                  );
                })}
                {actionsCell && (
                  <CustomTableInfoCell>
                    {actionsCell.map((action, actionIndex) => (
                      <Box key={actionIndex} onClick={() => action.onClick(row)}>
                        {action.element}
                      </Box>
                    ))}
                  </CustomTableInfoCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </CustomTableContainer>
      {children}
      {/* <CustomModal
        style={{
          display: 'flex',
          alignItems: 'center',
          '& .MuiCard-root': {
            width: 'fit-content',
            margin: 'auto'
          }
        }}
        open={showPopup}
        setOpenModal={setShowPopup}
        modalContent={<Box>{popupText}</Box>}
      /> */}
    </Stack>
  );
};

export default BasicTable;
