import React from 'react';
import { Box, IconButton } from '@mui/material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { useNavigate } from 'react-router-dom';
import { palette } from '../../theme/palette';

const ArrowBackBtn = ({ title, route }: { title: string; route: string }) => {
  const navigate = useNavigate();
  return (
    <Box
      display='flex'
      alignItems='center'
      sx={{
        width: 'fit-content',
        color: palette.root.header,
        fontSize: '22px',
        fontWeight: 600,
        textTransform: 'none'
      }}
    >
      <IconButton onClick={() => navigate(route)}>
        <KeyboardArrowLeftIcon sx={{ color: '#04A0C5' }} />
      </IconButton>
      <div>{title}</div>
    </Box>
  );
};

export default ArrowBackBtn;
