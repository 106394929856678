import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Stack } from '@mui/material';

import { StyledTypography, StyledTypographySubTitle } from './styled';
import API from 'services/api.service';
import { API_ENDPOINTS } from 'constants/db.constants';
import { useAppSelector } from 'hooks/redux.hooks';

const ChangePasswordPeriod: React.FC = () => {
  const navigate = useNavigate();
  const user = useAppSelector((state) => state.auth.userDetails);

  const onSubmit = async () => {
    if (!user?.email) return;
    await API.getInstance().put(API_ENDPOINTS.SAVE_CURRENT_USER_PASSWORD, { email: user?.email });
    navigate('#reset-password');
  };

  return (
    <Stack spacing={12}>
      <Stack>
        <StyledTypography variant='h4' gutterBottom>
          Change Password
        </StyledTypography>
        <StyledTypographySubTitle sx={{ width: '448px' }}>
          Your password has expired, please change to a new password.
        </StyledTypographySubTitle>
      </Stack>
      <Button
        color='secondary'
        data-testid='ok-button'
        disableRipple
        fullWidth
        onClick={() => {
          onSubmit();
        }}
        variant='contained'
        sx={{ height: '60px' }}
      >
        OK
      </Button>
    </Stack>
  );
};

export default ChangePasswordPeriod;
