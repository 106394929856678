import React from 'react';
import { Outlet } from 'react-router-dom';

import CssBaseline from '@mui/material/CssBaseline';

import { RootStyle } from '../../theme/layouts';
import DashboardNavbar from './Navbar';
import DashboardSidebar from './Sidebar';
import { Box } from '@mui/material';

const DashboardLayout: React.FC = () => {
  return (
    <RootStyle>
      <CssBaseline />
      <DashboardNavbar />
      <DashboardSidebar />
      <Box component='main' sx={{ flexGrow: 1, p: 3, width: '100%', margin: '64px 24px 0 24px' }}>
        <Outlet />
      </Box>
    </RootStyle>
  );
};
export default DashboardLayout;
