// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("/src/scss/abstracts/fonts/Rubik-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("/src/scss/abstracts/fonts/Rubik-Light.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("/src/scss/abstracts/fonts/Rubik-Medium.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face{font-family:"Rubik Regular";src:url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("truetype")}@font-face{font-family:"Rubik Light";src:url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format("truetype")}@font-face{font-family:"Rubik Medium";src:url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format("truetype")}
`, "",{"version":3,"sources":["webpack://./src/scss/abstracts/_fonts.scss"],"names":[],"mappings":"AAAA,WACI,2BAA4B,CAC5B,8DAA0E,CAE9E,WACI,yBAA0B,CAC1B,8DAAwE,CAE5E,WACI,0BAA2B,CAC3B,8DAAyE","sourcesContent":["@font-face {\n    font-family: \"Rubik Regular\";\n    src: url(\"/src/scss/abstracts/fonts/Rubik-Regular.ttf\") format(\"truetype\");\n}\n@font-face {\n    font-family: \"Rubik Light\";\n    src: url(\"/src/scss/abstracts/fonts/Rubik-Light.ttf\") format(\"truetype\");\n}\n@font-face {\n    font-family: \"Rubik Medium\";\n    src: url(\"/src/scss/abstracts/fonts/Rubik-Medium.ttf\") format(\"truetype\");\n}\n\n/*  FONTS  */\n$fonts:(\n    'main': 'Rubik Light',\n    'bold': 'Rubic Medium',  \n);\n "],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
