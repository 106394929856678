/* eslint-disable functional/no-let */
import { isValid } from 'date-fns';
import { DataType, IColumnType, ITableState, Order, RowFormatType } from './types';
import _ from 'lodash';
import { ObjectView } from 'components/ObjectView';
import { isValidElement } from 'react';

export const descendingComparator = <T>(a: T, b: T, orderBy: keyof T) => {
  const regex = /^\d{2}.\d{2}.\d{4}$/;
  let _a: any;
  let _b: any;
  if (regex.test(String(a[orderBy])) && regex.test(String(b[orderBy]))) {
    let [day, month, year] = String(a[orderBy]).split('.');
    const tmpA = new Date(`${month}.${day}.${year}`);
    [day, month, year] = String(b[orderBy]).split('.');
    const tmpB = new Date(`${month}.${day}.${year}`);
    if (isValid(tmpA) && isValid(tmpB)) {
      _a = tmpA;
      _b = tmpB;
    }
  } else {
    if (typeof a[orderBy] === 'string') _a = _.toLower(String(a[orderBy]));
    else _a = a[orderBy];

    if (typeof b[orderBy] === 'string') _b = _.toLower(String(b[orderBy]));
    else _b = b[orderBy];
  }

  if (_b < _a) {
    return -1;
  }
  if (_b > _a) {
    return 1;
  }
  return 0;
};

export function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (a: { [key in Key]: number | string | Date }, b: { [key in Key]: number | string | Date }) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export const handleRequestSort = (tableState: ITableState, setState: any, property: string) => {
  const isAsc = tableState.sortBy === property && tableState.orderBy === 'asc';
  setState({ ...tableState, orderBy: isAsc ? 'desc' : 'asc', sortBy: property });
};

export const formatValue = (type: DataType, value: any, col: IColumnType, record: Record<string, number>) => {
  switch (type) {
    case 'link':
      return 'link';
    case 'custom':
      if (col.customValueHandle) {
        return col.customValueHandle(value, record);
      }
      return value;
    case 'json':
      try {
        return { value, object: ObjectView({ jsonString: value }) };
      } catch (error) {
        console.error(error)
        return value;
      }
    default:
      return value || '';
  }
};

export const limitLength = (value: string | any, maxLength?: number): string => {
  if (isValidElement(value.object)) return limitLength(value.value, maxLength);
  if (!maxLength) return value;
  if (value.length < maxLength) return value;
  return String(value).substring(0, maxLength) + '...';
};

export const formatActiveCell = <T extends RowFormatType>(row: T) => {
  const _isActive = !!row?.active;
  const _inTransfer = !!row?.siteToTransferTo;
  const p = 1;
  const borderRadius = 0.5;
  const color = _inTransfer ? '936100' : _isActive ? '#04AE79' : '#FF6969';
  const backgroundColor = _inTransfer ? '#F9F1D1' : _isActive ? '#e2ece8' : '#f1d6d7';
  const display = 'flex';
  const justifyContent = 'center';
  return { p, borderRadius, color, backgroundColor, display, justifyContent };
};
