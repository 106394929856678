import React from 'react';
interface IconProps {
  width?: string;
  height?: string;
  fill?: string;
  hover?: boolean;
}

export const CalenderIcon: React.FC<IconProps> = ({
  width = '24px',
  height = '24px',
  fill = '#037691',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <title>B18D010C-1723-442B-A4E7-5382C43E9A74</title>
      <g id='💎-BlueWind-Design-System-💎' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='Style-Guide---Global-elements' transform='translate(-282.000000, -2551.000000)'>
          <g id='icon-/-24px-/-calander' transform='translate(282.000000, 2551.000000)'>
            <g id='calendar-(1)' transform='translate(3.000000, 2.000000)' fill={fill}>
              <path
                d='M12,2 L12,1 C12,0.448 12.448,0 13,0 C13.552,0 14,0.448 14,1 L14,2 C16.209,2 18,3.791 18,6 L18,16 C18,18.209 16.209,20 14,20 C11.14,20 6.859,20 4,20 C1.791,20 0,18.209 0,16 L0,6 C0,3.791 1.791,2 4,2 L4,1 C4,0.448 4.448,0 5,0 C5.552,0 6,0.448 6,1 L6,2 L12,2 Z M16,10 L2,10 L2,16 C2,17.104 2.895,18 4,18 L14,18 C15.104,18 16,17.104 16,16 L16,10 Z M14,4 L14,5 C14,5.552 13.552,6 13,6 C12.448,6 12,5.552 12,5 L12,4 L6,4 L6,5 C6,5.552 5.552,6 5,6 C4.448,6 4,5.552 4,5 L4,4 C2.895,4 2,4.895 2,6 L2,8 L16,8 L16,6 C16,4.895 15.104,4 14,4 Z'
                id='Shape'
              ></path>
            </g>
            <rect id='Rectangle' x='0' y='0' width='24' height='24'></rect>
          </g>
        </g>
      </g>
    </svg>
  );
};
