import { Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledMainGrid = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignContent: 'center'
}));

const StyledProfileOption = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '42px 0 32px',
  cursor: 'pointer'
}));

const StyledProfileCircle = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignContent: 'center',
  marginBottom: '24px'
}));

const StyledProfileTitle = styled(Typography)(() => ({
  fontSize: '18px',
  fontWeight: '600',
  lineHeight: '27px',
  color: ' #333'
}));

const StyledProfileSubtitle = styled(Typography)(() => ({
  fontSize: '16px',
  fontWeight: '300',
  lineHeight: '24px',
  color: ' #333'
}));

export { StyledMainGrid, StyledProfileOption, StyledProfileCircle, StyledProfileTitle, StyledProfileSubtitle };
