import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from 'hooks/redux.hooks';
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form';
import { Button, Stack, Typography } from '@mui/material';

import { StyledTypography, StyledTypographyValidation } from './styled';
import CustomInputPassword from '../InputsForm/CustomInputPassword';
import CustomDefaultInput from '../InputsForm/CustomDefaultInput';
import { login, setUserNameAndPassword } from 'state/reducers/auth.reducers';
import { Info } from '@mui/icons-material';
import { emailValidation } from './utils/passwordValidation';

interface IFormInputs {
  userName: string;
  password: string;
}

const Login: React.FC = () => {
  const navigate = useNavigate();
  const methods = useForm<IFormInputs>({});
  const dispatch = useAppDispatch();
  const { userDetails, error, loading } = useAppSelector((state) => state.auth);
  const [userName] = methods.watch(['userName']);
  const isEmailValid = emailValidation(userName);
  const formSubmitHandler: SubmitHandler<IFormInputs> = (data: IFormInputs) => {
    if (isEmailValid) {
      dispatch(login(data));
      dispatch(setUserNameAndPassword(data));
    }
  };

  useEffect(() => {
    if (!loading && !error && userDetails?.password) {
      navigate('#account-authentication');
    } else {
      if (error?.appCode === 100) {
        navigate('#lock');
      }
      if (error?.errorCode === 401 && error?.errorMessage === 'login error') {
        return;
      }
    }
  }, [userDetails, error, loading]);

  return (
    <FormProvider {...methods}>
      <form noValidate onSubmit={methods.handleSubmit(formSubmitHandler)}>
        <Stack spacing={6}>
          <StyledTypography variant='h4' gutterBottom>
            Login
          </StyledTypography>
          <Stack spacing={6}>
            <Stack spacing={3}>
              <CustomDefaultInput
                controllerName={'userName'}
                inputRegister={'userName'}
                inputId={'userName'}
                label={'E-mail'}
                type={'email'}
              />
              <CustomInputPassword
                controllerName={'password'}
                inputRegister={'password'}
                inputId={'password'}
                label={'Password'}
              />
              {error && (
                <StyledTypographyValidation variant='subtitle1' gutterBottom data-testid='email-password-error'>
                  <Info sx={{ color: '#333333', padding: '8px 8px 0 0', alignSelf: 'flex-start' }} />
                  {error.errorCode === 500
                    ? 'The connection to server failed, please contact support.'
                    : 'Entered email address or password incorrect. Please try again.'}
                </StyledTypographyValidation>
              )}
              <Button
                fullWidth
                disabled={!methods.formState.isValid || !isEmailValid}
                type='submit'
                variant='contained'
                color='secondary'
                data-testid='login-button'
                sx={{ height: '60px' }}
              >
                Login
              </Button>
            </Stack>
            <Typography
              component={Link}
              to='#forgot-password'
              sx={{ color: '#333333', textDecoration: 'none', textAlign: 'center', fontSize: 17 }}
            >
              Forgot password?
            </Typography>
          </Stack>
        </Stack>
      </form>
    </FormProvider>
  );
};

export default Login;
