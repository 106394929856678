import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { UserRole, IUser } from 'constants/user.constants';
import { API_ENDPOINTS } from 'constants/db.constants';
import API from '../../services/api.service';
import jwtDecode from 'jwt-decode';
import { convertKeysToCamelCase } from '../../utils/global.util';

interface IUserState {
  userPermission?: UserRole;
  userDetails?: IUser;
  loading?: boolean;
  login?: boolean;
  error?: IStateError;
  blockTimer?: number;
}

export interface IStateError {
  errorCode?: number | null;
  errorMessage: string;
  appCode?: number | null;
}

const initialState: IUserState = {
  userPermission: UserRole.NONE,
  userDetails: undefined,
  loading: false,
  login: false,
  error: undefined
};

export const login = createAsyncThunk('auth/login', (data: any, { rejectWithValue }) =>
  API.getInstance()
    .post(API_ENDPOINTS.LOGIN, data)
    .then((response) => response.data)
    .catch((err) => rejectWithValue(err))
);

export const authorize = createAsyncThunk('auth/authorizeLogin', (data: any, { dispatch, rejectWithValue }) =>
  API.getInstance()
    .post(API_ENDPOINTS.AUTHENTICATE, data)
    .then((response) => {
      dispatch(setUserDetails(response.data));
      localStorage.setItem('token', response.data.token);
    })
    .catch((err) => rejectWithValue(err))
);

export const resetPasswordByEmail = createAsyncThunk('auth/resetPasswordByEmail', (data: any, { rejectWithValue }) =>
  API.getInstance()
    .post(API_ENDPOINTS.RESET_PASSWORD_BY_EMAIL, data)
    .then((response) => response.data)
    .catch((err) => rejectWithValue(err))
);

export const resetPassword = createAsyncThunk('auth/resetPassword', (data: any, { dispatch, rejectWithValue }) =>
  API.getInstance()
    .put(API_ENDPOINTS.RESET_PASSWORD, data)
    .then((response) => {
      dispatch(setUserDetails(response.data));
      localStorage.setItem('token', response.data.token);
    })
    .catch((err) => rejectWithValue(err))
);

export const updateUserProfile = createAsyncThunk('auth/updateUser', (newUserData: any, { rejectWithValue }) =>
  API.getInstance()
    .put(API_ENDPOINTS.USERS.UPDATE_USER_PROFILE, newUserData)
    .then((response) => response.data)
    .catch((err) => rejectWithValue(err))
);

export const setOnUserLogOut = createAsyncThunk('auth/logout', () => {
  API.getInstance().post(API_ENDPOINTS.USER_LOG_OUT);
});
export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setError: (state, { payload }) => {
      state.error = {
        errorCode: payload.errorCode,
        errorMessage: payload.data?.message
      };
    },
    resetState: (state) => {
      state.error = undefined;
      state.userDetails = undefined;
      state.userPermission = undefined;
    },
    setUserRole: (state, { payload }) => {
      state.userPermission = payload.userPermission;
    },
    setUserNameAndPassword: (state, { payload }) => {
      state.userDetails = {
        userName: payload?.userName,
        password: payload?.password,
        email: payload?.email
      };
    },
    setUserDetails: (state, { payload }) => {
      const jwtDecoded = jwtDecode(payload.token) as IUser;
      state.userDetails = convertKeysToCamelCase(jwtDecoded);
    },
    setUserProfileDetails: (state, { payload }) => {
      state.userDetails = convertKeysToCamelCase(payload);
    },
    logout: (state) => {
      localStorage.clear();
      state.userDetails = undefined;
      state.userPermission = undefined;
    }
  },
  extraReducers: {
    [login.pending.type]: (state) => {
      state.loading = true;
      state.error = undefined;
    },
    [login.fulfilled.type]: (state) => {
      state.loading = false;
      state.login = true;
    },
    [login.rejected.type]: (state, { payload }) => {
      if (
        payload?.status === 500 ||
        payload?.status === 503 ||
        payload?.response?.status === 500 ||
        payload?.response?.status === 503 ||
        payload?.response?.code === 500 ||
        payload?.response?.code === 503 ||
        payload?.code === 500 ||
        payload?.code === 503 ||
        payload.message === 'Network Error'
      ) {
        state.error = {
          appCode: 500,
          errorCode: 500,
          errorMessage: 'Server is not responding. Please try again later.'
        };
        state.loading = false;
        return;
      }

      state.blockTimer = payload?.data?.data?.block_more_seconds;
      const date = new Date();
      date.setSeconds(date.getSeconds() + (state.blockTimer || 0));
      localStorage.setItem('blockTimer', `${+date}`);
      state.error = {
        appCode: payload?.data?.app_code,
        errorCode: payload?.data?.statusCode,
        errorMessage: payload?.data?.message
      };
      state.loading = false;
    },
    [authorize.pending.type]: (state) => {
      state.loading = true;
      state.error = undefined;
    },
    [authorize.fulfilled.type]: (state) => {
      state.loading = false;
    },
    [authorize.rejected.type]: (state, { payload }) => {
      state.error = {
        errorCode: payload?.statusCode,
        errorMessage: payload?.message
      };
      state.loading = false;
    },
    [resetPasswordByEmail.pending.type]: (state) => {
      state.loading = true;
      state.error = undefined;
    },
    [resetPasswordByEmail.fulfilled.type]: (state) => {
      state.loading = false;
    },
    [resetPasswordByEmail.rejected.type]: (state, { payload }) => {
      state.error = {
        errorCode: payload?.data?.statusCode,
        errorMessage: payload?.data?.message
      };
      state.loading = false;
    },
    [resetPassword.pending.type]: (state) => {
      state.loading = true;
      state.error = undefined;
    },
    [resetPassword.fulfilled.type]: (state) => {
      state.loading = false;
    },
    [resetPassword.rejected.type]: (state, { payload }) => {
      state.error = {
        errorCode: payload?.data?.statusCode,
        errorMessage: payload?.data?.message
      };
      state.loading = false;
    },
    [updateUserProfile.pending.type]: (state) => {
      state.loading = true;
      state.error = undefined;
    },
    [updateUserProfile.fulfilled.type]: (state, { payload }) => {
      const jwtDecoded = jwtDecode(payload.data) as IUser;
      state.userDetails = { ...state.userDetails, ...convertKeysToCamelCase(jwtDecoded) };
      state.loading = false;
    },
    [updateUserProfile.rejected.type]: (state, { payload }) => {
      state.loading = false;
      state.error = {
        errorCode: payload?.data?.statusCode,
        errorMessage: payload?.data?.message
      };
    },
    [setOnUserLogOut.pending.type]: (state) => {
      state.loading = true;
      state.error = undefined;
    },
    [setOnUserLogOut.fulfilled.type]: (state) => {
      state.loading = false;
    },
    [setOnUserLogOut.rejected.type]: (state, { payload }) => {
      state.error = {
        errorMessage: payload?.message
      };
      state.loading = false;
    }
  }
});

export const {
  setError,
  setUserRole,
  setUserNameAndPassword,
  setUserDetails,
  logout,
  resetState,
  setUserProfileDetails
} = authSlice.actions;

export default authSlice.reducer;
