import { styled } from '@mui/material/styles';
import { TextField, Box } from '@mui/material';

interface IStyledDropDownBoxProps {
  disabled: boolean;
  isclickable: boolean;
  error: boolean;
}

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiFormLabel-root': {
    fontSize: '14px',
    '&.Mui-focused': {
      color: '#333333'
    },
    '&.Mui-disabled': {
      color: '#333333'
    }
  },
  '& fieldset': {
    borderRadius: theme.shape.borderRadius
  }
}));

const StyledInputBox = styled(Box)(({ disabled, isclickable, error }: IStyledDropDownBoxProps) => ({
  width: '400px',
  height: '60px',
  display: 'flex',
  justifyContent: 'center',
  overflow: 'hidden',
  alignItems: 'center',
  padding: '8px 14px',
  backgroundColor: disabled ? 'none' : 'white',
  border: `${error || !disabled ? 'solid 1px ' : ''} ${error ? '#FA3D3D' : !disabled ? '#B8BCCC' : 'none'}`,
  borderRadius: '12px',
  cursor: isclickable ? 'pointer' : 'unset'
}));

export { StyledTextField, StyledInputBox };
