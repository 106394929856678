import React from 'react';
import { useLocation } from 'react-router-dom';
import { Box, CardContent } from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';

import Login from './login';
import { AccountAuthentication } from './accountAuthentication';
import ForgotPassword from './forgotPassword';
import LockUser from './lockUser';
import ResetPassword from './resetPassword';
import ForgotPasswordVerify from './forgotPasswordVerify';
import ChangePasswordPeriod from './changePasswordPeriod';

import { StyledCard, StyledTypographyLock } from './styled';

const AuthorizationForm: React.FC = () => {
  const location = useLocation();

  const createForm = () => {
    const { hash } = location;
    switch (hash) {
      case '#account-authentication':
        return <AccountAuthentication />;
      case '#forgot-password':
        return <ForgotPassword />;
      case '#reset-password':
        return <ResetPassword />;
      case '#forgot-password-verify':
        return <ForgotPasswordVerify />;
      case '#lock':
        return <LockUser />;
      case '#change-password-period':
        return <ChangePasswordPeriod />;
      default:
        return <Login />;
    }
  };

  const isLockScreen = () => {
    const { hash } = location;
    if (hash === '#lock') {
      return (
        <StyledTypographyLock variant='subtitle1' gutterBottom>
          <LockIcon sx={{ color: '#333333', paddingRight: '8px' }} /> Too many failed login attempts, Login blocked for
          5:00 minutes.
        </StyledTypographyLock>
      );
    }
    return;
  };

  return (
    <Box>
      {isLockScreen()}
      <StyledCard>
        <CardContent sx={{ width: 460 }}>{createForm()}</CardContent>
      </StyledCard>
    </Box>
  );
};

export default AuthorizationForm;
