import React, { Dispatch, SetStateAction } from 'react';
import { Button, DialogActions, DialogContent, DialogContentText, Stack } from '@mui/material';
import { StyledDialog, StyledDialogTitle } from './styled';

// eslint-disable-next-line functional/no-mixed-type
interface IDialogProps {
  errorDialog?: boolean;
  dialogTitle?: string;
  dialogContentText?: string;
  openDialog: boolean;
  setOpenDialog?: Dispatch<SetStateAction<boolean>>;
  setStatusInactive?: Dispatch<SetStateAction<boolean>>;
  onConfirm: () => void;
  confirmLabel?: string;
  cancelLabel?: string;
  showCancel?: boolean;
}

const ConfirmDialog: React.FC<IDialogProps> = (props: IDialogProps) => {
  const {
    openDialog,
    setOpenDialog,
    dialogContentText,
    dialogTitle,
    onConfirm,
    confirmLabel,
    cancelLabel,
    showCancel
  } = props;
  return (
    <>
      <StyledDialog
        open={openDialog}
        onClose={() => {
          if (!setOpenDialog) return;
          setOpenDialog(false);
        }}
      >
        <StyledDialogTitle sx={{ textAlign: 'center' }}>{dialogTitle}</StyledDialogTitle>
        {dialogContentText && (
          <DialogContent>
            <DialogContentText>{dialogContentText}</DialogContentText>
          </DialogContent>
        )}
        <DialogActions>
          <Stack spacing={1}>
            <Button
              data-testid='ok-button'
              autoFocus
              variant='contained'
              color='secondary'
              onClick={onConfirm}
              sx={{ minWidth: '420px', height: '60px' }}
            >
              {confirmLabel || 'Ok'}
            </Button>
            {showCancel && (
              <Button
                data-testid='cancel-button'
                autoFocus
                variant='text'
                color='secondary'
                onClick={() => {
                  if (setOpenDialog) {
                    setOpenDialog(false);
                  }
                }}
                sx={{ minWidth: '420px', height: '60px' }}
              >
                {cancelLabel || 'Cancel'}
              </Button>
            )}
          </Stack>
        </DialogActions>
      </StyledDialog>
    </>
  );
};

export default ConfirmDialog;
