import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from 'hooks/redux.hooks';
import { getAllSites } from 'state/reducers/user.reducers';
import { getAllCps, updateCp } from 'state/reducers/cp.reducers';
import _ from 'lodash';

import { Box, Button, Card, Stack } from '@mui/material';

//style
import { StyledCard, StyledCardContent, StyledStatusBox, StyledTypographySubTitle } from '../../styled-components';

//constants
import { CpInputsForm, ICp, ICpActive } from 'constants/cp.constants';
import { pagesEnum } from 'constants/ui.constants';
//components
import CustomInputForm from '../../../components/InputsForm/CustomInput';
import ConfirmDialog from 'components/ConfirmDialog';
//icons
import { PermissionIcon } from 'assets/icons/Permission.icon';
import { CalenderIcon } from 'assets/icons/Calender.icon';
import { EditIcon } from 'assets/icons/Edit.icon';
import { Site } from 'constants/sites.constants';
import SelectBox from 'components/SelectBox';
import NoInfo from 'components/NoInfo';
import CircularIndeterminate from 'components/Loading';
import { TabsSwitcher } from 'components/TabsSwitcher';
import ArrowBackBtn from 'components/ArrowBackBtn/arrowBackBtn';

interface IFormInputs {
  dataFrom: CpInputsForm;
  openProfileModal: boolean;
  setOpenProfileModal: Dispatch<SetStateAction<boolean>>;
  cp: ICp;
}

const CpInformation: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { id } = useParams();

  const { sites } = useAppSelector((state) => state.user);
  const [cp, setCp] = useState<ICp | undefined>(undefined);

  const { cps, loading, error } = useAppSelector((state) => state.cp);
  const [formError, setFormError] = useState<{ [key: string]: boolean }>({});
  const [onEdit, setOnEdit] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  const [assignSite, setAssignSite] = useState('');

  useEffect(() => {
    dispatch(getAllSites());
  }, []);

  useEffect(() => {
    if (!cps) {
      dispatch(getAllCps({}));
    }
  }, []);

  const getFullName = (firstName = '', lastName = '') => {
    if (!firstName && !lastName) return 'N/A';
    else return `${firstName || ''} ${lastName || ''}`;
  };

  const [statusInactive, setStatusInactive] = useState(false);

  const methods = useForm<IFormInputs>({
    defaultValues: {
      dataFrom: {},
      cp: {}
    }
  });

  useEffect(() => {
    if (cps) {
      const findCp = cps?.find((cp: ICp) => cp.id == id);
      if (!findCp) {
        setCp(undefined);
        return;
      }
      if (!findCp?.assignedSite) {
        setAssignSite('All');
      }
      setCp(findCp);
      setAssignSite(findCp.assignedSite ?? 'All');
      setStatusInactive((findCp.active as unknown as ICpActive) === ICpActive.Inactive);
      methods.reset({
        openProfileModal: undefined,
        setOpenProfileModal: undefined,
        dataFrom: {
          idNumber: (findCp?.idNumber || '') as string,
          createdAt: `${getFullName(findCp?.created_by_first_name, findCp?.created_by_last_name)} ${
            findCp?.createdAt || ''
          }` as string,
          lastLogin: findCp?.lastActivity || '',
          lastEdit: (findCp?.lastEdit || '') as string
        }
      });
    }
  }, [cps, id]);

  const onConfirmHandler = () => {
    const updateCpData = {
      active: statusInactive
    };
    const cpId = cp?.id;
    dispatch(updateCp({ updateCpData, cpId }));
    setStatusInactive(!statusInactive);
    setOpenDialog(false);
    setOnEdit(false);
  };

  const formSubmitHandler: SubmitHandler<IFormInputs> = (data: IFormInputs) => {
    const site = sites?.find((site: Site) => site.name == assignSite) || { id: null };
    const updateCpData = {
      cp_identification: data.dataFrom.idNumber,
      site_id: site?.id,
      active: !statusInactive
    };
    const cpId = cp?.id;
    dispatch(updateCp({ updateCpData, cpId })).then(() => {
      setOnEdit(false);
    });
  };

  const validateField = (event: any) => {
    const { id, value }: { id: keyof ICp; value: string } = event.target;
    if (value?.length) {
      if (['name', 'idNumber'].includes(id) && value) {
        const exist = !!cps?.find((_cp: ICp) => {
          return _cp[id] === value && !_.isEqual(_cp.id, cp?.id);
        });
        if (exist) {
          setFormError({
            ...formError,
            [id]: true
          });
          return;
        }
        setFormError({
          ...formError,
          [id]: false
        });
      }
    }
  };

  const isSubmitDisabled = useMemo(() => {
    return _.includes(_.values(formError), true) || loading;
  }, [formError, loading]);

  if (loading) return <CircularIndeterminate />;
  if (!cp || error) return <NoInfo />;

  return (
    <Box sx={{ width: '100%', height: '100%' }}>
      <FormProvider {...methods}>
        <form noValidate onChange={validateField} onSubmit={methods.handleSubmit(formSubmitHandler)}>
          <StyledCard sx={{ background: 'transparent' }}>
            <StyledCardContent>
              <Stack spacing={3}>
                <ArrowBackBtn title={'CP Info'} route={`/${pagesEnum.CP}`} />
                <Stack spacing={1} direction='row' alignItems='center' justifyContent='space-between'>
                  <Stack spacing={2} direction='row' alignItems='center'>
                    {onEdit ? (
                      <TabsSwitcher
                        onClick={() => {
                          onEdit ? setOpenDialog(true) : {};
                        }}
                        isInactive={statusInactive}
                        disabled={!onEdit}
                      />
                    ) : (
                      <StyledStatusBox isInactive={statusInactive}>
                        {statusInactive ? 'Inactive' : 'Active'}
                      </StyledStatusBox>
                    )}
                  </Stack>
                  {!onEdit && (
                    <Button
                      color='secondary'
                      data-testid='edit-button'
                      startIcon={<EditIcon />}
                      variant='outlined'
                      onClick={() => {
                        setOnEdit(true);
                      }}
                    >
                      Edit CP
                    </Button>
                  )}
                  <ConfirmDialog
                    openDialog={openDialog}
                    setOpenDialog={setOpenDialog}
                    onConfirm={onConfirmHandler}
                    dialogTitle={statusInactive ? 'Convert CP to active' : 'Convert CP to inactive'}
                    confirmLabel='OK'
                    showCancel={true}
                  />
                </Stack>
                <Card variant='outlined' sx={{ p: 3 }}>
                  <Stack direction='row' spacing={2} flexWrap='wrap' useFlexGap>
                    <CustomInputForm
                      disabled={!onEdit}
                      defaultValue={cp?.idNumber}
                      controllerName={'dataFrom.idNumber'}
                      inputRegister={'dataFrom.idNumber'}
                      inputId={'idNumber'}
                      label={'iPad Serial Number'}
                      type={'text'}
                      errorMessage={_.get(formError, 'idNumber') ? 'Same CP id, Try other id' : ''}
                      icon={<PermissionIcon />}
                    />
                    <CustomInputForm
                      disabled={true}
                      defaultValue={`${cp.created_by_first_name} ${cp.created_by_last_name} ${cp?.createdAt}`}
                      controllerName={'dataFrom.createdAt'}
                      inputRegister={'dataFrom.createdAt'}
                      inputId={'createdAt'}
                      label={'Created By'}
                      type={'text'}
                      icon={<CalenderIcon />}
                    />
                  </Stack>
                </Card>
                <StyledTypographySubTitle>Assign to Site</StyledTypographySubTitle>
                <SelectBox
                  key={'sites'}
                  items={sites.map((site) => site?.name ?? '').filter((name) => name.length)}
                  values={assignSite}
                  onFilterChange={(filterType: string, filterValue: string) => {
                    setAssignSite(filterValue);
                  }}
                  type={'sites'}
                  withAllOption
                  allOptionLabel='Personal Site'
                  disabled={!onEdit}
                  inputId='site'
                />
              </Stack>
            </StyledCardContent>
          </StyledCard>
          {onEdit && (
            <Stack spacing={2} direction='row' sx={{ display: 'flex', justifyContent: 'flex-end', m: 10 }}>
              <Button
                color='secondary'
                data-testid='cancel-button'
                size='large'
                variant='text'
                onClick={() => {
                  setOnEdit(false);
                  navigate(`/${pagesEnum.CP}/${cp?.id}`);
                }}
              >
                cancel
              </Button>
              <Button
                color='secondary'
                variant='contained'
                data-testid='save-button'
                disabled={isSubmitDisabled}
                type='submit'
                size='large'
              >
                save
              </Button>
            </Stack>
          )}
        </form>
      </FormProvider>
    </Box>
  );
};

export default CpInformation;
