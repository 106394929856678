// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../assets/fonts/TitilliumWeb-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../assets/fonts/TitilliumWeb-SemiBold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("../assets/fonts/TitilliumWeb-Light.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
    font-family: "TitilliumWebRegular";
    src: local("TitilliumWebRegular"),
    url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("truetype");
}

@font-face {
    font-family: "TitilliumWebSemiBold";
    src: local("TitilliumWebSemiBold"),
    url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format("truetype");
}

@font-face {
    font-family: "TitilliumWebLight";
    src: local("TitilliumWebLight"),
    url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format("truetype");
}
`, "",{"version":3,"sources":["webpack://./src/theme/fonts.css"],"names":[],"mappings":"AAAA;IACI,kCAAkC;IAClC;8DACkE;AACtE;;AAEA;IACI,mCAAmC;IACnC;8DACmE;AACvE;;AAEA;IACI,gCAAgC;IAChC;8DACgE;AACpE","sourcesContent":["@font-face {\n    font-family: \"TitilliumWebRegular\";\n    src: local(\"TitilliumWebRegular\"),\n    url(\"../assets/fonts/TitilliumWeb-Regular.ttf\") format(\"truetype\");\n}\n\n@font-face {\n    font-family: \"TitilliumWebSemiBold\";\n    src: local(\"TitilliumWebSemiBold\"),\n    url(\"../assets/fonts/TitilliumWeb-SemiBold.ttf\") format(\"truetype\");\n}\n\n@font-face {\n    font-family: \"TitilliumWebLight\";\n    src: local(\"TitilliumWebLight\"),\n    url(\"../assets/fonts/TitilliumWeb-Light.ttf\") format(\"truetype\");\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
