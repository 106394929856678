import React from 'react';
import { Controller, FieldArrayWithId, useFieldArray, useFormContext } from 'react-hook-form';

import {
  Table,
  Paper,
  TableBody,
  TableContainer,
  Autocomplete,
  IconButton,
  TextField,
  Box,
  Button
} from '@mui/material';
import { Add, DeleteOutlineRounded } from '@mui/icons-material';

import { StyledTableCell, StyledTableRow } from '../../styled-components';
import _ from 'lodash';
import { Site } from 'constants/sites.constants';
import { useLocation } from 'react-router-dom';
import { ISitePermission } from 'constants/user.constants';
import ConfirmDialog from 'components/ConfirmDialog';

interface ISitePermissionTable {
  isEditMode: boolean;
  sitePermissions?: any;
}
type FormValues = {
  sitePermissions: Array<ISitePermission>;
};

export const SitePermissionTable: React.FC<ISitePermissionTable> = ({ isEditMode, sitePermissions }) => {
  const [openDialog, setOpenDialog] = React.useState(false);
  const { control, getValues } = useFormContext();
  const { fields, append, remove } = useFieldArray<FormValues, 'sitePermissions'>({
    name: 'sitePermissions'
  });

  const location = useLocation();
  const edit = location?.pathname?.includes('new') || isEditMode;

  const handleRemove = (id: string) => {
    remove(fields.findIndex((item) => item.id === id));
  };

  const closeDialog = () => {
    setOpenDialog(false);
  };

  const getUnChosenOptions = () => {
    const chosenOptions = getValues('sitePermissions').map((item: ISitePermission) => item.id);
    return _.filter(sitePermissions, (item: Site) => !chosenOptions.includes(item.id));
  };

  const renderOptions = (formField: FieldArrayWithId<FormValues>, index: number) => {
    return (
      <Controller
        name={`sitePermissions.${index}.id`}
        control={control}
        rules={{
          required: true
        }}
        render={({ field: { onChange } }) => {
          return (
            <Autocomplete
              onChange={(e, item: Site | null) => {
                onChange(item?.id);
              }}
              disabled={!edit}
              getOptionLabel={(option) => option.name as string}
              autoHighlight
              options={getUnChosenOptions()}
              sx={{ width: '100%' }}
              renderInput={(params) => {
                return (
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {edit && (
                      <IconButton
                        sx={{
                          marginRight: '10px',
                          cursor: 'pointer'
                        }}
                        onClick={() => {
                          if (fields.length <= 1) {
                            setOpenDialog(true);
                          } else {
                            handleRemove(formField.id);
                          }
                        }}
                      >
                        <DeleteOutlineRounded />
                      </IconButton>
                    )}
                    <TextField
                      sx={{
                        '& fieldset': { border: !edit ? 'none' : null }
                      }}
                      label={formField?.name}
                      inputProps={{ ...params.inputProps, 'data-testid': 'add-new' }}
                      InputLabelProps={params.InputLabelProps}
                      InputProps={params.InputProps}
                      disabled={params.disabled}
                      fullWidth={params.fullWidth}
                      id={params.id}
                      size={params.size}
                    />
                  </Box>
                );
              }}
            />
          );
        }}
      />
    );
  };

  return (
    <>
      <TableContainer component={Paper} sx={{ maxWidth: 768 }}>
        <Table aria-label='customized table'>
          <TableBody>
            {_.map(fields, (field, index) => {
              return (
                <StyledTableRow key={field.id}>
                  <StyledTableCell
                    sx={{
                      display: 'flex',
                      alignItems: 'center'
                    }}
                    variant='body'
                    align='left'
                  >
                    {renderOptions(field, index)}
                  </StyledTableCell>
                </StyledTableRow>
              );
            })}
            {edit && (
              <StyledTableRow>
                <StyledTableCell>
                  <Button
                    sx={{ m: 2 }}
                    color='secondary'
                    variant='text'
                    startIcon={<Add />}
                    onClick={() => {
                      const firstRole = fields.length > 0 ? getValues('sitePermissions[0].role') : undefined;
                      append({ id: 0, role: firstRole });
                    }}
                  >
                    Add New Site
                  </Button>
                </StyledTableCell>
              </StyledTableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <ConfirmDialog
        openDialog={openDialog}
        setOpenDialog={closeDialog}
        dialogTitle={'Users must be linked to at least one site. Inactivate user if relevant'}
        showCancel={false}
        confirmLabel='OK'
        onConfirm={closeDialog}
      />
    </>
  );
};
