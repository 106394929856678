import { styled } from '@mui/material/styles';
import { Dialog, DialogTitle } from '@mui/material';

const StyledDialog = styled(Dialog)(() => ({
  '.MuiDialog-paper': {
    minWidth: '720px',
    minHeight: '418px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '40px',
    paddingBottom: '24px'
  }
}));

const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  fontSize: '32px',
  fontWeight: '600',
  color: theme.palette.root.header
}));

export { StyledDialog, StyledDialogTitle };
