import { useNavigate } from 'react-router-dom';
import React, { useMemo } from 'react';
import { CardContent, Typography } from '@mui/material';
//style
import { StyledIcon, StyledMemberBox, StyledMemberCard } from '../../styled-components';

interface IFormInputs {
  title?: string;
  icon?: React.ReactNode;
  count?: number;
  params?: { [key: string]: string | number | boolean };
  to?: string | boolean;
}

export const SiteMember: React.FC<IFormInputs> = ({ title, icon, count, params, to }) => {
  const navigate = useNavigate();
  const isclickable = useMemo(
    () => !!((to || String(to).length) && params && (Object.keys(params as any)?.length || String(params)?.length)),
    [to, params]
  );

  const parsedUrl = useMemo(() => {
    if (!isclickable) return '';
    return encodeURI(
      `/${to}${Object.keys(params!)
        .map((key) => {
          return `/${key}/${params![key]}`;
        })
        .join('')}`
    );
  }, [isclickable, params]);

  return (
    <StyledMemberBox
      data-testid={title}
      sx={{ cursor: isclickable ? 'pointer' : 'unset' }}
      onClick={() => isclickable && navigate(parsedUrl)}
    >
      <StyledIcon>{icon}</StyledIcon>
      <StyledMemberCard>
        <CardContent>
          <Typography sx={{ textAlign: 'center' }}>{title}</Typography>
          <Typography sx={{ textAlign: 'center' }}>{count}</Typography>
        </CardContent>
      </StyledMemberCard>
    </StyledMemberBox>
  );
};
