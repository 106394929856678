import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Stack, Typography } from '@mui/material';

import { StyledTypography, StyledLockButton } from './styled';
import { useAppDispatch, useAppSelector } from 'hooks/redux.hooks';
import { resetState } from 'state/reducers/auth.reducers';

const LockUser: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { blockTimer } = useAppSelector((state) => state.auth);
  const [minutesTimer, setMinutesTimer] = useState<number>(blockTimer || 0);
  const intervalIdRef = useRef<NodeJS.Timeout | undefined>(undefined);
  const blockedTimerExpired = +(localStorage.getItem('blockTimer') || 0);
  const blockTimeLeft = Math.floor((blockedTimerExpired - +new Date()) / 1000);

  useEffect(() => {
    if (blockTimeLeft > 0) {
      setMinutesTimer(blockTimeLeft);
    }
    intervalIdRef.current = setInterval(() => {
      if (blockTimeLeft > 0) {
        setMinutesTimer((timer) => timer - 1);
      } else {
        localStorage.removeItem('blockTimer');
      }
    }, 1000);

    return () => clearInterval(intervalIdRef.current);
  }, []);

  useEffect(() => {
    if (blockTimeLeft === 0 && minutesTimer === 0) {
      clearInterval(intervalIdRef.current);
      dispatch(resetState());
      navigate('/login');
    }
  }, [minutesTimer]);

  const secondsToDigitalFormat = (): string => {
    const minutes = Math.floor(minutesTimer / 60);
    const seconds = minutesTimer - minutes * 60;
    const padZeroMinutes = minutes.toString().padStart(2, '0');
    const padZeroSeconds = seconds.toString().padStart(2, '0');
    return `${padZeroMinutes}:${padZeroSeconds}`;
  };
  return (
    <>
      <Stack spacing={7}>
        <StyledTypography variant='h4' gutterBottom>
          Login
        </StyledTypography>
        <Stack spacing={3} sx={{ alignItems: 'center' }}>
          <StyledLockButton
            sx={{
              '&.Mui-disabled': {
                background: '#eaeaea',
                color: '#333333'
              }
            }}
            fullWidth
            disabled
            variant='contained'
          >
            {secondsToDigitalFormat()}
          </StyledLockButton>
          <Button
            data-testid='login-button'
            fullWidth
            onClick={() => {
              dispatch(resetState());
              navigate('/login');
            }}
            type='submit'
            variant='contained'
            color='secondary'
            sx={{ height: '60px' }}
          >
            Login
          </Button>
          <Typography
            component={Link}
            to='#forgot-password'
            sx={{ color: '#333333', textDecoration: 'none', textAlign: 'center', fontSize: 17, width: 'fit-content' }}
          >
            Forgot Password?
          </Typography>
        </Stack>
      </Stack>
    </>
  );
};

export default LockUser;
