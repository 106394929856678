import React from 'react';

import BrandLogo from 'assets/images/BrandLogo';
import { StyledBox, StyledTypography } from './styled';

const MainLogo: React.FC = () => {
  return (
    <StyledBox>
      <BrandLogo />
      <StyledTypography>Sites and user management</StyledTypography>
    </StyledBox>
  );
};

export default MainLogo;
