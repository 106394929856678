import React from 'react';
import { Box, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import { ArrowDropDown } from '@mui/icons-material';

import { EnhancedTableProps } from './types';
import { CustomTableCell, CustomTableSortLabel } from './styled';

const EnhancedTableHead: React.FC<EnhancedTableProps> = ({
  order,
  orderBy,
  headCells,
  onRequestSort,
  onOrderPagination,
  setState,
  tableState,
  actionsCell
}: EnhancedTableProps) => {
  const createSortHandler = (property: string) => () => {
    onRequestSort(tableState, setState, property);
    if (onOrderPagination) {
      onOrderPagination(property, tableState.orderBy);
    }
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell, i) => (
          <CustomTableCell key={i} sortDirection={orderBy === headCell.name ? order : false}>
            <CustomTableSortLabel
              active={orderBy === headCell.name}
              direction={orderBy === headCell.name ? order : 'asc'}
              onClick={createSortHandler(headCell.name)}
              IconComponent={ArrowDropDown}
            >
              <Typography variant='body1'>{headCell.title}</Typography>
              {orderBy === headCell.name ? (
                <Box component='span' sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </CustomTableSortLabel>
          </CustomTableCell>
        ))}
        {actionsCell && <TableCell width='100%'></TableCell>}
      </TableRow>
    </TableHead>
  );
};

export default EnhancedTableHead;
