/* eslint-disable functional/no-mixed-type */
import React, { ReactNode } from 'react';
import { orderBy } from 'constants/ui.constants';

export type DataType = 'date' | 'number' | 'string' | 'link' | 'custom' | 'json';
export type ColumnAlign = 'left' | 'center' | 'right';
export type Order = 'asc' | 'desc';

interface IActionCellItem {
  element: ReactNode;
  onClick: (id: Record<string, number>) => void;
}

export interface IColumnType {
  name: string;
  title: string;
  type: DataType;
  eventsHandle?: { [key: string]: (event: React.MouseEvent<HTMLElement>) => void };
  fieldId: string;
  customValueHandle?: (value: string, record: Record<string, number>) => string | number;
  align?: ColumnAlign;
}

export interface ITableProps {
  data: Record<any, any>[];
  columns: IColumnType[];
  defaultSortBy?: string;
  rowsClass?: string;
  actionsCell?: IActionCellItem[];
  fieldId?: string;
  onOrderPagination?: (orderColumn: string, orderDirection: orderBy) => void;
  orderDirection?: orderBy;
  children?: React.ReactNode;
  maxLength?: { [key: string]: number };
}

export interface ITableState {
  sortBy?: string;
  orderBy: orderBy;
  perPage: number;
  page: number;
  total: number;
}

export const initialTableState: ITableState = {
  orderBy: orderBy.DESC,
  perPage: 10,
  page: 0,
  total: 0
};

export interface EnhancedTableProps {
  onRequestSort: (tableState: ITableState, setState: any, property: string) => void;
  onOrderPagination?: (orderColumn: string, orderDirection: orderBy) => void;
  order: orderBy;
  orderBy: string;
  rowCount: number;
  headCells: IColumnType[];
  tableState: ITableState;
  setState: any;
  actionsCell?: IActionCellItem[];
}
export type RowFormatType = {
  active?: boolean;
  siteToTransferTo?: string;
};
