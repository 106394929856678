import React, { Dispatch, SetStateAction, useState } from 'react';

import UserProfileForm from './UserProfile';
import { useAppDispatch, useAppSelector } from 'hooks/redux.hooks';
import { IUser } from 'constants/user.constants';
import { convertKeysToSnakeCase } from '../../utils/global.util';
import { updateUserProfile } from 'state/reducers/auth.reducers';
import { Dialog, DialogContent } from '@mui/material';

interface IProfileModalProps {
  openProfileModal: boolean;
  setOpenProfileModal: Dispatch<SetStateAction<boolean>>;
}

const UserProfileModal: React.FC<IProfileModalProps> = ({ openProfileModal, setOpenProfileModal }) => {
  const userDetails = useAppSelector((state) => state.auth.userDetails);
  const dispatch = useAppDispatch();
  const [onEdit, setOnEdit] = useState(false);

  const onUpdateUser = (data: Partial<IUser>) => {
    const convertedToSnakeCaseData = convertKeysToSnakeCase(data);
    dispatch(updateUserProfile(convertedToSnakeCaseData));
    setOnEdit(false);
  };

  return (
    <Dialog open={openProfileModal} onClose={() => setOpenProfileModal(false)}>
      <DialogContent>
        <UserProfileForm {...userDetails} disabled={onEdit} setOnEdit={setOnEdit} onUpdateUser={onUpdateUser} />
      </DialogContent>
    </Dialog>
  );
};

export default UserProfileModal;
