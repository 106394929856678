import React from 'react';
interface IconProps {
  width?: string;
  height?: string;
  fill?: string;
  hover?: boolean;
}

export const PhoneIcon: React.FC<IconProps> = ({ width = '24px', height = '24px', fill = '#037691', hover = true }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <title>E345B2E7-B2C0-4382-86D0-F0715E90FCCC</title>
      <g id='💎-BlueWind-Design-System-💎' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='Style-Guide---Global-elements' transform='translate(-282.000000, -2700.000000)'>
          <g id='icon-/-24px-/-tel' transform='translate(282.000000, 2700.000000)'>
            <rect id='Rectangle-Copy-8' x='0' y='0' width='24' height='24'></rect>
            <g id='phone-call' transform='translate(2.000000, 2.000000)' fill={fill}>
              <path
                d='M15.2726074,11.4752619 L15.3614614,11.5674478 C16.0789568,12.29605 19.6719876,15.8724207 19.6719876,15.8724207 C19.8874583,16.0867808 20.0051898,16.3788881 19.9998243,16.6809914 C19.9940831,16.9842055 19.8641342,17.2718701 19.6408887,17.4773448 C18.5824163,18.4525166 17.3195798,19.1422453 15.8612647,19.5576374 C14.4429339,19.9619228 12.8324564,20.1051997 11.0431604,19.9896896 C10.7732666,19.9719188 10.5200329,19.8575194 10.328997,19.6664834 L0.332929982,9.67041646 C0.130787295,9.46938444 0.0152771873,9.19726929 0.00750246851,8.91182604 C-0.03803517,7.02256938 0.125233924,5.45651889 0.456214809,4.16591557 C0.893820407,2.46214149 1.62908667,1.21263312 2.56982764,0.309655066 C3.00632256,-0.109069073 3.69716186,-0.102405029 4.12477139,0.325204503 L8.56746783,4.76790094 C8.90511276,5.10554587 8.98952399,5.62200933 8.77516388,6.04961886 L8.02323751,7.55569295 L12.4437205,11.9761759 L13.9497946,11.2242495 C14.3174277,11.0398776 14.756144,11.0743085 15.0904569,11.3119928 C15.158208,11.3608625 15.2192951,11.4152855 15.2726074,11.4752619 Z M3.41949333,2.76091282 C3.07740571,3.29847909 2.80640122,3.94711277 2.60759056,4.71903128 C2.34547147,5.74196213 2.21330125,6.965925 2.22218664,8.41868674 L11.5973868,17.7938869 C12.9446345,17.8483099 14.1652653,17.7316891 15.2526153,17.4218111 C15.9667787,17.2185577 16.6198551,16.9320038 17.2096231,16.5543746 L16.2122377,15.5603213 C15.7268731,15.077178 15.2426192,14.5929241 14.7583653,14.1086702 L14.220799,13.5711039 L12.721389,14.320809 C12.2937795,14.5351691 11.777316,14.4507578 11.4396711,14.1131129 L5.88630053,8.55974235 C5.5486556,8.22209742 5.46424437,7.70563396 5.67860447,7.27802443 L6.43053084,5.77195033 L3.41949333,2.76091282 Z'
                id='path2'
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
